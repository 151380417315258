import i18n from "@/i18n";
import Axios from "axios";
import _ from "@/boot/lodash";
import { $moment } from "@/boot/moment-js";
import type { ILanguage } from "@/models/constants";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import { localize } from "vee-validate/dist/vee-validate.full.esm";
import { ToastProgrammatic as $toast } from "buefy";
import type { IState } from "@/store";
import { getSupportedLocaleCode } from "@/data/enums/i18n";
import SupportedLocaleCodes from "@/locales";
import { QUERY_PARAMS } from "@/data/constants";

export interface Ii18nState {
  locales: ILocale[];
  activeLocaleCode: string;
  browserLocaleCode: string;
}

export interface ILocale {
  language: ILanguage;
  packs: any[];
}

const initialState = (): Ii18nState => ({
  activeLocaleCode: SupportedLocaleCodes.EN,
  browserLocaleCode: navigator.language,
  locales: [
    {
      language: {
        code: SupportedLocaleCodes.EN
      } as ILanguage,
      packs: []
    }
  ]
});

const getters: GetterTree<Ii18nState, IState> = {
  // Get supported brand locales
  brandLocales: (s, g, rootState): string[] => {
    return _.map(rootState?.brand?.languages || [], ({ code }) => code);
  },
  // Get default brand locale
  brandLocale: (s, g, rootState) => {
    const { languages, language_id } = rootState?.brand;
    return _.find(languages, ({ id }) => id === language_id)?.code;
  },
  brandLangDesignators: (s, getters): string[] => {
    return _.uniq(
      _.map(getters.brandLocales, locale => {
        return locale.split("-")?.[0];
      })
    );
  },
  upmindLangDesignators: (): string[] => {
    return _.uniq(
      _.map(_.values(SupportedLocaleCodes), locale => {
        return locale.split("-")?.[0];
      })
    );
  },
  /**
   * @name $store.getters["i18n/key"]
   * @param key String
   * @desc Takes a key and appends the current active ISO-639 locale code.
   * @returns New 'locale-aware' key that can be applied to other components
   * (eg <u-link>) to prevent component re-use.
   */
  key:
    ({ activeLocaleCode }) =>
    (key: string) => {
      return `${key}_${activeLocaleCode}`;
    }
};

const actions: ActionTree<Ii18nState, IState> = {
  setDefaultLocale: ({ commit, getters }) => {
    /** `rootGetters.isAdminContext` may not be set at this point, so we need to
     * manually check the window.location.pathname instead */
    const isAdminContext = window.location.pathname.startsWith("/admin");
    const searchParams = new URLSearchParams(window.location?.search);

    /**
     * @desc Here we construct an array of preferred locales from various sources
     * (searchParams, localStorage and the browser language). The order of these
     * locales is important, as the first brand supported locale will be used.
     * */

    const preferredLocales = _.uniq(
      _.map(
        _.compact([
          searchParams.get(QUERY_PARAMS.LOCALE),
          searchParams.get(QUERY_PARAMS.LANG),
          isAdminContext &&
            localStorage?.getItem("i18n/active_admin_locale_code"),
          localStorage?.getItem("i18n/active_locale_code"),
          window.navigator.language
        ]),
        code => code.replace("_", "-")
      )
    );

    /**
     * @desc Here we create an intersection to work out which of the preferred
     * locales are supported at a brand or Upmind level (depending on context),
     * when comparing the designator part (ISO 639-1) of the locale code (eg.
     * 'es' from 'es-MX') */

    const localeIntersection = preferredLocales.filter(
      (code: SupportedLocaleCodes) => {
        const designator = code.split("-")?.[0];
        return (
          isAdminContext
            ? getters["upmindLangDesignators"]
            : getters["brandLangDesignators"]
        ).includes(designator);
      }
    );

    /**
     * @desc Here we silently clean 'locale' and 'lang' params from the URL
     * in case any were passed from an external source. */

    const cleanedUrl = new URL(window.location?.href);
    cleanedUrl.searchParams.delete(QUERY_PARAMS.LOCALE);
    cleanedUrl.searchParams.delete(QUERY_PARAMS.LANG);
    window.history.replaceState("", "", cleanedUrl);

    /**
     * @desc Here we get the final localeCode, fully checking for Upmind level
     * support (including principal subdivisions) */

    const localeCode = getSupportedLocaleCode(
      localeIntersection?.[0] || getters["brandLocale"]
    );

    // Switch i18n locale
    i18n.locale = localeCode;

    // Commit active locale
    commit("setLocaleCode", {
      localeCode,
      isAdminContext
    });
  },
  setLanguage: (
    { state, getters, rootGetters, commit, dispatch },
    localeCode: string
  ) => {
    return new Promise(resolve => {
      if (
        !rootGetters.isAdminContext &&
        !getters["brandLocales"].includes(localeCode)
      )
        return resolve(state.activeLocaleCode);
      if (localeCode === state.activeLocaleCode) {
        dispatch("loadMomentLocale", localeCode);
        dispatch("loadVeeValidateLocale", localeCode);
        resolve(localeCode);
        return;
      } else {
        commit("setLocaleCode", {
          localeCode,
          isAdminContext: rootGetters.isAdminContext
        });
        resolve(localeCode);
        return window.location.reload();
      }
    });
  },

  loadLanguagePacks: (
    { dispatch, commit },
    { localeCode = i18n.locale, packs }: { localeCode: string; packs: [] }
  ) => {
    return new Promise<void>(resolve => {
      Promise.all(
        _.map(packs, pack => {
          return dispatch("loadLanguagePack", { localeCode, pack });
        })
      ).then(packs => {
        const messages = {};
        _.each(packs, pack => {
          if (!pack) return;

          /**
           * Translates files with dot in the name into nested objects
           * .ie _modules.web_hosting => {_modules: {web_hosting: {...}}}
           */
          const paths = pack.name.split(".");
          let curr = messages;
          while (paths.length) {
            const path = paths.shift();
            if (!paths.length) curr[path] = pack.messages;
            else {
              curr[path] = {};
              curr = curr[path];
            }
          }

          commit("addLocalePack", {
            localeCode,
            pack: pack.name
          });
        });
        if (!_.isEmpty(messages)) {
          i18n.mergeLocaleMessage(localeCode, messages);
          i18n.setLocaleMessage(localeCode, i18n.messages[localeCode]);
        }
        resolve();
      });
    });
  },

  fetchLanguageFile: (
    ctx,
    {
      localeCode = i18n.locale,
      name,
      path
    }: { localeCode: string; name: string; path?: string }
  ) => {
    const version = import.meta.env.VERSION;

    return new Promise(resolve => {
      Axios.get(path || `/languages/${localeCode}/${name}.json?${version}`)
        .then(response => {
          resolve({
            name,
            messages: response.data
          });
        })
        .catch(() => {
          $toast.open({
            message: i18n.t("_sentence.language_pack_missing", {
              pack: `${localeCode}/${name}`
            }) as string,
            type: "is-danger"
          });
          resolve(null); // Pack not found
        });
    });
  },

  loadLanguagePack: (
    { dispatch, state, commit },
    {
      localeCode = i18n.locale,
      path,
      pack
    }: { localeCode: string; path: null; pack: [] }
  ) => {
    return new Promise(resolve => {
      let locale = _.find(
        state.locales,
        (l: ILocale) => l.language.code === localeCode
      ) as ILocale | undefined;

      // Check for invalid locale
      if (locale === undefined) {
        locale = {
          language: {
            code: localeCode
          } as ILanguage,
          packs: []
        } as ILocale;
        commit("addLocale", { locale });
      }

      // Pack already loaded
      if (locale.packs.includes(pack)) {
        return resolve(null);
      }

      // Get language pack
      dispatch("fetchLanguageFile", {
        localeCode,
        name: pack,
        path
      }).then(resolve);
    });
  },

  loadVeeValidateLocale: async (context, localeCode: string) => {
    // Fallback to country designator if regional variation is unsupported
    const codes = _.uniq([...[localeCode], ...[localeCode.split("-")[0]]]);
    // Map missing codes (eg. VeeValidate only has regional support for Portuguese)
    const map = {
      [SupportedLocaleCodes.PT]: "pt_PT",
      [SupportedLocaleCodes.NB]: "nb_NO"
    };

    for (let code of codes) {
      try {
        code = _.get(map, code, code).replace("-", "_"); // Transform to snakecase
        const locale = await import(
          `../../../node_modules/vee-validate/dist/locale/${code}.json`
        );
        localize(code, locale);
        break;
      } catch {
        // Silent fail
        // Error loading VeeValidate locale (may be missing?)
      }
    }
  },

  loadMomentLocale: async (context, localeCode: string) => {
    // Fallback to country designator if regional variation is unsupported
    const codes = _.uniq([...[localeCode], ...[localeCode.split("-")[0]]]);

    // Glob for all Moment locales
    const importMomentLocalesHelper = import.meta.glob(
      "../../../node_modules/moment/dist/locale/*.js"
    );

    let moduleLocaleHelper;
    for (const code of codes) {
      try {
        // Load specific Moment Locale (also, `@modules` produces `/node_modules/` reference)
        moduleLocaleHelper = await importMomentLocalesHelper[
          `../../../node_modules/moment/dist/locale/${code.toLocaleLowerCase()}.js`
        ]();

        // It's ok to do `moduleLocalHelper.default`, because if the locale code
        // is not supported by `moment`, the previous import will throw an error
        // that will be captured by the catch
        $moment.locale(code, moduleLocaleHelper.default);

        break;
      } catch {
        // Silent fail
        // Error loading Moment.js locale module (may be missing?)
      }
    }
  }
};

const mutations: MutationTree<Ii18nState> = {
  addLocale: (state: Ii18nState, payload: { locale: ILocale }) => {
    const index = _.findIndex(
      state.locales,
      (l: ILocale) => l.language.code === payload.locale.language.code
    );

    if (index !== -1) {
      _.merge(state.locales[index], payload.locale);
      return;
    }
    state.locales.push(payload.locale);
  },
  setLocaleCode: (
    state: Ii18nState,
    {
      localeCode,
      isAdminContext
    }: { localeCode: string; isAdminContext: boolean }
  ) => {
    state.activeLocaleCode = localeCode;
    if (isAdminContext)
      return localStorage.setItem("i18n/active_admin_locale_code", localeCode);
    localStorage.setItem("i18n/active_locale_code", localeCode);
  },
  addLocalePack: (
    state: Ii18nState,
    { localeCode, pack }: { localeCode: string; pack: string }
  ) => {
    const index = _.findIndex(
      state.locales,
      (l: ILocale) => l.language.code === localeCode
    );

    // Check for invalid locale
    if (index === -1) return false;
    state.locales[index].packs.push(pack);
  }
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
