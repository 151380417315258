import Vue from "vue";
import VueI18n from "vue-i18n";
import EssentialKeys from "./essentialKeys.json";
import SupportedLocaleCodes from "@/locales";
import _ from "@/boot/lodash";

Vue.use(VueI18n);

export default new VueI18n({
  locale: SupportedLocaleCodes.EN,
  silentTranslationWarn: import.meta.env.NODE_ENV === "production",
  missing: (locale, key) => {
    if (locale === SupportedLocaleCodes.EN)
      return _.get(EssentialKeys, key, key);
    return key;
  }
});
