<template>
  <div class="cprod-grid is-relative">
    <!-- No results -->
    <transition name="slide-down">
      <template v-if="!$cProdsData.isLoading && !$cProdsData.products">
        <div class="u-ripple-table-empty">
          <div class="u-border-box has-padding-200 has-text-centered">
            <p>
              <i18n tag="strong" path="_.no_products_or_services" />
            </p>
            <p class="is-size-7 has-text-grey">
              <i18n path="_sentence.no_results_matching_filters_msg" />
            </p>
            <template v-if="showCta">
              <p class="has-margin-top-100">
                <b-button
                  class="has-theme-brand"
                  @click="$cProdsMethods.startNewOrder"
                >
                  <i18n path="_action.place_new_order" />
                </b-button>
              </p>
            </template>
          </div>
        </div>
      </template>
    </transition>
    <!-- Loading -->
    <b-loading :active="$cProdsData.isLoading" :is-full-page="false" />
    <!-- Grids view -->
    <cprod-grid-item
      v-for="(cProd, index) in contractProducts"
      :key="`cprod-grid-item-${index}`"
      :c-prod="cProd"
      :client-id="$cProdsData.clientId"
      :show-skeletons="$cProdsData.isLoading || !$cProdsData.products"
      :is-loading="$cProdsData.isLoading"
      :redirect="redirect"
      v-on="rowEvents(cProd)"
    />
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import Context from "@/mixins/context";
import CProdMixin from "@/mixins/cProdMixin";
import type {
  ICProdsProviderData,
  ICProdsProviderMethods
} from "@/models/providers/contractProduct";
import type { IContractProduct } from "@/models/contracts";

export default defineComponent({
  name: "CProdsGridView",
  components: {
    "cprod-grid-item": () =>
      import("@/components/app/global/contractProducts/cProdGridItem.vue")
  },
  mixins: [Context, CProdMixin],
  inject: {
    cProdsProvider: {
      from: "cProdsProvider",
      type: Function as PropType<ICProdsProviderData>
    },
    $cProdsMethods: {
      from: "cProdsProviderMethods",
      type: Object as PropType<ICProdsProviderMethods>
    }
  },
  props: {
    rowEvents: {
      type: Function as PropType<
        (contractProduct: IContractProduct) => {
          [key: string]: Function;
        }
      >,
      default: () => {}
    },
    redirect: { type: Boolean, default: true }
  },
  computed: {
    $cProdsData() {
      return this.cProdsProvider();
    },
    contractProducts(): IContractProduct[] {
      if (this.$cProdsData.isLoading || !this.$cProdsData.products)
        return Array(
          this.$store.getters["ui/isMobile"]
            ? 2
            : this.$store.getters["isAdminContext"]
            ? this.$store.getters["ui/isFullHd"]
              ? this.$store.getters["ui/navIsCollapsed"]
                ? 12
                : 9
              : 6
            : 4
        );
      return this.$cProdsData.products;
    },
    showCta() {
      return (
        this.$store.getters["brand/showStore"] && !this.isMockClientContext
      );
    }
  }
});
</script>

<style lang="scss" scoped>
.cprod-grid {
  display: grid;
  gap: 0.75rem;
  grid-template-columns: 1fr;
  @include tablet {
    grid-template-columns: repeat(auto-fill, minmax(24em, 1fr));
  }
}

::v-deep .cprod-grid .b-skeleton {
  margin-top: 0;
  opacity: 0.75;
}

.u-ripple-table-empty {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 22rem;
  box-shadow: 0 4px 16px 0 rgba($black, 0.1);
  & ~ .b-skeleton {
    opacity: 0.4;
    border-top-color: $light !important;
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 360ms ease-out;
}
.slide-down-leave-active {
  transition-duration: 0s;
}
.slide-down-enter,
.slide-down-leave-to {
  margin-top: -1rem;
  opacity: 0;
}
</style>
