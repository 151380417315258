export enum RegexMatch {
  INTEGER = "\\d+",
  NEW = "new",
  SLUG = "[\\w-_]+", // eg "foo" | "foo-bar" | "foo_bar"
  UUID = "[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}"
}

export enum GuestRoutes {
  FORGOTTEN_PASSWORD = "guest__forgottenPassword", // {host}/auth/forgotten-password | {host}/forgotten-password
  STORE = "order", // {host}/order
  SHARED_INVOICE = "sharedInvoice" // {host}/invoice/:invoiceId
}

export enum ClientRoutes {
  ACCOUNT = "client__account", // {host}/account
  ACCOUNT_CREDIT = "client__accountCredit", // {host}/billing/account-credit
  ACCOUNT_NOTES = "client__accountNotes", // {host}/account/notes
  ACCOUNT_PROFILE = "client__accountProfile", // {host}/account/profile
  ACCOUNT_SECURITY = "client__accountSecurity", // {host}/account/security
  AFFILIATE = "client__affiliate", // {host}/account/affiliate
  AFFILIATE_COMMISSIONS = "client__affiliateCommissions", // {host}/account/affiliate/commissions
  AFFILIATE_OVERVIEW = "client__affiliateOverview", // {host}/account/affiliate/overview
  AFFILIATE_PAYOUTS = "client__affiliatePayouts", // {host}/account/affiliate/payouts
  BILLING = "client__billing", // {host}/billing
  BILLING_SETTINGS = "client__billingSettings", // {host}/billing/settings
  CATEGORY_PRODUCTS = "client__categoryProducts", // {host}/products/category/:categoryId
  CATEGORY_PRODUCTS_ACTIVE = "client__activeCategoryProducts", // {host}/products/category/:categoryId/active
  CATEGORY_PRODUCTS_ALL = "client__allCategoryProducts", // {host}/products/category/:categoryId/all
  CATEGORY_PRODUCTS_CANCELLED = "client__cancelledCategoryProducts", // {host}/products/category/:categoryId/cancelled
  CHILD_ACCOUNTS = "client__childAccounts", // {host}/account/child-accounts
  CONTRACT_PRODUCT = "client__cProd", // {host}/products/:contractId/:productId
  CONTRACT_PRODUCTS = "client__contractProducts", // {host}/products
  CONTRACT_PRODUCT_BILLING = "client__cProdBilling", // {host}/products/:contractId/:productId/billing
  CONTRACT_PRODUCT_DELEGATES = "client__cProdDelegates", // {host}/products/:contractId/:productId/delegates
  CONTRACT_PRODUCT_OVERVIEW = "client__cProdOverview", // {host}/products/:contractId/:productId/overview
  CONTRACT_PRODUCT_SETTINGS = "client__cProdSettings", // {host}/products/:contractId/:productId/settings
  CONTRACT_PRODUCT_SETUP = "client__cProdSetup", // {host}/products/:contractId/:productId/setup
  CREDIT_NOTE = "client__creditNote", // {host}/billing/credit-notes/:invoiceId
  CREDIT_NOTES = "client__creditNotes", // {host}/billing/credit-notes
  CUSTOM_PAGE = "client__customPage", // {host}/~/:slug
  DASHBOARD = "client__dashboard", // {host}/dashboard
  DEFAULT = "client", // {host}/
  EMAIL_HISTORY = "client__emailHistory", // {host}/account/email-history
  EMAIL_HISTORY_ALL = "client__emailHistoryAll", // {host}/account/email-history/all
  EMAIL_HISTORY_BOUNCED = "client__emailHistoryBounced", // {host}/account/email-history/bounced
  EMAIL_HISTORY_FAILED = "client__emailHistoryFailed", // {host}/account/email-history/failed
  EMAIL_HISTORY_SENT = "client__emailHistorySent", // {host}/account/email-history/sent
  EMAIL_PREVIEW = "client__email", // {host}/account/email-history/all/:emailId
  INVOICE = "client__invoice", // {host}/billing/invoices/:invoiceId
  INVOICES = "client__invoices", // {host}/billing/invoices
  INVOICES_ALL = "client__invoicesAll", // {host}/billing/invoices/all
  INVOICES_CREDITED = "client__invoicesCredited", // {host}/billing/invoices/credited
  INVOICES_PAID = "client__invoicesPaid", // {host}/billing/invoices/paid
  INVOICES_UNPAID = "client__invoicesUnpaid", // {host}/billing/invoices/unpaid
  LEGACY_INVOICE = "client__legacyInvoice", // {host}/billing/legacy-invoices/:invoiceId
  LEGACY_INVOICES = "client__legacyInvoices", // {host}/billing/legacy-invoices
  LOGIN = "client__login", // {host}/auth/login | {host}/login
  LOGIN_AS = "client__loginAs", // {host}/auth/login-as | {host}/login-as
  LOGOUT = "client__logout", // {host}/auth/logout | {host}/logout
  NOTIFICATIONS = "client__notifications", // {host}/account/notifications
  ONE_TIME_PURCHASES = "client__oneTimePurchases", // {host}/products/one-time-purchases
  ONE_TIME_PURCHASES_ACTIVE = "client__activeOneTimePurchases", // {host}/products/one-time-purchases/active
  ONE_TIME_PURCHASES_ALL = "client__allOneTimePurchases", // {host}/products/one-time-purchases/all
  ONE_TIME_PURCHASES_CANCELLED = "client__cancelledOneTimePurchases", // {host}/products/one-time-purchases/cancelled
  ORDER = "client__order", // {host}/billing/order/:orderId
  ORDERS = "client__orders", // {host}/billing/orders
  ORDER_CREDIT_NOTES = "client__orderCreditNotes", // {host}/billing/order/:orderId/credit-notes
  ORDER_INVOICES = "client__orderInvoices", // {host}/billing/order/:orderId/invoices
  ORDER_SUMMARY = "client__orderSummary", // {host}/billing/order/:orderId/overview
  PARENT_ACCOUNT_CONFIGURATION = "client__parentAccountConfiguration", // {host}/account/parent-account
  PAYMENT_METHODS = "client__paymentMethods", // {host}/billing/payment-methods
  PREFERENCES = "client__preferences", // {host}/auth/preferences | {host}/preferences
  PRODUCTS = "client__products", // {host}/products
  PRODUCTS_ACTIVE = "client__activeProducts", // {host}/products/active
  PRODUCTS_ALL = "client__allProducts", // {host}/products/all
  PRODUCTS_CANCELLED = "client__cancelledProducts", // {host}/products/cancelled
  REGISTER = "client__register", // {host}/auth/register | {host}/register
  RELAY_TOKEN = "client__relayToken", // {host}/auth/relay
  RESET_PASSWORD = "client__resetPassword", // {host}/auth/reset-password | {host}/reset-password
  SUBSCRIPTIONS = "client__subscriptions", // {host}/products/subscriptions/
  SUBSCRIPTIONS_ACTIVE = "client__activeSubscriptions", // {host}/products/subscriptions/active
  SUBSCRIPTIONS_ALL = "client__allSubscriptions", // {host}/products/subscriptions/all
  SUBSCRIPTIONS_CANCELLED = "client__cancelledSubscriptions", // {host}/products/subscriptions/cancelled
  SUPPORT = "client__support", // {host}/support
  SUPPORT_TICKET = "client__supportTicket", // {host}/support/tickets/:ticketId
  SUPPORT_TICKETS = "client__supportTickets", // {host}/support/tickets
  SUPPORT_TICKETS_ADD = "client__supportAddTicket" // {host}/support/tickets/add
}

export enum UpmindClientRoutes {
  CONTRACT_PRODUCT = "admin__upmindClientCProd", // {host}/admin/upmind/products/:contractId/:productId
  CONTRACT_PRODUCT_BILLING = "admin__upmindClientCProdBilling", // {host}/admin/upmind/products/:contractId/:productId/billing
  CONTRACT_PRODUCT_OVERVIEW = "admin__upmindClientCProdOverview", // {host}/admin/upmind/products/:contractId/:productId/overview
  CONTRACT_PRODUCT_SETTINGS = "admin__upmindClientCProdSettings", // {host}/admin/upmind/products/:contractId/:productId/settings
  INVOICE = "admin__upmindInvoice", // {host}/admin/upmind/billing/invoices/:invoiceId
  ORDER = "admin__upmindOrder" // {host}/admin/upmind/orders/:orderId
}

export enum AdminRoutes {
  ADMIN_LOGS_WEBHOOKS_EVENTS = "admin__logsWebhooksEvents", // {host}/admin/logs/webhooks
  CATALOGUE_PRODUCT_ACTIVE_CPS = "admin__catalogueProductActiveContractProducts", // {host}/admin/settings/catalogue/product/:productId/products/active
  CATALOGUE_PRODUCT_ALL_CPS = "admin__catalogueProductAllContractProducts", // {host}/admin/settings/catalogue/product/:productId/products/all
  CATALOGUE_PRODUCT_CANCELLED_CPS = "admin__catalogueProductCancelledContractProducts", // {host}/admin/settings/catalogue/product/:productId/products/cancelled
  CLIENT_ACCOUNTS = "admin_clientAccounts", // {host}/admin/clients/:clientId/accounts
  CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT = "admin__clientAffiliateAccount", //{host}/admin/clients/:clientId/accounts/affiliate
  CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_COMMISSIONS = "admin__clientAffiliateAccountCommissions", // {host}/admin/clients/:clientId/accounts/affiliate/commissions
  CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_OVERVIEW = "admin__clientAffiliateAccountOverview", // {host}/admin/clients/:clientId/accounts/affiliate/overview
  CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_PAYOUTS = "admin__clientAffiliateAccountPayouts", // {host}/admin/clients/:clientId/accounts/affiliate/payouts
  CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_SETTINGS = "admin__clientAffiliateAccountSettings", // {host}/admin/clients/:clientId/accounts/affiliate/settings
  CLIENT_ACCOUNTS_CHILD_ACCOUNTS = "admin__clientChildAccounts", // {host}/admin/clients/:clientId/accounts/child-accounts
  CLIENT_BILLING_UNPAID_INVOICES = "admin__clientInvoicesUnpaid", // {host}/admin/clients/:clientId/billing/invoices/unpaid
  CLIENT_LOGS_PROVISION_REQUESTS = "admin__clientLogsProvisionRequests", // {host}/admin/clients/:clientId/logs/provision-requests
  CLIENT_NOTIFICATIONS = "admin__clientNotifications", // {host}/admin/clients/:clientId/settings/notifications
  CLIENT_SETTINGS_BILLING = "admin__clientBillingSettings", // {host}/admin/clients/:clientId/settings/billing
  CLIENT_SETTINGS_PROFILE = "admin__clientProfileSettings", // {host}/admin/clients/:clientId/settings/profile
  CLIENT_SETTINGS_SECURITY = "admin__clientSecuritySettings", // {host}/admin/clients/:clientId/settings/security
  CONTRACT_PRODUCT = "admin__clientCProd", // {host}/admin/clients/:clientId/products/:contractId/:productId
  CONTRACT_PRODUCT_INVOICES = "admin__clientCProdInvoices", // {host}/admin/clients/:clientId/products/:contractId/:productId/invoices
  CONTRACT_PRODUCT_OVERVIEW = "admin__clientCProdOverview", // {host}/admin/clients/:clientId/products/:contractId/:productId/overview
  CONTRACT_PRODUCT_PROVISIONING = "admin__clientCProdProvisioning", // {host}/admin/clients/:clientId/products/:contractId/:productId/manage
  CONTRACT_PRODUCT_SETUP = "admin__clientCProdSetup", // {host}/admin/clients/:clientId/products/:contractId/:productId/setup
  CUSTOM_BASKET_FIELDS_SETTINGS = "admin__customBasketFields", // {host}/admin/settings/custom-basket-fields
  CUSTOM_CANCELLATION_FIELDS_SETTINGS = "admin__customCancellationFields", // {host}/admin/settings/custom-cancellation-fields
  CUSTOM_CLIENT_FIELDS_SETTINGS = "admin__customClientFields", // {host}/admin/settings/custom-client-fields
  DEMO_DATA_SETTINGS = "admin__settingsDemoData", // {host}/admin/settings/demo-data
  EMAIL_TEMPLATES_SETTINGS = "admin__emailTemplatesSettings", // {host}/admin/settings/email-templates
  EMAIL_TEMPLATE_ADD = "admin__addEmailTemplate", // {host}/admin/settings/email-templates/add
  EMAIL_TEMPLATE_EDIT = "admin__editEmailTemplate", // {host}/admin/settings/email-templates/:templateId/edit
  EMAIL_TEMPLATE_HISTORY = "admin__emailTemplateHistory", // {host}/admin/settings/email-templates/:templateId/email-history
  EMAIL_TEMPLATE_SETTINGS = "admin__emailTemplatesSetting", // {host}/admin/settings/email-templates/:templateId
  INVOICE = "admin__invoice", // {host}/admin/clients/:clientId/billing/invoices/:invoiceId
  INVOICE_PREVIEW = "admin__invoicePreview", // {host}/admin/billing/invoices/:invoiceId
  LOGOUT = "admin__logout", // {host}/admin/logout
  LOGS_PROVISION_REQUESTS = "admin__logsProvisionRequests", // {host}/admin/logs/provision-requests
  ORDER = "admin__clientOrder", // {host}/admin/clients/:clientId/orders/:orderId
  SETTINGS_RETENTION_REASONS = "admin__settingsRetentionsReasons", // {host}/admin/settings/retention-reasons
  SETTINGS_TERMS_AND_CONDITIONS = "admin__settingsTermsAndConditions",
  SUPPORT_DEPARTMENTS = "admin__supportDepartments", // {host}/admin/settings/support-departments,
  SUPPORT_TICKET = "admin__supportTicket", // {host}/admin/support/inbox/:ticketId
  WEBHOOKS_SETTINGS = "admin__webhooksSetting", // {host}/admin/settings/automation/webhooks
  WEBHOOKS_SETTINGS_WEBHOOK = "admin__webhooksSettingWebhook", // {host}/admin/settings/automation/webhooks/:webhookId
  WEBHOOKS_SETTINGS_WEBHOOK_EDIT = "admin__webhooksSettingWebhookEdit", // {host}/admin/settings/automation/webhooks/:webhookId/edit
  WEBHOOKS_SETTINGS_WEBHOOK_EVENTS = "admin__webhooksSettingWebhookEvents" // {host}/admin/settings/automation/webhooks/:webhookId/venets
}
