import _ from "@/boot/lodash";
import type { IState } from "@/store";
import type { IUser } from "@/models/users";
import type { GetterTree, ActionTree, MutationTree } from "vuex";
import { Contexts } from "@/models/contexts";
import { DataModules } from "../data/modules";
import { UserMetaKeys } from "@/data/enums/users";
import Vue from "vue";

const getters: GetterTree<IUser | null, IState> = {
  locale: state => {
    return _.get(state, "interface_language_code");
  },
  meta: (state, getters, rootState) => (key: UserMetaKeys) => {
    return rootState.user?.meta?.[key];
  },
  image: state => {
    return _.get(state, "image");
  },
  email: state => {
    return _.get(state, "email");
  },
  can:
    (state, getters, rootState, rootGetters) =>
    (...functionalities: string[]): boolean => {
      let userCan = true;
      let missing: string[] = [];
      if (rootState.context === Contexts.ADMIN) {
        // Map codes
        const codes = _.map(
          rootGetters["auth/admin/functionalities"],
          f => f.code
        );
        // Iterate over each functionality
        _.each(functionalities, functionality => {
          if (!codes.includes(functionality)) {
            missing = _.union(missing, [functionality]);
            userCan = false;
          }
        });
        // Console log missing functionalities
        if (missing.length) {
          // eslint-disable-next-line no-console
          console.warn(`Missing functionalities:\n${missing.join("\n")}`);
        }
      }
      return userCan;
    },
  isUpmindClient: state => {
    return !!state?.upmind_client_id;
  },
  isChildClient: state => {
    return !!state?.parent_client_config?.parent_client_id;
  }
};

const actions: ActionTree<IUser | null, IState> = {
  removeMetaKey: async ({ dispatch, rootState }, key) => {
    const newMeta = _.omit(rootState.user?.meta, [key]);
    return dispatch("updateMeta", { meta: newMeta, merge: false });
  },
  addMeta: async ({ dispatch }, meta) => {
    return dispatch("updateMeta", { meta });
  },
  updateMeta: async (
    { dispatch, commit, rootGetters, rootState },
    { meta, merge = true }
  ) => {
    if (!rootGetters.isAdminContext && !rootGetters.isClientContext) return;

    const dataModule = rootGetters.isAdminContext
      ? DataModules.USERS
      : DataModules.CLIENTS;

    const newMeta = merge ? _.merge({}, rootState.user.meta, meta) : meta;
    const permittedMeta = _.pick(newMeta, _.values(UserMetaKeys));

    /**
     * We set meta before update also in case user has no internet or internet is slow
     * This will give better UX preventing the delay between update and UI render
     */
    commit("setMeta", permittedMeta, { root: true });

    const user = await dispatch(
      `data/${dataModule}/update`,
      {
        id: rootState.user.id,
        data: { meta: permittedMeta }
      },
      { root: true }
    );

    commit("setMeta", user?.meta, { root: true });
  }
};

const mutations: MutationTree<IUser> = {
  update: (state, { key, value }) => {
    Vue.set(state, key, value);
  }
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations
};
