export enum ProvisionRequestStatusCodes {
  PROVISION_REQUEST_DRAFT = "provision_request_draft",
  PROVISION_REQUEST_SCHEDULED = "provision_request_scheduled",
  PROVISION_REQUEST_PENDING = "provision_request_pending",
  PROVISION_REQUEST_DISPATCHED = "provision_request_dispatched",
  PROVISION_REQUEST_SUCCESS = "provision_request_success",
  PROVISION_REQUEST_ERROR = "provision_request_error",
  PROVISION_REQUEST_CANCELLED = "provision_request_cancelled"
}

export enum CancellationRequestStatusCodes {
  CANCELLATION_REQUEST_REQUESTED = "request_cancellation_request",
  CANCELLATION_REQUEST_ACCEPTED = "request_accepted"
}

export enum PriceType {
  PRICE_LIST = "pricelist",
  CONVERTED = "converted",
  MANUAL = "manual"
}

export enum ContractProductsTypes {
  ALL = "all",
  SUBSCRIPTIONS = "subscriptions",
  OTPS = "otps"
}

export enum ContractProductsKinds {
  ACTIVE = "active",
  CANCELLED = "cancelled",
  ALL = "all"
}
