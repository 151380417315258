import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { IState } from "@/store";
import type { IUser } from "@/models/users";
import { DeviceTypes } from "@/data/enums/ui";
import { Contexts } from "@/models/contexts";
import userflow from "userflow.js";

interface IUserflowState {
  initialised: boolean;
  resourceCenterState: null | IUserflowResourceCenterState;
  signature: string | null;
}

interface IUserflowResourceCenterState {
  hasChecklist: boolean;
  isOpen: boolean;
  uncompletedChecklistTaskCount: number;
}

const initialState = (): IUserflowState => ({
  initialised: false,
  resourceCenterState: null,
  signature: null
});

const getters: GetterTree<IUserflowState, IState> = {
  resourceCenterIsOpen: ({ resourceCenterState }) => {
    return !!resourceCenterState?.isOpen;
  },
  showResourceCenter: ({ resourceCenterState }) => {
    return !!(
      resourceCenterState?.hasChecklist &&
      resourceCenterState.uncompletedChecklistTaskCount > 0
    );
  }
};

const actions: ActionTree<IUserflowState, IState> = {
  tryInit: async ({ commit, dispatch, state, rootGetters }, user: IUser) => {
    if (state.initialised || !state.signature) return;
    if (!rootGetters.isAdminContext) return;
    if (rootGetters["auth/isImpersonating"](Contexts.ADMIN)) return;
    const token = import.meta.env.VITE_APP_USERFLOW_TOKEN;
    if (import.meta.env.VITE_APP_USERFLOW === "true" && token) {
      userflow.init(token);
      userflow
        .identify(
          user.id,
          {
            admin: user.admin,
            hostname: window.location.hostname,
            device_type: rootGetters["ui/isDesktopPlus"]
              ? DeviceTypes.DESKTOP
              : DeviceTypes.MOBILE,
            firstname: user.firstname,
            locale: user.interface_language_code,
            name: user.fullname,
            signed_up_at: user.created_at
          },
          {
            signature: state.signature
          }
        )
        .then(() => {
          commit("initialised", true);
          dispatch("initResourceCenter");
        })
        .catch(err => err);
    }
  },
  end: async ({ commit, state }) => {
    if (!state.initialised) return;
    try {
      await userflow.reset();
      commit("initialised", false);
    } catch {
      // Silent catch
    }
  },
  track: async (
    { state },
    { event, attrs }: { event: string; attrs: Record<string, any> }
  ) => {
    if (!state.initialised) return;
    try {
      await userflow.track(event, attrs);
    } catch {
      // Silent catch
    }
  },
  initResourceCenter: async ({ state, commit }) => {
    if (!state.initialised) return;
    userflow.setResourceCenterLauncherHidden(true);
    userflow.on("resourceCenterChanged", () =>
      commit("resourceCenterState", userflow.getResourceCenterState())
    );
    userflow.on("checklistEnded", () => {
      const rC = state.resourceCenterState;
      if (rC?.uncompletedChecklistTaskCount) return;
      if (rC?.isOpen) userflow.closeResourceCenter();
    });
  },
  toggleResourceCenter: async ({ state }) => {
    if (!state.initialised) return;
    userflow.toggleResourceCenter();
  }
};

const mutations: MutationTree<IUserflowState> = {
  initialised: (state, val) => {
    state.initialised = !!val;
  },
  signature: (state, val) => {
    state.signature = val;
  },
  resourceCenterState: (state, val: IUserflowResourceCenterState) => {
    state.resourceCenterState = val;
  }
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
