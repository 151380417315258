import type { IToken } from "@/models/token";
import type { Contexts } from "@/models/contexts";
import _ from "@/boot/lodash";

export const tokenParser = {
  access_token: val => val || "",
  created_at: val => _.toNumber(val || `0`),
  expires_in: val => _.toNumber(val || `0`),
  refresh_expires_in: val => _.toNumber(val || `0`),
  refresh_token: val => val || "",
  second_factor_required: val =>
    _.isBoolean(val) ? val : val === "true" ? true : false,
  token_type: val => val || ""
};

export default (context: Contexts): IToken => {
  return _.mapValues(tokenParser, (method, key) => {
    return method(_.get(localStorage, `${context}/auth/token/${key}`));
  });
};
