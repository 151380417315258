import { mapGetters, mapState } from "vuex";
import _ from "@/boot/lodash";
import type { IState } from "@/store";
import type { IBillingCycle, IBillingTermsOption } from "@/models/constants";

export default {
  data: () => ({
    billingCyclesLoaded: false
  }),
  computed: {
    ...mapGetters({
      getBillingCycleName: "constants/getBillingCycleName"
    }),
    ...mapState({
      billingCycles: (state: IState): IBillingCycle[] =>
        state.constants.billingCycles
    }),
    billingTermsOptions(): IBillingTermsOption[] {
      return _.map(this.billingCycles, billingTerm => ({
        value: billingTerm.months,
        label: billingTerm.name
      }));
    }
  },
  methods: {
    async created() {
      await this.getBillingCycles();
      this.billingCyclesLoaded = true;
    },

    getBillingCycles() {
      return this.$store.dispatch("constants/getBillingCycles");
    }
  }
};
