import { mapState } from "vuex";
import type { IBrand } from "@/models/brands";
import type { IState } from "@/store";

export default {
  computed: {
    ...mapState({
      brands: (state: IState) => state.auth.admin.brands
    })
  },
  methods: {
    getBrandName(brandId): IBrand["id"] {
      return this.$_.get(
        this.$_.find(this.brands, ["id", brandId]),
        "name",
        ""
      );
    }
  }
};
