import _ from "@/boot/lodash";
import i18n from "@/i18n";
import router from "@/router";
import store from "@/store";
import { ClientRoutes, GuestRoutes } from "@/data/enums/router";
import { Contexts } from "@/models/contexts";
import type { NavigationGuardNext, Route } from "vue-router";
import { SnackbarProgrammatic as $snackbar } from "buefy";
import { ToastProgrammatic as $toast } from "buefy";

const guardClientAuthRoutes = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  if (
    store.getters["auth/client/isAuthenticated"] &&
    !store.getters["auth/client/isGuestCustomerAuthenticated"]
  ) {
    store.dispatch("ui/endRouting");
    return next(
      from.path === "/"
        ? // Replace '\\' from start to prevent attack vector
          `${to.query?.redirect || "/"}`.replace(/^\\+/, "")
        : false
    );
  }
  store.commit("user", {});
  /** Here we try 'brand/get' again in case the user has been routed from the
   * admin area where a different brand was loaded in memory (possible with
   * multi-brand orgs). In most cases this dispatch won't result in any
   * additional network calls as brand data will be returned from store.
   * */
  await store.dispatch("brand/get", {
    ignoreStored: from.path.startsWith("/admin")
  });
  // Continue with routing
  next();
};

export default [
  {
    path: "/auth/preferences",
    component: () => import("@/skeleton/client/loggedOut/minimalLayout.vue"),
    beforeEnter: guardClientAuthRoutes,
    meta: {
      allowGuestContext: true
    },
    children: [
      {
        path: "",
        alias: "/preferences",
        name: ClientRoutes.PREFERENCES,
        props: (to: Route) => {
          return {
            token: to.query.token,
            username: to.query.username
          };
        },
        component: () => import("@/views/client/auth/preferences/index.vue"),
        meta: {
          title: "_.notification_preferences",
          group: "auth"
        }
      }
    ]
  },
  {
    path: "/auth",
    redirect: { name: ClientRoutes.LOGIN },
    component: () => import("@/skeleton/client/loggedOut/defaultLayout.vue"),
    beforeEnter: guardClientAuthRoutes,
    meta: {
      allowGuestContext: true
    },
    children: [
      {
        path: "login",
        alias: "/login",
        name: ClientRoutes.LOGIN,
        component: () => import("@/views/client/auth/login/index.vue"),
        meta: {
          title: "_.login",
          group: "auth"
        }
      },
      {
        path: "forgotten-password",
        alias: "/forgotten-password",
        name: GuestRoutes.FORGOTTEN_PASSWORD,
        component: () =>
          import("@/views/client/auth/forgottenPassword/index.vue"),
        meta: {
          title: "_.recover_account"
        }
      },
      {
        path: "reset-password",
        alias: "/reset-password",
        name: ClientRoutes.RESET_PASSWORD,
        component: () => import("@/views/client/auth/resetPassword/index.vue"),
        meta: {
          title: "_.reset_password"
        }
      },

      {
        path: "register",
        alias: "/register",
        name: ClientRoutes.REGISTER,
        component: () => {
          // Org registration
          if (store.getters.isUpmindContext)
            return import("@/views/client/auth/registerOrg/index.vue");
          // Client registration
          return import("@/views/client/auth/register/index.vue");
        },
        beforeEnter: async (
          to: Route,
          from: Route,
          next: NavigationGuardNext
        ) => {
          if (store.getters["brand/hasRegistrationEnabled"]) return next();
          next({ name: ClientRoutes.LOGIN, replace: true });
          $snackbar.open({
            duration: 8000,
            message: i18n.t(
              "_sentence.auth.client_reg_unavailable_msg"
            ) as string,
            type: "is-danger"
          });
        },
        meta: {
          title: "_.create_account",
          group: "auth"
        }
      }
    ]
  },
  {
    path: "/logout",
    name: ClientRoutes.LOGOUT,
    beforeEnter(to, from, next) {
      const query = {};
      const fullPath = window.location.pathname + window.location.search;
      if (!fullPath.startsWith("/logout")) query["redirect"] = fullPath;
      if (from.matched.length) {
        const message = _.get(to.params, "toast");
        if (message) $toast.open({ message });
      }
      next();
      router.replace({ query }).catch(err => err);
    },
    component: {
      name: "ClientLogout",
      async created() {
        // First, we end the existing client session
        await store.dispatch("auth/logout", Contexts.CLIENT);
        // Next, we attempt to restore any backup (parent) token/session
        if (await store.dispatch("auth/restoreToken", Contexts.CLIENT)) {
          router.replace({ name: ClientRoutes.CHILD_ACCOUNTS });
          return $snackbar.open({
            duration: 2500,
            actionText: i18n.t("_action.dismiss") as string,
            message: i18n.t(
              "_sentence.related_accounts.parent_session_restored"
            ) as string,
            type: "is-info"
          });
        }
        // Otherwise, we route to the client login
        const query = router.currentRoute.query;
        router.replace({ name: ClientRoutes.LOGIN, query }).catch(err => err);
      },
      render(h) {
        return h("loading", {
          props: {
            isAbsolute: true
          }
        });
      }
    }
  },
  {
    path: "/login-as",
    name: ClientRoutes.LOGIN_AS,
    component: {
      name: "ClientLoginAs",
      created() {
        return router
          .replace({ name: ClientRoutes.DASHBOARD })
          .catch(err => err);
      },
      render(h) {
        return h("loading", {
          props: {
            isAbsolute: true
          }
        });
      }
    }
  }
];
