import type { GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ICurrency } from "@/models/constants";
import { SupportedLocaleCodes, WIPLocaleCodes } from "@/locales";
import { ISO_4217_CURRENCY_CODE } from "@/data/iso4217";

const getters: GetterTree<{}, IState> = {
  // Validate by running eg `1234567.98.toLocaleString("zh-TW")` in console
  decimalSeparator: (s, g, rootState) => {
    switch (rootState.i18n.activeLocaleCode) {
      case SupportedLocaleCodes.AZ:
      case SupportedLocaleCodes.BG:
      case SupportedLocaleCodes.CS:
      case SupportedLocaleCodes.DA:
      case SupportedLocaleCodes.DE:
      case SupportedLocaleCodes.EL:
      case SupportedLocaleCodes.ES:
      case SupportedLocaleCodes.FR:
      case SupportedLocaleCodes.FR_CA:
      case SupportedLocaleCodes.ID:
      case SupportedLocaleCodes.IT:
      case SupportedLocaleCodes.NB:
      case SupportedLocaleCodes.NL:
      case SupportedLocaleCodes.PL:
      case SupportedLocaleCodes.PT:
      case SupportedLocaleCodes.PT_BR:
      case SupportedLocaleCodes.RO:
      case SupportedLocaleCodes.RU:
      case SupportedLocaleCodes.SK:
      case SupportedLocaleCodes.SV:
      case SupportedLocaleCodes.TR:
      case SupportedLocaleCodes.UK:
        return ",";
      case SupportedLocaleCodes.EN:
      case SupportedLocaleCodes.EN_US:
      case SupportedLocaleCodes.ES_419:
      case SupportedLocaleCodes.HE:
      case SupportedLocaleCodes.JA:
      case SupportedLocaleCodes.UR:
      case SupportedLocaleCodes.ZH_TW:
        return ".";
      default:
        return undefined;
    }
  },
  // Validate by running eg `1234567.98.toLocaleString("zh-TW")` in console
  thousandsSeparator: (s, g, rootState) => {
    switch (rootState.i18n.activeLocaleCode) {
      case SupportedLocaleCodes.AZ:
      case SupportedLocaleCodes.DA:
      case SupportedLocaleCodes.DE:
      case SupportedLocaleCodes.EL:
      case SupportedLocaleCodes.ES:
      case SupportedLocaleCodes.ID:
      case SupportedLocaleCodes.IT:
      case SupportedLocaleCodes.NL:
      case SupportedLocaleCodes.PT:
      case SupportedLocaleCodes.PT_BR:
      case SupportedLocaleCodes.RO:
      case SupportedLocaleCodes.TR:
        return ".";
      case SupportedLocaleCodes.EN:
      case SupportedLocaleCodes.EN_US:
      case SupportedLocaleCodes.ES_419:
      case SupportedLocaleCodes.HE:
      case SupportedLocaleCodes.JA:
      case SupportedLocaleCodes.UR:
      case SupportedLocaleCodes.ZH_TW:
        return ",";
      case SupportedLocaleCodes.BG:
      case SupportedLocaleCodes.CS:
      case SupportedLocaleCodes.FR:
      case SupportedLocaleCodes.FR_CA:
      case SupportedLocaleCodes.NB:
      case SupportedLocaleCodes.PL:
      case SupportedLocaleCodes.RU:
      case SupportedLocaleCodes.SK:
      case SupportedLocaleCodes.SV:
      case SupportedLocaleCodes.UK:
        return " ";
      default:
        return undefined;
    }
  },
  /**
   *
   * @name suggestedCurrencyCodes
   * @desc Here we return an array of ISO-4217 currency code suggestions – based on the
   * current browser locale OR active locale.
   */
  suggestedCurrencyCodes: (s, getters, { i18n }): ISO_4217_CURRENCY_CODE[] => {
    const codes = getters["localeCurrencySuggestions"](i18n.browserLocaleCode);
    return codes.length
      ? codes
      : getters["localeCurrencySuggestions"](i18n.activeLocaleCode);
  },
  /**
   *
   * @name localeCurrencySuggestions
   * @desc Here we map ISO-4217 currency code suggestions (as an array of codes)
   * for different supported locales. This is a manually maintained mapping.
   */
  localeCurrencySuggestions: () => (locale: string) => {
    switch (locale) {
      case WIPLocaleCodes.EN_AU:
        return [ISO_4217_CURRENCY_CODE.AUD];
      case SupportedLocaleCodes.AZ:
        return [ISO_4217_CURRENCY_CODE.AZN];
      case SupportedLocaleCodes.BG:
        return [ISO_4217_CURRENCY_CODE.BGN];
      case SupportedLocaleCodes.FR_CA:
        return [ISO_4217_CURRENCY_CODE.CAD];
      case SupportedLocaleCodes.ZH_TW:
        return [ISO_4217_CURRENCY_CODE.CNY];
      case WIPLocaleCodes.ES_CO:
        return [ISO_4217_CURRENCY_CODE.COP, ISO_4217_CURRENCY_CODE.USD];
      case SupportedLocaleCodes.CS:
        return [ISO_4217_CURRENCY_CODE.CZK];
      case SupportedLocaleCodes.DA:
        return [ISO_4217_CURRENCY_CODE.DKK];
      case SupportedLocaleCodes.DE:
      case SupportedLocaleCodes.EL:
      case SupportedLocaleCodes.ES:
      case SupportedLocaleCodes.FR:
      case SupportedLocaleCodes.IT:
      case SupportedLocaleCodes.NL:
      case SupportedLocaleCodes.PT:
      case SupportedLocaleCodes.SK:
        return [ISO_4217_CURRENCY_CODE.EUR];
      case WIPLocaleCodes.EN_GB:
        return [ISO_4217_CURRENCY_CODE.GBP];
      case SupportedLocaleCodes.ID:
        return [ISO_4217_CURRENCY_CODE.IDR];
      case WIPLocaleCodes.ES_MX:
        return [ISO_4217_CURRENCY_CODE.MXN, ISO_4217_CURRENCY_CODE.USD];
      case SupportedLocaleCodes.NB:
        return [ISO_4217_CURRENCY_CODE.NOK];
      case WIPLocaleCodes.EN_NZ:
        return [ISO_4217_CURRENCY_CODE.NZD];
      case SupportedLocaleCodes.UR:
        return [ISO_4217_CURRENCY_CODE.PKR];
      case SupportedLocaleCodes.PL:
        return [ISO_4217_CURRENCY_CODE.PLN];
      case SupportedLocaleCodes.PT_BR:
        return [ISO_4217_CURRENCY_CODE.BRL];
      case SupportedLocaleCodes.RO:
        return [ISO_4217_CURRENCY_CODE.RON];
      case SupportedLocaleCodes.RU:
        return [ISO_4217_CURRENCY_CODE.RUB];
      case SupportedLocaleCodes.SV:
        return [ISO_4217_CURRENCY_CODE.SEK];
      case SupportedLocaleCodes.TR:
        return [ISO_4217_CURRENCY_CODE.TRY];
      case SupportedLocaleCodes.UK:
        return [ISO_4217_CURRENCY_CODE.UAH];
      case SupportedLocaleCodes.EN_US:
      case SupportedLocaleCodes.ES_419:
        return [ISO_4217_CURRENCY_CODE.USD];
      case WIPLocaleCodes.AF:
      case WIPLocaleCodes.AF_ZA:
      case WIPLocaleCodes.EN_ZA:
      case WIPLocaleCodes.ZU:
      case WIPLocaleCodes.ZU_ZA:
        return [ISO_4217_CURRENCY_CODE.ZAR];
      default:
        return [];
    }
  },
  formatValue:
    (s, g, rootState) =>
    (value: number, currency: ICurrency["code"]): string => {
      try {
        // Locale should be an IETF BCP 47 language tag
        const locale = rootState.i18n.activeLocaleCode.replace("_", "-");
        return new Intl.NumberFormat(locale, {
          style: "currency",
          currency, // ISO 4217 currency code
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(value);
      } catch {
        return `${value}`;
      }
    },
  trimTrailingZeros:
    (s, { decimalSeparator }) =>
    (formattedValue: string): string => {
      if (!decimalSeparator) return formattedValue;
      const regex = new RegExp(
        [
          `(?:\\${decimalSeparator}0{2})`, // Match escaped decimal separator + '00'
          `(\\s[\\D\\S]{1,3})?`, // Optionally match whitespace + `USD` | `£GB` | `₹` etc
          `$` // Match end of string
        ].join(""),
        "g"
      );
      return formattedValue.replace(regex, "$1");
    },
  /**
   * Sums an array of numbers, adjusting for floating-point precision issues.
   * This function uses a scaling method to ensure accurate summing of decimal values.
   *
   * @param {number[]} values - The array of numbers to sum.
   * @returns {number} The sum of the array, adjusted for precision.
   *
   * @example
   * console.log(precisionSum([29, 59.99]));
   * // returns 88.99 instead of 88.99000000000001
   */
  precisionSum:
    () =>
    (values: number[]): number => {
      const scale = 1e12; // Scale factor for precision adjustment
      const total = values.reduce((acc, val) => {
        return Math.round((acc + val) * scale) / scale;
      }, 0);
      return total;
    }
};

export default {
  namespaced: true,
  getters
};
