import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import _ from "@/boot/lodash";
import { ToastProgrammatic as $toast } from "buefy";
import i18n from "@/i18n";
import type { IAddress } from "@/models/addresses";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    clientId => {
      const admin = `api/admin/clients/${clientId}/addresses`;
      const client = `api/clients/${clientId}/addresses`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  clientScope: () => id => {
    return `$client_${id}`;
  },
  selectorScope: () => id => {
    return `$client_${id}_selector`;
  },
  params: (state: IDataState, getters) => (id: IAddress["id"]) => {
    return _.get(state, `${getters.clientScope(id)}.params`, {});
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload?.id).contextual,
        storeModule: DataModules.CLIENTS_ADDRESSES,
        params: {
          ...payload.params,
          with_staged_imports: 1
        }
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload?.id).contextual,
        storeModule: DataModules.CLIENTS_ADDRESSES
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath(payload?.id).contextual}/${
          payload?.address_id
        }`,
        storeModule: DataModules.CLIENTS_ADDRESSES
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath(payload?.id).contextual}/${
          payload?.address_id
        }`,
        storeModule: DataModules.CLIENTS_ADDRESSES
      },
      { root: true }
    );
  },
  openAddressModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/client/addEditClientAddressModal.vue"
            ),
          width: 640,
          ...payload
        }
      },
      { root: true }
    );
  },
  openEditModal: (
    { dispatch },
    { address, config = {} }: { address: IAddress; config: any }
  ) => {
    return dispatch(
      "openAddressModal",
      _.merge(
        {
          props: _.merge(
            {},
            { clientId: address.client_id },
            {
              data: _.pick(address, [
                "name",
                "address_1",
                "address_2",
                "country_id",
                "region_id",
                "city",
                "postcode",
                "type"
              ]),
              actionType: "update",
              addressId: address.id
            }
          )
        },
        config
      )
    );
  },
  confirmDelete: async (
    { dispatch, rootGetters },
    {
      address,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onConfirm = () => {},
      onSuccess = () => {
        $toast.open({
          message: i18n.t("_.object_successfully_removed", {
            object: i18n.t("_.address")
          }) as string
        });
      },
      onError = error => {
        dispatch("api/handleValidationError", { error }, { root: true });
      }
    }: {
      address: IAddress;
      onConfirm: Function;
      onSuccess: Function;
      onError: Function;
    }
  ) => {
    if (!rootGetters["user/can"]("delete_client_address")) return;
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            mode: "button",
            title: i18n.t("_action.delete_object", {
              object: (i18n.t("_.address") as string).toLowerCase()
            }),
            confirmButtonText: i18n.t("_action.delete"),
            message: i18n.t("_sentence.delete_confirmation", {
              object: (i18n.t("_.address") as string).toLowerCase()
            })
          },
          events: {
            confirmed: async () => {
              try {
                await dispatch("remove", {
                  id: address.client_id,
                  address_id: address.id
                });
                await onSuccess();
              } catch (error) {
                await onError(error);
              }
              await onConfirm();
              confirm.close();
            }
          }
        }
      },
      { root: true }
    );
  },
  makeDefault: async (
    { dispatch, rootGetters },
    {
      address,
      onSuccess = () =>
        $toast.open({
          message: i18n.t("_.default_object_updated", {
            object: (i18n.t("_.address") as string).toLowerCase()
          }) as string
        }),
      onError = (error: Error) =>
        dispatch("api/handleValidationError", { error }, { root: true })
    }: {
      address: IAddress;
      onSuccess: Function;
      onError: Function;
    }
  ) => {
    if (!rootGetters["user/can"]("update_client_address")) return;
    try {
      await dispatch("update", {
        id: address.client_id,
        address_id: address.id,
        data: { default: true }
      });
      await onSuccess();
      return Promise.resolve(true);
    } catch (error) {
      await onError(error);
      return;
    }
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
