<template>
  <orders-provider
    :id="/** Orders provider 1 */ `OP1`"
    :client-id="clientId"
    :available-filters="orderFilters"
    :available-sorters="orderSorters"
    :manual-filters="providerManualFilters"
  >
    <template v-slot:default="{ $ordersData, $ordersMethods }">
      <div>
        <div class="has-margin-bottom-50">
          <b-input
            v-focus="$store.getters['ui/isTabletPlus']"
            :value="$ordersData.searchQuery"
            :icon-right="$ordersData.searchQuery ? 'close-circle' : null"
            :placeholder="$t('_placeholder.orders_quick_search')"
            :icon-right-clickable="true"
            @icon-right-click="$ordersMethods.doQuickSearch()"
            @input="$ordersMethods.debounceSearch"
          />
        </div>
        <filter-sort-controls
          v-if="$ordersData.enableFiltering || $ordersData.enableSorting"
          :active-filters="$ordersData.activeFilters"
          :enable-filtering="$ordersData.enableFiltering"
          :enable-sorting="$ordersData.enableSorting"
          :pre-defined-filters="$ordersData.constQueryFilters"
          :filters="$ordersData.availableFilters"
          :sorters="$ordersData.availableSorters"
          :table="$ordersData.table"
          class="has-margin-bottom-100"
          @onRefresh="$ordersMethods.reloadData"
          @onFiltersChange="$ordersMethods.onFiltersChange"
          @onSort="$ordersMethods.onSort"
        />
        <add-segment-message
          v-if="!$_.isEmpty($ordersData.activeFilters)"
          :active-filters="$ordersData.activeFilters"
          :pre-defined-filters="$ordersData.constQueryFilters"
          :api-props="segmentApiProps"
          :segment-type="segmentType"
        />
        <orders-table-view />
        <u-pagination
          :current.sync="$ordersData.page"
          :per-page="$ordersData.limit"
          :total="$ordersData.total"
          :default-limit="$ordersData.defaults.limit"
          :visible-total="$ordersData.table.data.length"
          @change-page="$ordersMethods.onPageChange"
          @change-per-page="$ordersMethods.onLimitChange"
        />
      </div>
    </template>
  </orders-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OrdersProvider from "@/components/app/global/orders/ordersProvider.vue";
import { DefaultOrderSorters } from "@/data/sorters/order";
import { DataModules } from "@/store/modules/data/modules";
import { SegmentTypes } from "@/data/enums/segments";
import type { PropType } from "vue";
import type { ISorter, IFilter } from "@/models/tables";
import type { IClient } from "@upmind-automation/types";

export default defineComponent({
  name: "OrdersListing",
  components: {
    OrdersProvider,
    "orders-table-view": () =>
      import("@/components/app/global/orders/ordersTableView.vue"),
    "add-segment-message": () =>
      import("@/components/app/admin/segments/addSegmentMessage.vue")
  },
  props: {
    clientId: { type: String as PropType<IClient["id"]>, default: null },
    availableFilters: {
      type: Function as PropType<() => IFilter[]>,
      default: () => {}
    },
    availableSorters: {
      type: Function as PropType<() => ISorter[]>,
      default: DefaultOrderSorters
    },
    manualFilters: {
      type: Function as PropType<
        () => Record<string, string | number | boolean>
      >,
      default: () => ({})
    }
  },
  data: () => ({
    segmentType: SegmentTypes.ORDER,
    orderFilters: [] as IFilter[],
    orderSorters: [] as ISorter[]
  }),
  computed: {
    segmentApiProps() {
      return {
        with: this.$store.getters[`data/${DataModules.ORDERS}/withParam`]
      };
    },
    providerManualFilters() {
      return this.manualFilters();
    }
  },
  created() {
    this.orderSorters = this.availableSorters(this);
  }
});
</script>
