import { AdminRoutes } from "@/data/enums/router";
import i18n from "@/i18n";
import type {
  ICProdProvider,
  ICProdProvProvider
} from "@/models/providers/contractProduct";
import type { IMenuRoute } from "@/models/routes";
import store from "@/store";
import _ from "@/boot/lodash";
import { BrandConfigKeys } from "@/data/constants";

const isActive: IMenuRoute["isActive"] = (currentRoute, isActive) => {
  if ((currentRoute.name || "").match(/__(cancelled|active)/g)) return false;
  return isActive;
};

export function cProdAllProductsTabigation(): IMenuRoute[] {
  return [
    {
      label: i18n.t("_.active"),
      to: { name: "admin__clientAllActiveProducts" }
    },
    {
      label: i18n.t("_.cancelled"),
      to: { name: "admin__clientAllCancelledProducts" }
    },
    {
      label: i18n.t("_.all"),
      to: { name: "admin__clientAllAllProducts" }
    }
  ];
}

export function cProdSubscriptionsTabigation(): IMenuRoute[] {
  return [
    {
      label: i18n.t("_.active"),
      to: { name: "admin__clientActiveSubscriptions" }
    },
    {
      label: i18n.t("_.cancelled"),
      to: { name: "admin__clientCancelledSubscriptions" }
    },
    {
      label: i18n.t("_.all"),
      to: { name: "admin__clientAllSubscriptions" }
    }
  ];
}

export function cProdOTPsTabigation(): IMenuRoute[] {
  return [
    {
      label: i18n.t("_.active"),
      to: { name: "admin__clientActiveOTPs" }
    },
    {
      label: i18n.t("_.cancelled"),
      to: { name: "admin__clientCancelledOTPs" }
    },
    {
      label: i18n.t("_.all"),
      to: { name: "admin__clientAllOTPs" }
    }
  ];
}

export function adminClientCategoryProductsTabigation() {
  return [
    {
      isActive: (currentRoute, isActive) => {
        if (currentRoute.path.endsWith("/active")) return true;
        return isActive;
      },
      label: i18n.t("_.active"),
      to: {
        name: "admin__clientAllActiveCategoryProducts"
      }
    },
    {
      isActive: (currentRoute, isActive) => {
        if (currentRoute.path.endsWith("/cancelled")) return true;
        return isActive;
      },
      label: i18n.t("_.cancelled"),
      to: {
        name: "admin__clientAllCancelledCategoryProducts"
      }
    },
    {
      isActive: currentRoute => {
        return (
          !currentRoute.path.endsWith("/active") &&
          !currentRoute.path.endsWith("/cancelled")
        );
      },
      label: i18n.t("_.all"),
      to: {
        name: "admin__clientAllCategoryProducts"
      }
    }
  ];
}

export function adminClientSubscriptionsCategoryProductsTabigation() {
  return [
    {
      label: i18n.t("_.active"),
      to: {
        name: "admin__clientSubscriptionsActiveCategoryProducts"
      }
    },
    {
      label: i18n.t("_.cancelled"),
      to: {
        name: "admin__clientSubscriptionsCancelledCategoryProducts"
      }
    },
    {
      isActive,
      label: i18n.t("_.all"),
      to: {
        name: "admin__clientSubscriptionsCategoryProducts"
      }
    }
  ];
}

export function adminClientOTPsCategoryProductsTabigation() {
  return [
    {
      label: i18n.t("_.active"),
      to: {
        name: "admin__clientOTPsActiveCategoryProducts"
      }
    },
    {
      label: i18n.t("_.cancelled"),
      to: {
        name: "admin__clientOTPsCancelledCategoryProducts"
      }
    },
    {
      isActive,
      label: i18n.t("_.all"),
      to: {
        name: "admin__clientOTPsCategoryProducts"
      }
    }
  ];
}

export function cProdTabigation({
  cProdData,
  cProdProvData
}: {
  cProdData: ICProdProvider;
  cProdProvData: ICProdProvProvider;
}) {
  return [
    {
      label: i18n.t("_.setup"),
      to: {
        name: AdminRoutes.CONTRACT_PRODUCT_SETUP
      },
      if: () => cProdData.isAwaitingSetup
    },
    {
      label: i18n.t("_.overview"),
      to: {
        name: AdminRoutes.CONTRACT_PRODUCT_OVERVIEW
      }
    },
    {
      label: i18n.t("_.notes_and_secrets"),
      to: {
        name: `adminClientCProdVault`
      },
      if: () => {
        const config = store.getters["brand/config"];
        return _.get(config, BrandConfigKeys.CLIENT_NOTES_AND_SECRETS_ENABLED);
      }
    },
    {
      label: i18n.t("_.invoices"),
      to: {
        name: AdminRoutes.CONTRACT_PRODUCT_INVOICES
      }
    },
    {
      label: i18n.t("_.credit_notes"),
      to: {
        name: `adminClientCProdCreditNotes`
      }
    },
    {
      label: i18n.t("_.manage"),
      to: {
        name: AdminRoutes.CONTRACT_PRODUCT_PROVISIONING
      },
      if: () => {
        return cProdProvData.canManageProvisioning;
      }
    },
    {
      label: i18n.t("_.settings"),
      to: {
        name: `adminClientCProdSettings`
      },
      if: () => {
        return cProdData.canModifySettings;
      }
    },
    {
      label: i18n.t("_.delegates"),
      to: {
        name: `adminClientCProdDelegates`
      }
    },
    {
      label: i18n.t("_.activity_log"),
      to: {
        name: `adminClientCProdActivities`
      }
    }
  ];
}
