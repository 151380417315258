import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { IAffiliate, IAffiliateBalance } from "@/models/affiliate";
import type { ApiPathGetter } from "@/models/api";
import { Methods } from "@/models/methods";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    ({ accountId }) => {
      const admin = `api/admin/accounts/${accountId}/affiliate`;
      const client = `api/accounts/${accountId}/affiliate`;
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => (accountId: IAffiliate["account_id"]) => {
    return `$affiliate_${accountId}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `api/admin/affiliate_accounts`, //  getters.apiPath(payload).contextual,
        storeModule: DataModules.AFFILIATES
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload): Promise<IAffiliate> => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}`,
        storeModule: DataModules.AFFILIATES
      },
      { root: true }
    );
  },
  getBalance: ({ dispatch, getters }, payload): Promise<IAffiliateBalance> => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/balance`,
        storeModule: DataModules.AFFILIATES
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload).contextual,
        storeModule: DataModules.AFFILIATES
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/${payload.id}`,
        storeModule: DataModules.AFFILIATES
      },
      { root: true }
    );
  },
  toggleAccount: (
    { dispatch, getters },
    {
      accountId,
      enable
    }: { accountId: IAffiliate["account_id"]; enable: boolean }
  ) => {
    const slug = enable ? "enable" : "disable";
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath({ accountId }).admin}/${slug}`,
        storeModule: DataModules.AFFILIATES
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    commissions: require("./commissions").default,
    links: require("./links").default,
    payoutDestinations: require("./payoutDestinations").default,
    payouts: require("./payouts").default,
    referrals: require("./referrals").default,
    stats: require("./stats").default,
    tiers: require("./tiers").default
  }
};
