import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { IAffiliatePendingCommission } from "@/models/affiliate";
import moment from "moment";
import { ConfirmModalModes } from "@/data/enums";
import i18n from "@/i18n";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext }): ApiPathGetter =>
    ({ accountId }) => {
      const admin = `api/admin/accounts/${accountId}/affiliate`;
      const client = `api/accounts/${accountId}/affiliate`;
      const contextual = isAdminContext ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => accountId => {
    return `$commissions_${accountId}`;
  },
  isApproved: () => (c: IAffiliatePendingCommission) => {
    return c.commission_approved && c.approved_at;
  },
  isAwaitingPayment: () => (c: IAffiliatePendingCommission) => {
    return !c.invoice_paid && !c.commission_approved;
  },
  isCancelled: () => (c: IAffiliatePendingCommission) => {
    return (
      !c.commission_approved && moment.utc(c.keep_until).toDate() < new Date()
    );
  },
  isOnHold:
    () =>
    ({ suspended }: IAffiliatePendingCommission) => {
      return suspended;
    },
  isPendingApproval: () => (c: IAffiliatePendingCommission) => {
    return !c.commission_approved;
  },
  isRejected: () => (c: IAffiliatePendingCommission) => {
    return c.rejected;
  }
};

const actions: ActionTree<IDataState, IState> = {
  listAvailable: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/commissions`,
        storeModule: DataModules.AFFILIATES_COMMISSIONS,
        params: {
          with_staged_imports: 1,
          "filter[paid]": false,
          ...payload.params
        }
      },
      { root: true }
    );
  },
  listPending: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath(payload).contextual}/pending_commissions`,
        storeModule: DataModules.AFFILIATES_COMMISSIONS,
        params: {
          with_staged_imports: 1,
          with: ["invoice,invoice.client"].join(),
          ...payload.params
        }
      },
      { root: true }
    );
  },
  approve: (
    { dispatch },
    { affiliate_account_id, affiliate_commission_invoice_ids }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `api/admin/affiliate_accounts/${affiliate_account_id}/approve_pending_commissions`,
        storeModule: DataModules.AFFILIATES_COMMISSIONS,
        requestConfig: { data: { affiliate_commission_invoice_ids } }
      },
      { root: true }
    );
  },
  reject: ({ dispatch }, { affiliate_account_id, data }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `api/admin/affiliate_accounts/${affiliate_account_id}/reject_pending_commissions`,
        storeModule: DataModules.AFFILIATES_COMMISSIONS,
        requestConfig: { data }
      },
      { root: true }
    );
  },
  openRejectModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 480,
          component: () =>
            import(
              "@/components/app/global/affiliate/rejectAffiliateCommissionModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  openApproveCommissionModal: async (
    { dispatch },
    { amount, brandName, affiliateAccountId, invoiceIds, onSuccess }
  ) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            mode: ConfirmModalModes.BUTTON,
            autoClose: false,
            title: i18n.t("_.approve_pending_commission"),
            confirmButtonText: i18n.t("_action.approve"),
            message: i18n.t("_sentence.affiliate.confirm_commission_approval", {
              amount: amount,
              brand: brandName
            }),
            type: "is-success"
          },
          canCancel: [],
          events: {
            confirmed: async () => {
              try {
                await dispatch(
                  `data/${DataModules.AFFILIATES_COMMISSIONS}/approve`,
                  {
                    affiliate_account_id: affiliateAccountId,
                    affiliate_commission_invoice_ids: invoiceIds
                  },
                  {
                    root: true
                  }
                );
                dispatch(
                  "toast/show",
                  {
                    message: i18n.t("_.commission_approved")
                  },
                  {
                    root: true
                  }
                );
                onSuccess();
                confirm.close();
              } catch (error) {
                dispatch("api/handleError", error, {
                  root: true
                });
              }
            }
          }
        }
      },
      { root: true }
    );
  },
  openRecalculateCommissionsModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 540,
          canCancel: ["button", "outside", "escape"],
          component: () =>
            import(
              "@/components/app/global/affiliate/recalculateCommissionsModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  openApplyManualCommissionModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 480,
          canCancel: ["button"],
          component: () =>
            import(
              "@/components/app/global/affiliate/applyManualCommissionModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },
  recalculateCommissions: ({ dispatch }, { invoiceId, data }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `api/admin/invoices/${invoiceId}/calculate_pending_commissions`,
        requestConfig: { data }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
