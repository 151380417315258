<template>
  <ValidationObserver ref="observer" slim>
    <form @submit.prevent="validateBeforeSubmit">
      <modal-card
        :is-processing="isProcessing"
        :title="$t('_action.add_notification')"
        @close="$emit('close')"
      >
        <div>
          <!-- Hook -->
          <u-field
            :label="`${$t('_.trigger')}*`"
            :vname="$t('_.trigger').toString().toLowerCase()"
            vid="trigger"
            rules="required"
          >
            <treeselect
              v-model="form.hook_id"
              name="trigger"
              :multiple="false"
              :disabled="isProcessing"
              :disable-branch-nodes="true"
              :options="hookOptions"
              :default-expand-level="1"
              :value-consists-of="'LEAF_PRIORITY'"
              :placeholder="$t('_.select_trigger')"
              @input="form.template_id = form.hook_id ? form.template_id : null"
            />
          </u-field>

          <!-- Template -->

          <u-field
            v-if="selectedTemplateCategory"
            :label="`${$t('_.template')}*`"
            :vname="$t('_.template').toString().toLowerCase()"
            vid="template"
            rules="required"
          >
            <b-field grouped>
              <b-autocomplete
                ref="templateAutocomplete"
                :custom-formatter="option => option.name"
                :data="filteredTemplates"
                :disabled="isProcessing || isLoadingTempaltes"
                :open-on-focus="true"
                :placeholder="$t('_sentence.please_select_template')"
                :value="selectedTemplate ? selectedTemplate.name : null"
                expanded
                field="id"
                name="template"
                @select="onTemplateSelected"
                @typing="templateSearch = $event"
              >
                <template slot="header">
                  <a @click="addTemplateModal">
                    <span>
                      {{
                        $t("_action.add_new_object", {
                          object: $t("_.template").toString().toLowerCase()
                        })
                      }}
                    </span>
                  </a>
                </template>
                <template slot-scope="props">
                  <p>{{ props.option.name }}</p>
                </template>
              </b-autocomplete>
              <p class="control">
                <span class="input is-static has-text-grey-light">
                  {{ $t("_.or").toString().toLowerCase() }}
                </span>
              </p>
              <p class="control">
                <b-button
                  class="button is-outlined"
                  :disabled="isLoadingTempaltes"
                  type="button"
                  @click="addTemplateModal"
                  >{{ $t("_.create_new_template") }}</b-button
                >
              </p>
            </b-field>
          </u-field>

          <b-field v-if="!$_.isEmpty(selectedTemplate)">
            <div>
              <u-field
                :label="$t('_.subject')"
                :vname="$t('_.subject').toString().toLowerCase()"
                vid="subject"
                rules="required"
              >
                <b-input
                  :disabled="true"
                  :placeholder="$t('_.subject')"
                  :value="selectedTemplate.subject"
                  expanded
                  name="subject"
                  @input.native="$emit('update:subject', $event.target.value)"
                />
              </u-field>

              <u-field
                :label="$t('_.body')"
                :vname="$t('_.body').toString().toLowerCase()"
                vid="body"
                rules="required"
              >
                <u-codemirror-twig
                  :value="selectedTemplate.body"
                  :disabled="true"
                />
              </u-field>
            </div>
          </b-field>
        </div>

        <button
          slot="footer"
          type="submit"
          :class="{ 'is-loading': isProcessing }"
          :disabled="formIsDisabled"
          class="button is-success"
        >
          <i18n :path="'_action.create'" />
        </button>
      </modal-card>
    </form>
  </ValidationObserver>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "@/boot/lodash";

// Treeselect
import HasValidation from "@/mixins/HasValidation";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { DataModules } from "@/store/modules/data/modules";
import { TemplateTypes } from "@/data/enums/templates";
import type {
  IObjectNotificationProvider,
  IObjectNotificationProviderMethods
} from "@/models/providers/objectNotifications";
import type { PropType } from "vue";

export default defineComponent({
  name: "AddEditObjectNotificationMappingModal",
  components: {
    Treeselect
  },
  mixins: [HasValidation],
  inject: {
    $objectNotificationProvider: {
      from: "objectNotificationProvider",
      type: Function as PropType<IObjectNotificationProvider>
    },
    $objNotificationMethods: {
      from: "objectNotificationMethods",
      type: Function as PropType<IObjectNotificationProviderMethods>
    }
  },
  data: () => ({
    form: {},
    templateSearch: "",
    isLoadingTempaltes: false,
    hookLogEventsMap: {}
  }),
  computed: {
    isProcessing() {
      return this.$objNotificationsData.isProcessing;
    },
    $objNotificationsData() {
      return this.$objectNotificationProvider();
    },
    formIsDisabled() {
      return _.isEqual(this.form, this.initForm()) || this.hasErrors;
    },
    hookOptions() {
      return this.$objNotificationsData.templateCategories.map(category => {
        return {
          id: category.id,
          label: category.name,
          children: category?.hooks?.map(hook => {
            return {
              id: hook.id,
              label: this.hookLogEventsMap[hook?.code]?.name || hook.name
            };
          })
        };
      });
    },
    selectedTemplate() {
      return this.$objNotificationsData.templates?.find(
        i => i.id === this.form.template_id
      );
    },
    selectedTemplateCategory() {
      return this.$objNotificationsData.templateCategories?.find(
        category =>
          !!category.hooks?.find(hook => hook.id === this.form.hook_id)
      );
    },
    filteredTemplates() {
      return _.filter(this.$objNotificationsData.templates, template => {
        return _.includes(
          template.name.toLowerCase(),
          this.templateSearch.toLowerCase()
        );
      });
    }
  },
  async created() {
    this.form = this.initForm();

    const { HookLogEventsMap } = await import(
      "@/components/app/global/timelineComponent/events"
    );

    this.hookLogEventsMap = HookLogEventsMap;
  },
  methods: {
    onTemplateSelected(template) {
      if (!template) return;
      this.form.template_id = template?.id;
    },
    async addTemplateModal() {
      const modal = await this.$store.dispatch(
        `data/${DataModules.TEMPLATES}/openAddEditModal`,
        {
          props: {
            templateType: TemplateTypes.NOTIFICATION,
            showSubmitButton: false,
            showHookSelector: false,
            showChannelSelector: true,
            showActiveToggle: false,
            preselectedCategoryCode: this.selectedTemplateCategory?.code
          },
          events: {
            success: async template => {
              try {
                modal.close();
                this.isLoadingTempaltes = true;
                await this.$objNotificationMethods.setTemplates();
                this.form.template_id = template.id;
              } finally {
                this.isLoadingTempaltes = false;
              }
            }
          }
        }
      );
    },
    initForm() {
      return {
        hook_id: null,
        template_id: null
      };
    },
    validateBeforeSubmit() {
      this.$objNotificationMethods.setProcessing(true);
      this.validate()
        .then(result => {
          if (result) {
            return this.$objNotificationMethods.create(this.form);
          }
          throw this.$t("_sentence.form_is_not_valid");
        })
        .then(() => {
          this.$store.dispatch("toast/show", {
            message: this.$t("_sentence.successfully_saved")
          });
          this.$emit("success");
          this.$emit("close");
        })
        .catch(error => {
          this.$store.dispatch("api/handleValidationError", {
            error,
            vm: this
          });
        })
        .finally(() => {
          this.$objNotificationMethods.setProcessing(false);
        });
    }
  }
});
</script>
