import type { IState } from "@/store";
import type { ActionTree, MutationTree } from "vuex";
import { deleteCookie, getCookie, setCookie } from "@/helpers/cookies";
import { Contexts } from "@/models/contexts";
import _ from "@/boot/lodash";
import router from "@/router";

const UPM_TRACK_KEYS = ["source", "medium", "campaign", "content", "term"];
const UPM_TRACK_COOKIE = "upm_track";

export interface IUpmState {
  track: {
    source: string;
    medium: string;
    campaign: string;
    content: string;
    term: string;
  } | null;
}

const initialState: IUpmState = {
  track: null
};

const actions: ActionTree<IUpmState, IState> = {
  getTrackCookie: ({ commit, dispatch, rootState }) => {
    try {
      const trackAtob = atob(`${getCookie(UPM_TRACK_COOKIE)}`);
      const trackObj = JSON.parse(trackAtob);
      if (rootState.context === Contexts.ADMIN) return;
      return commit("setTrack", {
        ..._.keyBy(UPM_TRACK_KEYS),
        ..._.pick(trackObj, UPM_TRACK_KEYS)
      });
    } catch {
      return dispatch("deleteTrackCookie");
    }
  },
  setTrackCookie: ({ commit }, trackObj: IUpmState["track"]) => {
    // Abort if empty
    if (!trackObj) return;
    // Commit track object
    commit("setTrack", trackObj);
    // Set upm_track cookie
    return setCookie(
      UPM_TRACK_COOKIE,
      btoa(JSON.stringify(trackObj)),
      7776000 /* 90 days */
    );
  },
  deleteTrackCookie: ({ commit }) => {
    // Commit null track
    commit("setTrack", null);
    // Delete upm_track cookie
    return deleteCookie(UPM_TRACK_COOKIE);
  },
  deleteTrackParams: () => {
    const query = { ...router.currentRoute.query };
    // Delete each upm_ track parameter
    _.each(UPM_TRACK_KEYS, key => delete query[`upm_${key}`]);
    // Update router only if params have changed
    if (!_.isEqual(router.currentRoute.query, query)) {
      router.replace({ query });
    }
  },
  track: async ({ state, dispatch }) => {
    // Get existing track cookie
    await dispatch("getTrackCookie");
    // Abort if pre-existing upm_track
    if (state.track) return dispatch("deleteTrackParams");
    // Current query
    const query = { ...router.currentRoute.query };
    // Track object
    const trackObj = _.mapValues(_.keyBy(UPM_TRACK_KEYS), key => {
      const upmKey = `upm_${key}`;
      const utmKey = `utm_${key}`;
      const val = query[upmKey] || query[utmKey] || null;
      delete query[upmKey];
      return val;
    });
    // Track values
    const trackValues = _.values(trackObj);
    // If one or more track values
    if (_.compact(trackValues).length) {
      // Set track cookie
      dispatch("setTrackCookie", trackObj);
    }
    // Delete track query params
    return dispatch("deleteTrackParams");
  }
};

const mutations: MutationTree<IUpmState> = {
  setTrack: (state, track: IUpmState["track"] | null) => {
    state.track = track;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
