import RequestPromises from "@/mixins/requestPromises";
import hash from "object-hash";
import { DEFAULT_LIST_SCOPE } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import { mapState } from "vuex";
import {
  deSerialisedConfig,
  getFiltersWithOperators,
  serialiseConfig
} from "@/helpers/table";
import { ROUTE_QUERY_SEPARATOR, Sorters } from "@/data/table";
import _ from "@/boot/lodash";
import type {
  ITableConfig,
  IFilteringSortingTable,
  IFilter
} from "@/models/tables";

export default {
  mixins: [RequestPromises],
  props: {
    id: { type: String, required: true },
    dataScope: { type: String, default: DEFAULT_LIST_SCOPE },
    pushToHistory: { type: Boolean, default: true },
    ignoreHistory: { type: Boolean, default: false },
    saveInStorage: { type: Boolean, default: false },
    saveWithPage: { type: Boolean, default: false },
    limit: { type: Number, default: 10 },
    enableFiltering: { type: Boolean, default: true },
    enableSorting: { type: Boolean, default: true },
    enablePagination: { type: Boolean, default: true },
    querySeparator: { type: String, default: ROUTE_QUERY_SEPARATOR }
  },
  data: () => ({
    staticTable: {
      limit: 10,
      loading: false,
      complete: true,
      sorting: false,
      sort: {},
      data: [],
      results: [],
      page: 1,
      total: null,
      paginated: true
    } as IFilteringSortingTable,
    filters: [] as IFilter[],
    activeFilters: [] as IFilter[],
    dataModule: "",
    dataModuleAction: "list",
    defaults: {} as ITableConfig,
    config: {},
    apiProps: {} as { [key: string]: any },
    dataPayloadSettings: {},
    lastConfig: "",
    loading: true,
    requestError: false
  }),
  computed: {
    ...mapState({
      data(state, getters): [] {
        return getters[`data/listArray`]({
          storeModule: this.dataModule,
          scope: this.dataScope
        });
      },
      total(state, getters): Number {
        return getters[`data/total`]({
          storeModule: this.dataModule,
          scope: this.dataScope
        });
      }
    }),
    table(): IFilteringSortingTable {
      return {
        ...this.staticTable,
        paginated: this.total !== 0,
        data: this.data,
        total: this.total
      };
    },
    serialisedConfig() {
      return _.clone(
        this.saveInStorage
          ? localStorage[`config/${this.id}`]
          : this.$route.query[this.id] || ""
      );
    },
    newQuery() {
      const query = _.clone(this.$route.query);
      if (this.lastConfig === this.serialiseConfig(this.defaults)) {
        delete query[this.id];
      } else {
        query[this.id] = this.lastConfig;
      }
      return query;
    },
    showDataFilter(): boolean {
      if (this.activeFilters.length) {
        return true;
      }

      if (
        this.table.limit >= (_.isNull(this.table.total) ? 1 : this.table.total)
      ) {
        return false;
      }

      return this.table.data.length > 0;
    },
    deSerialisedConfig() {
      return deSerialisedConfig(
        this.serialisedConfig,
        this.filters,
        this.querySeparator
      );
    },
    constQueryFilters() {
      return this.loading ? [] : this.deSerialisedConfig.constFilters;
    },
    filterKeys() {
      return _.map(this.filters, f => f.key);
    }
  },
  async created() {
    this.defaults = {
      page: 1,
      limit: this.limit,
      sort: {
        field: "created_at",
        direction: Sorters.DESCENDING
      },
      filters: [],
      constFilters: []
    };

    await this.setLocalFilters();
  },
  watch: {
    filterKeys: { handler: "setLocalFilters", deep: true },
    "$route.query": { handler: "serialisedConfigWatch", deep: true }
  },
  methods: {
    onPageChange(page: number) {
      if (this.table.data.length !== 0) {
        this.getData({ page });
      }
    },
    onLimitChange(limit: number) {
      if (this.table.data.length !== 0) {
        this.getData({ limit });
      }
    },
    onSort(field: string, direction: Sorters) {
      this.getData({
        sort: {
          field,
          direction:
            field === ""
              ? _.get(this.defaults, "sort.direction", Sorters.DESCENDING)
              : direction
        }
      });
    },
    onFiltersChange(filters: IFilter[]) {
      this.getData({ filters });
    },
    async getData(options: any = {}) {
      if (this.staticTable.loading) return Promise.resolve();
      if (!_.values(DataModules).includes(this.dataModule as DataModules)) {
        throw new Error("Please set proper 'dataModule'");
      }
      this.staticTable.loading = true;

      this.config = _.mergeWith(
        {},
        this.defaults,
        this.saveInStorage
          ? this.deSerialisedLocalStorageConfig()
          : this.deSerialisedConfig,
        options,
        (value, srcValue, key) => {
          if (!["filters"].includes(key)) return;
          if (Array.isArray(value) && srcValue.length) {
            return srcValue;
          }
        }
      );

      // Clearing previous filters
      if (options.filters !== undefined) {
        this.config.filters = options.filters;
      }

      this.lastConfig = this.serialiseConfig(this.config);

      // fix tables when ignoring history
      if (this.ignoreHistory) {
        if (options.page !== undefined) {
          this.config.sort = this.table.sort;
          this.config.filters = this.activeFilters;
        }
        if (options.filters !== undefined) {
          this.config.sort = this.table.sort;
          this.config.page = this.table.page;
        }
        if (options.sort !== undefined) {
          this.config.filters = this.activeFilters;
          this.config.page = this.table.page;
        }
      }

      this.setTable(); // Set Properly table

      const params = this.getStoreParams({
        scope: this.dataScope,
        vm: this,
        ignoreStored: this.config.ignoreStored,
        params: {
          limit: this.config.limit,
          offset: (this.config.page - 1) * this.config.limit,
          order: this.table.sorting
            ? `${
                this.table.sort.direction === Sorters.DESCENDING
                  ? Sorters.DESCENDING
                  : ""
              }${this.table.sort.field}`
            : "",
          ...getFiltersWithOperators({
            filters: _.concat(
              this.deSerialisedConfig.constFilters,
              this.activeFilters
            ) as IFilter[]
          }),
          ...this.apiProps
        },
        ...this.dataPayloadSettings
      });

      try {
        await this.storeGetData(params);
        this.updateRoute();
        this.requestError = false;
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
        this.requestError = true;
      } finally {
        this.loading = false;
        this.staticTable.sorting = false;
        this.staticTable.loading = false;

        if (this.table.page !== 1 && this.table.data.length === 0) {
          this.getData({ page: 1 });
        }
      }
    },
    storeGetData(params: any): Promise<any> {
      return this.$store.dispatch(
        `data/${this.dataModule}/${this.dataModuleAction}`,
        params
      );
    },
    /** This can be overridden at derived class to inject filters */
    getStoreParams(payload: any): Promise<any> {
      return payload;
    },
    setTable() {
      this.staticTable.limit = this.config.limit;
      this.staticTable.page = this.config.page;
      this.staticTable.sort = this.config.sort;
      this.staticTable.sorting = _.get(this.config, "sort.field") !== "";
      this.activeFilters = _.cloneDeep(this.config.filters);
    },
    updateRoute() {
      const query = this.newQuery;

      if (this.saveInStorage) {
        // Update local storage
        const usedConfig = this.lastConfig;

        localStorage.setItem(
          `config/${this.id}`,
          this.saveWithPage
            ? usedConfig
            : usedConfig.substring(
                usedConfig.indexOf(";") + 1,
                usedConfig.length
              )
        );

        // Update route query
        const newQueryPage = query[this.id]?.split(this.querySeparator)[0];
        const routePage = this.$route.query.p;
        const newQuery =
          newQueryPage === undefined ||
          newQueryPage === "1" ||
          this.saveWithPage
            ? {}
            : { p: newQueryPage };
        if (routePage !== newQuery.p) {
          this.$router.replace({ query: newQuery });
        }

        return;
      }

      if (hash(query) === hash(this.$route.query)) {
        return;
      }

      if (this.ignoreHistory) {
        return;
      }

      if (this.pushToHistory) {
        this.$router.push({ query });
      } else {
        this.$router.replace({ query });
      }
    },
    serialiseConfig(config: any): string {
      return serialiseConfig({
        ...config,
        querySeparator: this.querySeparator
      });
    },
    async translateLabelsAndGetOptions(filters: IFilter[]) {
      this.translateLabels(filters);
      await this.fetchOptions(filters);
    },
    async fetchOptions(filters: IFilter[]) {
      for (const filter of filters) {
        if (!_.isFunction(filter.optionsFunc)) continue;
        if (!_.isEmpty(filter.options)) continue;
        this.$set(filter, "options", await filter.optionsFunc());
      }
    },
    deSerialisedLocalStorageConfig() {
      const localStorageData = localStorage[`config/${this.id}`];

      if (!localStorageData)
        return deSerialisedConfig("", this.filters, this.querySeparator);

      return deSerialisedConfig(
        this.saveWithPage
          ? localStorageData
          : localStorageData.replace(
              /^(\d+;)?(\d+;)/,
              `${this.$route.query["p"] || 1};$2`
            ),
        this.filters,
        this.querySeparator
      );
    },
    copyToActiveFilters() {
      _.forEach(this.filters, async filter => {
        const activeFilter = _.find(this.activeFilters, ["key", filter.key]);
        if (!activeFilter) return;
        this.$set(activeFilter, "options", _.cloneDeep(filter.options));
        this.$set(activeFilter, "label", filter.label);
        this.$set(activeFilter, "type", filter.type);
        this.$set(activeFilter, "group", filter.group);
        this.$set(activeFilter, "optionsFunc", filter.optionsFunc);
        this.$set(activeFilter, "requires", filter.requires);
      });
    },
    translateLabels(filters: IFilter[]) {
      filters.forEach(filter => {
        return _.startsWith(filter.label, "_")
          ? this.$t(filter.label, {})
          : filter.label;
      });
    },
    cancelRequests() {
      this.$store.commit("api/cancelRequests", this.requestPromises);
      this.staticTable.loading = false;
    },
    async setLocalFilters() {
      await this.translateLabelsAndGetOptions(this.filters);
      this.copyToActiveFilters();
    },
    serialisedConfigWatch(query: any) {
      const serialiseDefaultConfig = this.serialiseConfig(this.defaults);
      if (
        _.get(this, "contentAvailable", true) &&
        query[this.id] !== this.lastConfig &&
        (query[this.id] !== serialiseDefaultConfig ||
          this.lastConfig !== serialiseDefaultConfig)
      ) {
        this.getData();
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch("data/binList", {
      storeModule: this.dataModule,
      scope: this.dataScope,
      vm: this
    });
  }
};
