import type { IDataState } from "@/store/modules/data";
import { DEFAULT_LIST_SCOPE } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";

import type { ActionTree, GetterTree } from "vuex";
import { AppHookCodes } from "@/data/enums/hooks";
import type { IAccount } from "@/models/accounts";
import type { ApiPathGetter } from "@/models/api";
import type { IClient } from "@upmind-automation/types";
import type { IEmail } from "@/models/emails";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import { ToastProgrammatic as $toast } from "buefy";
import _ from "@/boot/lodash";
import i18n from "@/i18n";
import { ModalCancelByAll } from "@/data/enums";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/clients";
      const client = "api/clients";
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  withParam: () => () => {
    return _.compact([
      "image",
      "tags",
      "accounts",
      "accounts.brand",
      "parent_client_config.parent_client"
    ]).join();
  },
  scope: () => id => {
    return `$client_${id}`;
  },
  basketScope: () => id => {
    return `$basket_client_${id}`;
  },
  getClientEmail: (state, getters) => (id: IEmail["id"]) => {
    return _.get(state, `${getters.scope(id)}.data.email`, "");
  },
  listParams: (state: IDataState) => () => {
    return _.get(state, `${DEFAULT_LIST_SCOPE}.params`, {});
  },
  canTopUpAccountCredit: () => (client: IClient) => {
    return !!client?.topup_enabled && !client.staged_import;
  },
  canImpersonateClient: (s, g, rS, rootGetters) => (client?: IClient) => {
    if (!rootGetters["isAdminContext"]) return false;
    if (!rootGetters["user/can"]("create_client_access_token")) return false;
    if (client?.is_guest || client?.staged_import) return false;
    return !!client?.has_login;
  },
  canImpersonateOrg: (s, g, rS, rootGetters) => (client?: IClient) => {
    if (!rootGetters["isAdminContext"]) return false;
    if (!rootGetters["user/can"]("create_user_access_token")) return false;
    if (!rootGetters["isUpmindContext"] || client?.staged_import) return false;
    return (
      !!client?.upmind_org_user_id &&
      !!client?.upmind_org?.upmind_impersonation_enabled
    );
  },
  canCreateChildAccount: (s, g, rS, rootGetters) => (client?: IClient) => {
    if (!rootGetters["isAdminContext"]) return false;
    if (!rootGetters["user/can"]("create_child_client_config")) return false;

    return _.isNull(client?.parent_client_config);
  },
  canLinkToAParent: (s, g, rS, rootGetters) => (client?: IClient) => {
    if (!rootGetters["isAdminContext"]) return false;
    if (!rootGetters["user/can"]("create_child_client_config")) return false;

    return (
      _.isNull(client?.parent_client_config) &&
      !client?.child_client_configs_count
    );
  },
  showChildAccounts:
    (state, getters, rS, rootGetters) => (id: IClient["id"]) => {
      if (rootGetters["isAdminContext"]) return false;

      return (
        (_.get(
          state,
          `${getters.scope(id)}.data.child_client_configs_count`,
          0
        ) as number) > 0
      );
    },
  showParentAccount:
    (state, getters, rS, rootGetters) => (id: IClient["id"]) => {
      if (rootGetters["isAdminContext"]) return false;

      return !_.isNull(
        _.get(state, `${getters.scope(id)}.data.parent_client_config`, null)
      );
    }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        splitCount: true,
        storeModule: DataModules.CLIENTS
      },
      { root: true }
    );
  },

  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${_.get(payload, "id")}`,
        storeModule: DataModules.CLIENTS
      },
      { root: true }
    );
  },
  getClientWithRelatedData: ({ dispatch, rootGetters }, payload) => {
    return dispatch("get", {
      ...payload,
      params: {
        with_staged_imports: 1,
        with: _.union(
          [
            "account", // Relation required for determining `topup_enabled` value
            "accounts.brand",
            "affiliate_referral.affiliate_account.account.client",
            "brand", // Get brand incase in org mode
            "custom_fields",
            "custom_fields.field",
            "default_email",
            "image",
            "import.credentials",
            "import.source",
            "legacy_invoices", // Required for determining `has_legacy_invoices` flag
            "parent_client_config",
            "parent_client_config.parent_client",
            "parent_client_config.parent_client.image",
            "tags"
          ],
          rootGetters.isUpmindContext ? ["upmind_org"] : []
        ).join(","),
        with_count: "child_client_configs"
      }
    });
  },
  resendVerificationEmail: ({ dispatch, getters }, payload) => {
    if (!_.has(payload, "id")) throw new Error("Client id not provided");
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/resend_verification`,
        requestConfig: {
          data: {
            client_id: _.get(payload, "id")
          }
        },
        storeModule: DataModules.CLIENTS
      },
      { root: true }
    );
  },
  resetPassword: ({ dispatch, getters }, payload) => {
    if (!_.has(payload, "id")) throw new Error("Client id not provided");
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/password_reset`,
        requestConfig: {
          data: {
            client_id: _.get(payload, "id")
          }
        },
        storeModule: DataModules.CLIENTS
      },
      { root: true }
    );
  },
  openAddClientModal: ({ rootGetters, dispatch }, payload) => {
    if (!rootGetters["user/can"]("create_client")) {
      return dispatch("ui/showErrorMessage", i18n.t("_.invalid_permissions"), {
        root: true
      });
    }
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          canCancel: ["button"],
          component: () =>
            import("@/components/app/global/client/addClientModal.vue"),
          onCancel: () => {
            dispatch(
              "ui/userflow/track",
              { event: AppHookCodes.ADD_CLIENT_ABANDONED },
              { root: true }
            );
          },
          ...payload
        }
      },
      { root: true }
    );
  },
  openSelectClientModal: ({ rootGetters, dispatch }, payload) => {
    if (!rootGetters["user/can"]("list_clients")) {
      return dispatch("ui/showErrorMessage", i18n.t("_.invalid_permissions"), {
        root: true
      });
    }
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/clients/selectClientModal.vue"),
          ...payload
        }
      },
      { root: true }
    );
  },
  mergeClient: (
    { dispatch },
    data: {
      source_account_id: IAccount["id"];
      source_client_id: IClient["id"];
      target_account_id: IAccount["id"];
      target_client_id: IClient["id"];
      brand_id: IClient["brand_id"];
    }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `api/admin/accounts/merge_account_client`,
        requestConfig: {
          data
        }
      },
      { root: true }
    );
  },
  mergeLead: ({ dispatch, getters }, { id, leadId, includeRelated = true }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${id}/import/lead/${leadId}`,
        requestConfig: {
          data: {
            include_related: includeRelated
          }
        }
      },
      { root: true }
    );
  },
  create: async ({ dispatch, getters }, payload) => {
    // Add client
    const client: IClient = await dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.CLIENTS
      },
      { root: true }
    );
    // Add track event
    dispatch(
      "ui/userflow/track",
      { event: AppHookCodes.ADD_CLIENT_COMPLETED },
      { root: true }
    );
    // Return client
    return client;
  },
  createGuestClient: ({ dispatch, getters }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/guest`
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    if (!_.has(payload, "id")) throw new Error("Client id not provided");
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${_.get(payload, "id")}`,
        storeModule: DataModules.CLIENTS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    if (!_.has(payload, "id")) throw new Error("Client id not provided");
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${_.get(payload, "id")}`,
        storeModule: DataModules.CLIENTS
      },
      { root: true }
    );
  },
  confirmDelete: async (
    { dispatch },
    {
      clientId,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onConfirm = () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose = () => {},
      onSuccess = () => {
        $toast.open({
          message: i18n.t("_.object_successfully_removed", {
            object: i18n.t("_.client")
          }) as string
        });
      },
      onError = error => {
        dispatch("api/handleValidationError", { error }, { root: true });
      }
    }: {
      clientId: IClient["id"];
      onConfirm: Function;
      onClose: Function;
      onSuccess: Function;
      onError: Function;
    }
  ) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            confirmButtonText: i18n.t("_action.delete"),
            message: i18n.t("_sentence.delete_confirmation", {
              object: (i18n.t("_.client") as string).toLowerCase()
            })
          },
          events: {
            confirmed: async () => {
              try {
                await dispatch("remove", {
                  id: clientId
                });
                await onSuccess();
              } catch (error) {
                await onError(error);
              }
              await onConfirm();
              confirm.close();
            },
            close: () => onClose()
          }
        }
      },
      { root: true }
    );
  },
  search: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/search",
      {
        ...payload,
        path: `${getters.apiPath().admin}/search`,
        splitCount: true,
        storeModule: DataModules.CLIENTS
      },
      { root: true }
    );
  },
  disable2FA: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.DELETE,
        path: `${getters.apiPath().contextual}/${_.get(payload, "id")}/twofa`,
        storeModule: DataModules.CLIENTS
      },
      { root: true }
    );
  },
  assignTags: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().admin}/${_.get(payload, "id")}/tags`,
        storeModule: DataModules.CLIENTS,
        requestConfig: {
          data: {
            tag_ids: _.get(payload, "tagIds")
          }
        }
      },
      { root: true }
    );
  },
  updateLoginDetails: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().contextual}/${_.get(payload, "id")}/login`,
        storeModule: DataModules.CLIENTS,
        requestConfig: {
          data: _.get(payload, "data")
        }
      },
      { root: true }
    );
  },
  openSetPasswordModal: ({ dispatch }, payload) => {
    dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/clients/setPasswordModal.vue"),
          width: 480,
          ...payload
        }
      },
      { root: true }
    );
  },
  generateSupportPin: (_ctx, { digits = 6 }) => {
    return _.range(1, digits + 1).map(() => _.random(1, 9, false));
  },
  updateSupportPin: async (
    { dispatch, getters, commit, rootGetters },
    { clientId, supportPin }
  ) => {
    await dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().contextual}/${clientId}`,
        storeModule: DataModules.CLIENTS,
        requestConfig: {
          data: { support_pin: supportPin }
        }
      },
      { root: true }
    );

    if (rootGetters.isAdminContext) {
      commit(
        `data/replaceByPath`,
        {
          storeModule: DataModules.CLIENTS,
          scope: getters.scope(clientId),
          path: `data.support_pin`,
          data: supportPin
        },
        { root: true }
      );
    } else {
      commit(
        "user/update",
        { key: "support_pin", value: supportPin },
        { root: true }
      );
    }
  },
  openVerifyPinModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 540,
          canCancel: ModalCancelByAll,
          component: () =>
            import(
              "@/components/app/global/client/clientVerifySupportPinModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    accounts: require("./accounts").default,
    addresses: require("./addresses").default,
    childAccounts: require("./childAccounts").default,
    companies: require("./companies").default,
    emails: require("./emails").default,
    paymentDetails: require("./paymentDetails").default,
    phones: require("./phones").default,
    vaultAssets: require("./vaultAssets").default
  }
};
