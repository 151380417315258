<template>
  <Columns>
    <Column class="is-12">
      <ProfileCard
        type="client"
        :email="$_.get(user, 'default_email.email')"
        :image-src="$_.get(user, 'image_url')"
        :name="$_.get(user, 'fullname')"
        :object-id="$_.get(user, 'id')"
        :profile="user"
        @avatar-changed="$store.dispatch('auth/client/getUser')"
      >
        <template #dl="{ username, created_at, last_login }">
          <!-- Username -->
          <template v-if="showUsername">
            <i18n tag="dt" path="_.username" />
            <dd class="has-overflow-ellipsis-right">
              <u-link :key="username" :to="securitySettingsRoute">{{
                username
              }}</u-link>
            </dd>
          </template>
          <!-- Account Type -->
          <template v-if="accountType">
            <i18n tag="dt" path="_.account_type" />
            <dd class="has-overflow-ellipsis-right">
              <component :is="accountType" />
            </dd>
          </template>
          <!-- Last login -->
          <i18n tag="dt" path="_.last_login" />
          <dd>
            <span v-if="!last_login" class="has-text-grey">–</span>
            <datetime v-else :datetime="last_login" />
          </dd>
          <!-- Client Since -->
          <i18n tag="dt" path="_.client_since" />
          <dd>
            <datetime
              :datetime="created_at"
              :format="$t('_datetime.date.default')"
            />
          </dd>
        </template>
      </ProfileCard>
    </Column>
    <column>
      <box>
        <menu-component
          :routes="routes(user.id)"
          background="light"
          class="is-paddingless is-size-7"
        />
      </box>
    </column>
    <template v-if="showSupportPin">
      <Column>
        <Box>
          <support-pin-view />
        </Box>
      </Column>
    </template>
  </Columns>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { BrandConfigKeys } from "@/data/constants";
import { ClientRoutes } from "@/data/enums/router";
import { DataModules } from "@/store/modules/data/modules";
import type { CreateElement } from "vue";
import type { IState } from "@/store";
import type { IUser } from "@/models/users";

export default defineComponent({
  name: "accountMenu",
  components: {
    SupportPinView: () =>
      import("@/components/app/client/clientSupportPinView.vue")
  },
  props: {
    routes: { type: Function, required: true }
  },
  computed: {
    ...mapState({
      showSupportPin: (state: IState): boolean =>
        !!state.brand.config[BrandConfigKeys.SUPPORT_PIN_ENABLED],
      user: (state: IState): IUser => state.user
    }),
    securitySettingsRoute() {
      return {
        name: ClientRoutes.ACCOUNT_SECURITY
      };
    },
    /**
     * @name showUsername
     * @desc Here we determine whether or not to show the client's username. If the
     * username is identical to their default email we won't show the username
     * field as it just adds duplicate data. */
    showUsername() {
      const { default_email, username } = this.user;
      if (default_email?.email === username) return false;
      return true;
    },
    accountType() {
      const {
        id: clientId,
        parent_client_config,
        child_client_configs_count
      } = this.user;
      // IS Child Account
      if (parent_client_config)
        return {
          render: (h: CreateElement) =>
            h(
              "i18n",
              {
                props: {
                  path: "_sentence.related_accounts.child_of_parent"
                }
              },
              [
                h(
                  "u-link",
                  {
                    slot: "parent",
                    on: {
                      click: () => this.manageParentRelation()
                    }
                  },
                  parent_client_config?.parent_client?.public_name
                )
              ]
            )
        };
      // IS Parent Account
      if (child_client_configs_count)
        return {
          render: (h: CreateElement) =>
            h(
              "i18n",
              {
                props: {
                  path: "_sentence.parent_with_children_link"
                }
              },
              [
                h(
                  "u-link",
                  {
                    slot: "childLink",
                    props: {
                      to: {
                        name: ClientRoutes.CHILD_ACCOUNTS,
                        params: { clientId }
                      }
                    }
                  },
                  [this.$tc("_plurals.children", child_client_configs_count)]
                )
              ]
            )
        };
      return null;
    }
  },
  mounted() {
    this.$bus.$emit("scrollToTop");
  },
  methods: {
    getUserProperty(property: string) {
      return this.$_.get(this.user, property);
    },
    manageParentRelation() {
      if (!this.user?.parent_client_config) return Promise.resolve();
      return this.$store.dispatch(
        `data/${DataModules.CLIENTS_CHILD_ACCOUNTS}/openManageChildAccountModal`,
        {
          parent: this,
          props: {
            parentClient: this.user?.parent_client_config?.parent_client,
            childAccount: this.user?.parent_client_config,
            childClient: this.user
          },
          events: {
            success: () => this.$store.dispatch("auth/client/getUser")
          }
        }
      );
    }
  }
});
</script>
