import { RegexMatch } from "@/data/enums/router";
import { SegmentTypes } from "@/data/enums/segments";
import type { Route } from "vue-router";

import CProdsListing from "@/components/app/global/contractProducts/cProdsListing.vue";
import SegmentResultsListing from "@/components/app/admin/segments/segmentResultsListing.vue";
import { ContractStatusCodes } from "@/models/contracts";

import {
  statusParam,
  AllStatusCodesFilter,
  isFromLast30DaysParam,
  hasUnresolvedRequestsParam,
  IsCategoryParam,
  CProdFiltersWithoutCategory
} from "@/data/filters/contractProducts";
import {
  DefaultCProdSorters,
  StatusSorter,
  DatePurchasedSorter,
  DateCancelledSorter,
  DateLapsedSorter
} from "@/data/sorters/contractProducts";

import { getDefaultCProdFilters } from "@/router/admin/contractProducts";
import { ListDisplayMode } from "@/data/constants";
import { UserMetaKeys } from "@/data/enums/users";

export default [
  {
    path: "list",
    name: "adminCProds",
    redirect: { name: "adminCProdsList" },
    components: {
      default: () => import("@/views/admin/contractProducts/all/index.vue")
    },
    meta: {
      backTo: "_.all"
    },
    children: [
      {
        path: "",
        name: "adminCProdsList",
        component: {
          name: "adminCProdsList",
          template: "<router-view />"
        },
        redirect: { name: "admin__allCProds" },
        children: [
          {
            path: "all",
            name: "admin__allCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                AllStatusCodesFilter()
              ],
              routeFilters: () => ({})
            }
          },
          {
            path: "fulfilled",
            name: "admin__fulfilledCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.ACTIVE])
              })
            }
          },
          {
            path: "awaiting-activation",
            name: "admin__awaitingActivationCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.AWAITING_ACTIVATION])
              })
            }
          },
          {
            path: "awaiting-payment",
            name: "admin__awaitingPaymentCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.PENDING])
              })
            }
          },
          {
            path: "suspended",
            name: "admin__suspendedCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.SUSPENDED])
              })
            }
          },
          {
            path: "cancelled",
            name: "admin__cancelledCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [
                StatusSorter(),
                DateCancelledSorter(),
                DatePurchasedSorter()
              ],
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.CANCELLED])
              })
            }
          },
          {
            path: "lapsed",
            name: "admin__lapsedCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [
                StatusSorter(),
                DateLapsedSorter(),
                DatePurchasedSorter()
              ],
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...statusParam([ContractStatusCodes.CLOSED])
              })
            }
          },
          {
            path: "last-30-days",
            name: "admin__fromLast30DaysCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                AllStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...isFromLast30DaysParam()
              })
            }
          },
          {
            path: "has-provision-issues",
            name: "admin__hasProvisionIssuesCProds",
            component: CProdsListing,
            props: {
              availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                AllStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...hasUnresolvedRequestsParam()
              })
            }
          }
        ]
      },
      {
        path: `category/:categoryId(${RegexMatch.UUID})`,
        name: "admin__allCategoryProducts",
        component: CProdsListing,
        props: {
          availableSorters: () => [StatusSorter(), DatePurchasedSorter()],
          availableFilters: () => [
            ...CProdFiltersWithoutCategory(),
            AllStatusCodesFilter()
          ],
          routeFilters: vm => ({
            ...IsCategoryParam(vm)
          })
        }
      },
      {
        path: `segment/:segmentId(${RegexMatch.UUID})`,
        name: "adminCProdsSegmentList",
        component: {
          name: "AdminCProdsSegmentList",
          template: "<router-view />"
        },
        redirect: { name: "admin__CProdsSegment" },
        children: [
          {
            path: "",
            name: "admin__CProdsSegment",
            component: SegmentResultsListing,
            props: (route: Route) => ({
              defaultDisplayMode: ListDisplayMode.GRID,
              displayModeStoragePath: UserMetaKeys.UI_CPRODS_VIEW,
              segmentTypeHint: SegmentTypes.CONTRACTS_PRODUCTS,
              segmentId: route.params.segmentId,
              newSegmentRouteName: "adminCProdsSegmentList",
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                AllStatusCodesFilter()
              ]
            })
          }
        ]
      }
    ]
  }
];
