import _ from "@/boot/lodash";
import i18n from "@/i18n";
import router from "@/router";
import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { IClient } from "@upmind-automation/types";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import { QUERY_PARAMS } from "@/data/constants";
import { RegexMatch } from "@/data/enums/router";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = "api/admin/contracts";
      const client = "api/contracts";
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => id => {
    return `$contract_${id}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.CONTRACTS,
        params: {
          ...payload.params
        }
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.CONTRACTS,
        params: {
          ...payload.params,
          with_staged_imports: 1
        }
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.CONTRACTS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.CONTRACTS
      },
      { root: true }
    );
  },
  openClientCancellationModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contracts/clientContractCancellationModal.vue"
            ),
          width: 640,
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  openAdminCancellationModal: ({ dispatch }, modalConfig) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contracts/adminContractCancellationModal.vue"
            ),
          ...modalConfig
        }
      },
      { root: true }
    );
  },
  cancel: ({ dispatch, getters }, { contractId, data }) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        requestConfig: { data },
        path: `${getters.apiPath().admin}/${contractId}/cancel`
      },
      { root: true }
    );
  },
  requestCancellation: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        requestConfig: { data: payload.data },
        path: `${getters.apiPath().contextual}/${payload.id}/cancel/request`
      },
      { root: true }
    );
  },
  updatePaymentDetails: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        path: `${getters.apiPath().contextual}/${payload.id}/payment_details`,
        requestConfig: { data: payload.data }
      },
      { root: true }
    );
  },
  approveCancellation: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        requestConfig: { data: payload.data },
        path: `${getters.apiPath().contextual}/${payload.id}/cancel/approve`
      },
      { root: true }
    );
  },
  rejectCancellation: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.DELETE,
        requestConfig: { data: payload.data },
        path: `${getters.apiPath().contextual}/${payload.id}/cancel/reject`
      },
      { root: true }
    );
  },
  deleteCancellation: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.DELETE,
        requestConfig: { data: payload.data },
        path: `${getters.apiPath().contextual}/${payload.id}/cancel/request`
      },
      { root: true }
    );
  },
  startNewOrder: async ({ rootGetters, dispatch }) => {
    if (!rootGetters["user/can"]("staff_create_order")) {
      dispatch("ui/showErrorMessage", i18n.t("_.invalid_permissions"), {
        root: true
      });
      return;
    }
    const modal = await dispatch(
      "data/clients/openSelectClientModal",
      {
        props: {
          allowGuestClientOption: true
        },
        events: {
          onSelect: (client: IClient) => {
            router.push({
              name: "adminOrderShop",
              params: { basketId: RegexMatch.NEW },
              query: {
                [QUERY_PARAMS.ACCOUNT_ID]: _.get(client, "accounts.[0].id"),
                [QUERY_PARAMS.CLIENT_ID]: _.get(client, "id")
              }
            });
            // @ts-ignore
            modal.close();
          }
        }
      },
      {
        root: true
      }
    );
  },
  openPreviewModal: ({ dispatch }, { orderId }) => {
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/orders/orderModal.vue"),
          width: 840,
          props: {
            orderId
          }
        }
      },
      { root: true }
    );
  },
  openChangePaymentMethodModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/global/contractProducts/changePaymentMethodModal.vue"
            ),
          width: 720,
          ...payload
        }
      },
      { root: true }
    );
  },
  openSelectClientProductModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          canCancel: ["escape", "button", "x", "outside"],
          component: () =>
            import(
              "@/components/app/global/contractProducts/selectClientProductModal.vue"
            ),
          ...payload,
          width: 940
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    categories: require("./categories").default,
    products: require("./products").default,
    cancellationRequests: require("./cancellationRequests").default
  }
};
