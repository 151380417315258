import type { IOperator } from "@/models/tables";
import i18n from "@/i18n";

export enum ApiOperators {
  DEFAULT = "",
  EQUAL = "eq",
  LIKE = "like",
  NOT_LIKE = "nlike",
  GREATER_THAN = "gt",
  GREATER_THAN_OR_EQUAL_TO = "gte",
  LESS_THAN = "lt",
  LESS_THAN_OR_EQUAL_TO = "lte",
  NOT_EQUAL = "neq",
  BEFORE = "before",
  AFTER = "after",
  ALL = "all"
}

export enum FilterOperators {
  ALL = "all",
  ANY = "any",
  CONTAINS = "*",
  STARTS_WITH = "^",
  ENDS_WITH = "$",
  EQUAL = "=",
  NOT_EQUAL = "neq",
  GREATER_THAN = "gt",
  GREATER_THAN_OR_EQUAL_TO = "gte",
  LESS_THAN = "lt",
  LESS_THAN_OR_EQUAL_TO = "lte",
  AFTER = ">",
  BEFORE = "<",
  AFTER_RELATIVE = "ar",
  BEFORE_RELATIVE = "br",
  OR_ON_BEFORE_DATE = "af",
  OR_ON_AFTER_DATE = "be",
  NOT_SET = "nset"
}

export enum FilterTypes {
  STRING = "string",
  SELECT = "select",
  SELECT_RADIO = "select-radio",
  SELECT_RADIO_GROUP = "select-radio-group",
  SELECT_CHECKBOX = "select-checkbox",
  SELECT_MULTI = "select-multi",
  TAG = "tag",
  DATE = "date",
  MONTH = "month",
  NUMBER = "number",
  CATEGORY_DATA_SELECTOR = "category-data-selector"
}

export const FILTER_TYPES_WITH_ARRAY_VALUES = [
  FilterTypes.TAG,
  FilterTypes.SELECT_MULTI,
  FilterTypes.SELECT_CHECKBOX,
  FilterTypes.CATEGORY_DATA_SELECTOR
];
export const FILTER_ARRAY_VALUES_SEPARATOR = ",";
export const FRONTEND_FILTER_ARRAY_VALUES_SEPARATOR = "&&";

export const FILTER_OPERATORS: () => IOperator[] = () => [
  {
    key: FilterOperators.CONTAINS,
    label: i18n.t("_.contains"),
    apiOperator: ApiOperators.LIKE,
    types: [FilterTypes.STRING]
  },
  {
    key: FilterOperators.STARTS_WITH,
    label: i18n.t("_.starts_with"),
    apiOperator: ApiOperators.LIKE,
    types: [FilterTypes.STRING]
  },
  {
    key: FilterOperators.ENDS_WITH,
    label: i18n.t("_.ends_with"),
    apiOperator: ApiOperators.LIKE,
    types: [FilterTypes.STRING]
  },
  {
    key: FilterOperators.EQUAL,
    label: i18n.t("_.equals"),
    apiOperator: ApiOperators.DEFAULT,
    types: [
      FilterTypes.STRING,
      FilterTypes.DATE,
      FilterTypes.NUMBER,
      FilterTypes.SELECT,
      FilterTypes.SELECT_RADIO,
      FilterTypes.SELECT_RADIO_GROUP,
      FilterTypes.SELECT_CHECKBOX,
      FilterTypes.SELECT_MULTI
      // FilterTypes.TAG
    ]
  },
  {
    key: FilterOperators.NOT_EQUAL,
    label: i18n.t("_.different_than"),
    apiOperator: ApiOperators.NOT_EQUAL,
    types: [
      FilterTypes.STRING,
      FilterTypes.NUMBER,
      FilterTypes.SELECT,
      FilterTypes.SELECT_RADIO,
      FilterTypes.SELECT_RADIO_GROUP,
      FilterTypes.SELECT_CHECKBOX,
      FilterTypes.TAG
    ]
  },
  {
    key: FilterOperators.ALL,
    label: i18n.t("_.matches_all"),
    apiOperator: ApiOperators.ALL,
    types: [FilterTypes.TAG]
  },
  {
    key: FilterOperators.ANY,
    label: i18n.t("_.matches_any"),
    apiOperator: ApiOperators.DEFAULT,
    types: [FilterTypes.TAG, FilterTypes.CATEGORY_DATA_SELECTOR]
  },
  {
    key: FilterOperators.NOT_SET,
    label: i18n.t("_.not_set"),
    apiOperator: ApiOperators.DEFAULT,
    types: [FilterTypes.TAG]
  },
  {
    key: FilterOperators.GREATER_THAN,
    label: i18n.t("_.greater_than"),
    apiOperator: ApiOperators.GREATER_THAN,
    types: [FilterTypes.NUMBER]
  },
  {
    key: FilterOperators.GREATER_THAN_OR_EQUAL_TO,
    label: i18n.t("_.greater_equal"),
    apiOperator: ApiOperators.GREATER_THAN_OR_EQUAL_TO,
    types: [FilterTypes.NUMBER]
  },
  {
    key: FilterOperators.LESS_THAN,
    label: i18n.t("_.less_than"),
    apiOperator: ApiOperators.LESS_THAN,
    types: [FilterTypes.NUMBER]
  },
  {
    key: FilterOperators.LESS_THAN_OR_EQUAL_TO,
    label: i18n.t("_.less_equal"),
    apiOperator: ApiOperators.LESS_THAN_OR_EQUAL_TO,
    types: [FilterTypes.NUMBER]
  },
  {
    key: FilterOperators.AFTER,
    label: i18n.t("_.after"),
    apiOperator: ApiOperators.GREATER_THAN,
    types: [FilterTypes.DATE, FilterTypes.SELECT_RADIO_GROUP]
  },
  {
    key: FilterOperators.OR_ON_AFTER_DATE,
    label: i18n.t("_.on_or_after"),
    apiOperator: ApiOperators.GREATER_THAN_OR_EQUAL_TO,
    types: [FilterTypes.DATE, FilterTypes.SELECT_RADIO_GROUP]
  },
  {
    key: FilterOperators.BEFORE,
    label: i18n.t("_.before"),
    apiOperator: ApiOperators.LESS_THAN,
    types: [FilterTypes.DATE, FilterTypes.SELECT_RADIO_GROUP]
  },
  {
    key: FilterOperators.OR_ON_BEFORE_DATE,
    label: i18n.t("_.on_or_before"),
    apiOperator: ApiOperators.LESS_THAN_OR_EQUAL_TO,
    types: [FilterTypes.DATE, FilterTypes.SELECT_RADIO_GROUP]
  },
  {
    key: FilterOperators.AFTER_RELATIVE,
    label: i18n.t("_.after"),
    apiOperator: ApiOperators.AFTER,
    types: [
      FilterTypes.DATE,
      FilterTypes.SELECT_RADIO_GROUP,
      FilterTypes.SELECT,
      FilterTypes.STRING
    ]
  },
  {
    key: FilterOperators.BEFORE_RELATIVE,
    label: i18n.t("_.before"),
    apiOperator: ApiOperators.BEFORE,
    types: [
      FilterTypes.DATE,
      FilterTypes.SELECT_RADIO_GROUP,
      FilterTypes.SELECT
    ]
  }
];

export const RELATIVE_DATE_OPERATORS = [
  FilterOperators.AFTER_RELATIVE,
  FilterOperators.BEFORE_RELATIVE
];

export const OPERATORS_ALLOWED_TO_HAVE_SUBFILTERS = [
  FilterOperators.CONTAINS,
  FilterOperators.STARTS_WITH,
  FilterOperators.ENDS_WITH
];

export const RELATIVE_DATE_PERIODS: {
  value: string;
  label: string;
}[] = [
  {
    value: "hours",
    get label() {
      return i18n.t("hours").toString();
    }
  },
  {
    value: "days",
    get label() {
      return i18n.t("days").toString();
    }
  },
  {
    value: "weeks",
    get label() {
      return i18n.t("weeks").toString();
    }
  },
  {
    value: "months",
    get label() {
      return i18n.t("months").toString();
    }
  },
  {
    value: "years",
    get label() {
      return i18n.t("years").toString();
    }
  }
];

type RelativeDateType = {
  value: string;
  label: string;
};

export const RELATIVE_DATE_TIMES: () => RelativeDateType[] = () => [
  {
    value: "-",
    label: i18n.t("_.ago").toString()
  },
  {
    value: "+",
    label: i18n.t("_.from_now").toString()
  }
];

export enum Sorters {
  ASCENDING = "+",
  DESCENDING = "-"
}

export const RELATIVE_TIME_REGEX = /^[+-]?[0-9]+_[a-z]+$/;
export const SUBFILTER_CONCAT_CHAR = ",";
export const CONSTANT_FILTERS_START_CHAR = "$";
export const ROUTE_QUERY_SEPARATOR = ";";
export const ROUTER_OPERATOR_SEPARATOR = "|";
