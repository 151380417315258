import i18n from "@/i18n";
import type { IMenuRoute } from "@/models/routes";
import { AdminRoutes } from "@/data/enums/router";

export function routes() {
  return [
    {
      group: i18n.t("_.branding_and_customisation"),
      order: 0,
      routes: [
        {
          icon: "palette",
          name: i18n.t("_.object_settings", { object: i18n.t("_.brand") }),
          to: { name: "brandGeneralSettings" },
          auth: ["get_brand"],
          description: i18n.t("_sentence.admin_brand_settings_desc"),
          order: 1000,
          code: "brand_settings"
        },
        {
          icon: "file-contract",
          name: i18n.t("_.terms_and_conditions"),
          to: { name: AdminRoutes.SETTINGS_TERMS_AND_CONDITIONS },
          auth: ["get_brand", "get_current_terms_and_conditions"],
          description: i18n.t("_sentence.admin_terms_settings_desc"),
          order: 1001,
          code: "terms_and_conditions"
        },
        {
          icon: "pen-nib",
          name: i18n.t("_.client_custom_fields"),
          to: { name: AdminRoutes.CUSTOM_CLIENT_FIELDS_SETTINGS },
          auth: ["list_client_fields"],
          description: i18n.t("_sentence.admin_custom_fields_settings_desc"),
          order: 1002,
          code: "custom_fields"
        },
        {
          icon: "globe",
          name: i18n.t("_.domains"),
          to: { name: "brandDomainsSettings" },
          auth: ["list_oauth_clients"],
          description: i18n.t("_sentence.admin_domain_settings_desc"),
          order: 1003,
          code: "domains"
        },
        {
          icon: "building",
          name: i18n.t("_.brands"),
          to: { name: "listAdminBrands" },
          auth: ["list_brands"],
          description: i18n.t("_sentence.admin_multibrand_settings_desc"),
          order: 1005,
          code: "multiple_brands"
        },
        {
          icon: "tablet-alt",
          name: i18n.t("_.user_interface"),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "ui"
            }
          },
          auth: [],
          description: i18n.t("_sentence.admin_user_interface_settings_desc"),
          order: 1006,
          code: "ui"
        },
        {
          icon: "life-ring",
          name: i18n.t("_.service_desk"),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "tickets"
            }
          },
          auth: [],
          description: i18n.t("_sentence.admin_service_desk_settings_desc"),
          order: 1007,
          code: "tickets"
        },
        {
          icon: "headset",
          name: i18n.t("_.support_departments"),
          to: { name: AdminRoutes.SUPPORT_DEPARTMENTS },
          auth: ["list_tickets_departments"],
          description: i18n.t(
            "_sentence.admin_support_departments_settings_desc"
          ),
          order: 1008,
          code: "support_departments"
        },
        {
          icon: "file-signature",
          name: i18n.t("_.subscription_options"),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "subscriptions"
            }
          },
          auth: [],
          description: i18n.t(
            "_sentence.admin_subscription_options_settings_desc"
          ),
          order: 1009,
          code: "subscriptions"
        },
        {
          icon: "unlock-alt",
          name: i18n.t("_.security"),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "security"
            }
          },
          auth: [],
          description: i18n.t("_sentence.admin_security_settings_desc"),
          order: 1010,
          code: "security"
        },
        {
          icon: "coins",
          name: i18n.t("_.currencies"),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "currencies"
            }
          },
          auth: [],
          description: i18n.t("_sentence.admin_currencies_settings_desc"),
          order: 1011,
          code: "currencies"
        },
        {
          icon: "code",
          name: i18n.t("_.client_area_templates"),
          to: { name: "admin__clientTemplates" },
          auth: [
            "list_templates",
            "list_client_area_template_slots",
            "list_client_area_template_mappings"
          ],
          description: i18n.t("_sentence.admin_client_templates_settings_desc"),
          order: 1012,
          code: "client_templates"
        }
      ]
    },
    {
      group: i18n.t("_.object_settings", { object: i18n.t("_.ecommerce") }),
      order: 1,
      routes: [
        {
          icon: "shopping-cart",
          name: i18n.t("_.business_settings"),
          to: { name: "brandStoreSettings" },
          auth: ["get_brand"],
          description: i18n.t("_sentence.admin_store_settings_desc"),
          order: 1000,
          code: "store"
        },
        {
          icon: "file-invoice",
          name: i18n.t("_.order_options"),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "invoices"
            }
          },
          auth: ["get_brand", "list_countries"],
          description: i18n.t("_sentence.admin_invoice_settings_desc"),
          order: 1001,
          code: "invoices"
        },
        {
          icon: "coins",
          name: i18n.t("_.object_settings", { object: i18n.t("_.tax") }),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "price_tax"
            }
          },
          auth: ["get_brand", "list_countries"],
          description: i18n.t("_sentence.admin_tax_settings_desc"),
          order: 1002,
          code: "price_tax"
        },
        {
          icon: "credit-card",
          name: i18n.t("_.payment_providers"),
          to: { name: "brandPaymentProvidersSettings" },
          auth: ["get_brand", "list_brand_gateways"],
          description: i18n.t("_sentence.admin_payment_settings_desc"),
          order: 1003,
          code: "payment_providers"
        },
        {
          icon: "file-invoice",
          name: i18n.t("_.payment_options"),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "billing"
            }
          },
          auth: [],
          description: i18n.t(
            "_sentence.admin_billing_and_payments_settings_desc"
          ),
          order: 1004,
          code: "billing"
        },
        {
          icon: "user-secret",
          name: i18n.t("_.fraud"),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "fraud"
            }
          },
          auth: ["list_fraud_check_settings"],
          description: i18n.t("_sentence.admin_fraud_detection_settings_desc"),
          order: 1005,
          code: "fraud"
        },
        {
          icon: "pen-nib",
          name: i18n.t("_.basket_custom_fields"),
          to: { name: AdminRoutes.CUSTOM_BASKET_FIELDS_SETTINGS },
          auth: ["list_basket_fields"],
          description: i18n.t(
            "_sentence.admin_basket_custom_fields_settings_desc"
          ),
          order: 1006,
          code: "basket_custom_fields"
        },
        {
          icon: "pen-nib",
          name: i18n.t("_.custom_cancellation_fields"),
          to: { name: AdminRoutes.CUSTOM_CANCELLATION_FIELDS_SETTINGS },
          auth: [],
          description: i18n.t(
            "_sentence.custom_fields.cancellation_settings_desc"
          ),
          order: 1007,
          code: "custom-cancellation-fields"
        },
        {
          icon: "percent",
          name: i18n.t("_.affiliates"),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "affiliate_systems"
            }
          },
          auth: [],
          description: i18n.t("_sentence.admin_affiliates_settings_desc"),
          order: 1008,
          code: "affiliate_systems"
        },
        {
          icon: "percent",
          name: i18n.t("_.external_affiliate_systems"),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "external_affiliate_systems"
            }
          },
          auth: [],
          description: i18n.t(
            "_sentence.admin_external_affiliate_systems_settings_desc"
          ),
          order: 1009,
          code: "external_affiliate_systems"
        },
        {
          icon: "file-invoice",
          name: i18n.t("_.order_templates"),
          to: { name: "orderTemplatesSettings" },
          auth: [],
          description: i18n.t("_sentence.admin_order_templates_settings_desc"),
          order: 1010,
          code: "order_templates"
        },
        {
          icon: "file-pdf",
          name: i18n.t("_.invoice_template"),
          to: { name: "invoiceTemplateSettings" },
          auth: [],
          description: i18n.t("_sentence.admin_invoice_template_settings_desc"),
          order: 1011,
          code: "invoice_template"
        },
        {
          icon: "virus",
          name: i18n.t("_.spam_conditions"),
          to: { name: "admin__settingsSpamConditions" },
          auth: [],
          description: i18n.t("_sentence.admin_spam_conditions_desc"),
          order: 1012,
          code: "spam_conditions"
        }
      ]
    },
    {
      group: i18n.t("_.products_and_promotions"),
      order: 2,
      routes: [
        {
          icon: "box",
          name: i18n.t("_.product_catalogue"),
          to: { name: "adminProductCatalogue" },
          auth: ["staff_list_products", "list_product_categories"],
          description: i18n.t("_sentence.admin_products_settings_desc"),
          order: 1000,
          code: "product_and_services"
        },
        {
          icon: "percent",
          name: i18n.t("_.promotions"),
          to: { name: "admin__promotions" },
          auth: ["list_promotions"],
          description: i18n.t("_sentence.admin_promotion_settings_desc"),
          order: 1001,
          code: "promotions"
        },
        {
          icon: "user-tag",
          name: i18n.t("_.price_lists"),
          to: { name: "adminCataloguePriceLists" },
          auth: [],
          description: i18n.t("_sentence.admin_pricelists_settings_desc"),
          order: 1002,
          code: "price_lists"
        }
      ]
    },
    {
      group: i18n.t("_.provisioning"),
      requiresOrgProvisioning: true,
      order: 3,
      routes: [
        {
          icon: "sliders-h",
          name: i18n.t("_.provision_configurations"),
          to: { name: "adminCatalogueProvisioning" },
          auth: [],
          description: i18n.t(
            "_sentence.admin_provider_configurations_settings_desc"
          ),
          order: 1000,
          code: "provision"
        },
        {
          icon: "sliders-h",
          name: i18n.t("_.brand_provisioning_fields"),
          to: { name: "adminBrandProvisioning" },
          auth: [],
          description: i18n.t(
            "_sentence.admin_brand_provisioning_settings_desc"
          ),
          order: 1001,
          code: "brand-provisioning"
        }
      ]
    },
    {
      group: i18n.t("_.users_and_permissions"),
      order: 4,
      routes: [
        {
          icon: "users",
          name: i18n.t("_.staff_groups"),
          to: { name: "listUserGroups" },
          auth: ["list_user_groups"],
          description: i18n.t("_sentence.admin_user_groups_settings_desc"),
          order: 1000,
          code: "user_groups"
        },
        {
          icon: "user-circle",
          name: i18n.t("_.staff_users"),
          to: { name: "adminUsers" },
          auth: ["list_users"],
          description: i18n.t("_sentence.admin_staff_users_settings_desc"),
          order: 1001,
          code: "staff_users"
        },
        {
          icon: "plug",
          name: i18n.t("_.api_tokens"),
          to: { name: "adminApiTokens" },
          auth: ["list_user_groups"],
          description: i18n.t("_sentence.admin_api_tokens_settings_desc"),
          order: 1002,
          code: "api_tokens"
        }
      ]
    },
    {
      group: i18n.t("_.automation"),
      order: 4,
      routes: [
        {
          icon: "robot",
          name: i18n.t("_.actions"),
          to: { name: "adminAutomationChannels" },
          auth: ["list_notification_channels", "list_templates"],
          description: i18n.t(
            "_sentence.admin_automation_actions_settings_desc"
          ),
          order: 1000,
          code: "actions"
        },
        {
          icon: "crosshairs",
          name: i18n.t("_.triggers"),
          to: { name: "adminAutomationTriggers" },
          auth: ["list_notifiable_hooks"],
          description: i18n.t(
            "_sentence.admin_automation_triggers_settings_desc"
          ),
          order: 1001,
          code: "triggers"
        },
        {
          icon: "comments",
          name: i18n.t("_.notification_channels"),
          to: {
            name: "adminDynamicSettings",
            params: {
              settingId: "notification_channels"
            }
          },
          auth: [],
          description: i18n.t(
            "_sentence.admin_notification_channels_settings_desc"
          ),
          order: 1002,
          code: "notification_channels"
        },
        {
          icon: "envelope",
          name: i18n.t("_.email_templates"),
          to: { name: "adminEmailTemplates" },
          auth: ["list_notification_channels", "list_templates"],
          description: i18n.t("_sentence.admin_email_templates_settings_desc"),
          order: 1004,
          code: "email_templates"
        },
        {
          icon: "bolt",
          name: i18n.t("_.webhooks"),
          to: { name: AdminRoutes.WEBHOOKS_SETTINGS },
          auth: ["list_webhooks"],
          description: i18n.t("_sentence.webhooks.webhooks_settings_desc"),
          order: 1005,
          code: "webhooks"
        }
      ]
    },
    {
      group: i18n.t("_.miscellaneous"),
      order: 5,
      routes: [
        {
          icon: "file-import",
          name: i18n.t("_.imports"),
          to: { name: "adminImports" },
          auth: ["list_imports"],
          description: i18n.t("_sentence.admin_import_settings_desc"),
          order: 1,
          code: "imports"
        },
        {
          icon: "database",
          name: i18n.t("_.demo_data"),
          to: { name: AdminRoutes.DEMO_DATA_SETTINGS },
          auth: [],
          description: i18n.t("_sentence.demo_data_settings_desc"),
          order: 2,
          code: "demo-data"
        },
        {
          icon: "exclamation-circle",
          name: i18n.t("_.retention_reasons"),
          to: { name: AdminRoutes.SETTINGS_RETENTION_REASONS },
          auth: [],
          description: i18n.t(
            "_sentence.retentions.admin_reasons_settings_desc"
          ),
          order: 2,
          code: "retention-reasons"
        }
      ]
    }
  ];
}

export const userRoutes = vm => [
  {
    label: "_.overview",
    to: (vm: any) => {
      return {
        name: "adminUserOverview",
        params: vm.$route.params
      };
    }
  },
  {
    label: "_.notifications",
    to: (vm: any) => {
      return {
        name: "adminUserNotifications",
        params: vm.$route.params
      };
    }
  },
  {
    label: "_.email_history",
    to: (vm: any) => {
      return {
        name: "adminUserEmailHistory",
        params: vm.$route.params
      };
    },
    if: () => !vm.isApiToken
  },
  {
    label: "_.activity_log",
    to: (vm: any) => {
      return {
        name: "adminUserActivityLog",
        params: vm.$route.params
      };
    }
  }
];

export function usersTabigation() {
  return [
    {
      label: i18n.t("_.all"),
      to: {
        name: `adminUsers`
      }
    },
    {
      label: i18n.t("_.active"),
      to: {
        name: `adminUsersActive`
      }
    },
    {
      label: i18n.t("_.inactive"),
      to: {
        name: `adminUsersInactive`
      }
    }
  ];
}

export function paymentProvidersTabigation() {
  return [
    {
      label: i18n.t("_.all"),
      to: {
        name: "admin__settingsPaymentProvidersAll"
      }
    },
    {
      label: i18n.t("_.active"),
      to: {
        name: "admin__settingsPaymentProvidersActive"
      }
    },
    {
      label: i18n.t("_.inactive"),
      to: {
        name: "admin__settingsPaymentProvidersInactive"
      }
    }
  ];
}

export function apiTokensTabigation() {
  return [
    {
      label: i18n.t("_.all"),
      to: {
        name: `adminApiTokens`
      }
    },
    {
      label: i18n.t("_.active"),
      to: {
        name: `adminApiTokensActive`
      }
    },
    {
      label: i18n.t("_.inactive"),
      to: {
        name: `adminApiTokensInactive`
      }
    },
    {
      label: i18n.t("_.revoked"),
      to: {
        name: `adminApiTokensRevoked`
      }
    }
  ];
}

export function automationRoutes() {
  return [
    {
      label: i18n.t("_.actions"),
      to: {
        name: "adminAutomationChannels"
      },
      meta: {
        auth: ["list_notification_channels", "list_templates"]
      }
    },
    {
      label: i18n.t("_.triggers"),
      to: {
        name: "adminAutomationTriggers"
      },
      meta: {
        auth: ["list_notification_categories"]
      }
    }
  ];
}

export function catalogueTabigation() {
  return [
    {
      label: i18n.t("_.products_and_services"),
      to: {
        name: `adminCatalogueProducts`
      }
    },
    {
      label: i18n.t("_.product_options"),
      to: {
        name: `adminCatalogueOptions`
      }
    },
    {
      label: i18n.t("_.product_attributes"),
      to: {
        name: `adminCatalogueAttributes`
      }
    }
  ];
}

export function promotionTabigation() {
  return [
    {
      label: i18n.t("_.configuration"),
      to: {
        name: `adminPromotionConfiguration`
      }
    },
    {
      label: i18n.t("_.uses"),
      to: {
        name: `adminPromotionUses`
      }
    },
    {
      label: i18n.t("_.activity_log"),
      to: {
        name: `adminPromotionActivity`
      }
    }
  ];
}

export const productsQuickFilters = [
  {
    label: "_.all_products_and_bundles",
    to: {
      name: `adminCatalogueProducts`
    }
  }
];

export const productOptionsQuickFilters = [
  {
    label: "_.all_product_options",
    to: {
      name: `adminCatalogueOptions`
    }
  }
];

export const productAttributesQuickFilters = [
  {
    label: "_.all_product_attributes",
    to: {
      name: `adminCatalogueAttributes`
    }
  }
];

export const productOverview = vm => [
  {
    label: i18n.t("_.product_details"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueProductDetails",
        params: vm.$route.params
      };
    }
  },
  {
    label: i18n.t("_.product_billing"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueProductBilling",
        params: vm.$route.params
      };
    },
    hasWarning: vm.hasMissingPrices,
    hasError: vm.routeHasError
  },
  {
    label: i18n.t("_.product_automation"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueProductAutomation",
        params: vm.$route.params
      };
    },
    if: () => {
      return vm.canShowAutomation;
    }
  },
  {
    label: i18n.t("_.product_options"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueProductOptions",
        params: vm.$route.params
      };
    }
  },
  {
    label: i18n.t("_.product_attributes"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueProductAttributes",
        params: vm.$route.params
      };
    }
  },
  {
    label: i18n.t("_.provisioning"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueProductProvisioning",
        params: vm.$route.params
      };
    },
    if: () => {
      return vm.canShowProvisioning;
    }
  },
  {
    label: i18n.t("_.upgrades_downgrades"),
    to: (vm: any) => {
      return {
        name: "admin__catalogueProductUpgradeDowngrade",
        params: vm.$route.params
      };
    },
    if: () => {
      return vm.canShowUpgradesDowngrades;
    }
  },
  {
    label: i18n.tc("_.recommendations", 2),
    to: (vm: any) => {
      return {
        name: "adminCatalogueProductRecommendations",
        params: vm.$route.params
      };
    },
    if: () => {
      return vm.canShowRecommendations;
    }
  },

  {
    label: i18n.t("_.product_notifications"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueProductNotifications",
        params: vm.$route.params
      };
    },
    if: (vm: any) => vm.$userCan("list_object_notification_mappings")
  },
  {
    label: i18n.t("_.client_area_templates"),
    to: (vm: any) => {
      return {
        name: "admin__catalogueProductTemplates",
        params: vm.$route.params
      };
    }
  },
  {
    label: i18n.t("_.insights"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueProductContractProducts",
        params: vm.$route.params
      };
    },
    children: [
      {
        label: i18n.t("_.products_and_services"),
        to: (vm: any) => {
          return {
            name: "adminCatalogueProductContractProducts",
            params: vm.$route.params
          };
        }
      }
    ],
    if: () => {
      return vm.canShowContractProducts;
    }
  }
];

export const categoryOverview = vm => [
  {
    label: i18n.t("_.category_details"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueCategoryDetails",
        params: vm.$route.params
      };
    }
  },
  {
    label: i18n.t("_.category_products"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueCategoryProducts",
        params: vm.$route.params
      };
    }
  },
  {
    label: i18n.t("_.provisioning"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueCategoryProvisioning",
        params: vm.$route.params
      };
    },
    if: () => {
      return vm.canShowProvisioning;
    }
  }
];

export const optionCategoryOverview = () => [
  {
    label: i18n.t("_.category_details"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueOptionCategoryDetails",
        params: vm.$route.params
      };
    }
  },
  {
    label: i18n.t("_.category_options"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueOptionCategoryProducts",
        params: vm.$route.params
      };
    }
  }
];

export const attributeCategoryOverview = () => [
  {
    label: i18n.t("_.category_details"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueAttributeCategoryDetails",
        params: vm.$route.params
      };
    }
  },
  {
    label: i18n.t("_.category_attributes"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueAttributeCategoryProducts",
        params: vm.$route.params
      };
    }
  }
];

export const productOptionOverview = () => [
  {
    label: i18n.t("_.product_option_details"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueOptionDetails",
        params: vm.$route.params
      };
    }
  },
  {
    label: i18n.t("_.product_option_billing"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueOptionBilling",
        params: vm.$route.params
      };
    }
  }
];

export const productAttributeOverview = () => [
  {
    label: i18n.t("_.product_attribute_details"),
    to: (vm: any) => {
      return {
        name: "adminCatalogueAttributeDetails",
        params: vm.$route.params
      };
    }
  }
];

export const importTabigation = () => [
  {
    label: i18n.t("_.overview"),
    to: () => {
      return {
        name: "adminImport"
      };
    }
  },
  {
    label: i18n.t("_.logs"),
    to: () => {
      return {
        name: "adminImportLogs"
      };
    }
  }
];

export const catalogueProductsCPsTabigation = () => [
  {
    label: i18n.t("_.all"),
    to: () => {
      return {
        name: AdminRoutes.CATALOGUE_PRODUCT_ALL_CPS
      };
    }
  },
  {
    label: i18n.t("_.active"),
    to: () => {
      return {
        name: AdminRoutes.CATALOGUE_PRODUCT_ACTIVE_CPS
      };
    }
  },
  {
    label: i18n.t("_.cancelled"),
    to: () => {
      return {
        name: AdminRoutes.CATALOGUE_PRODUCT_CANCELLED_CPS
      };
    }
  }
];

export function automationActionTabigations() {
  return [
    {
      label: i18n.t("_action.edit"),
      to: {
        name: "adminEditAutomationAction"
      }
    },
    {
      label: i18n.t("_.sent_email_history"),
      to: {
        name: "adminAutomationActionEmailHistory"
      }
    }
  ];
}

export function automationWebhookTabigations() {
  return [
    {
      label: i18n.t("_action.manage"),
      to: {
        name: AdminRoutes.WEBHOOKS_SETTINGS_WEBHOOK_EDIT
      }
    },
    {
      label: i18n.t("_.events_history"),
      to: {
        name: AdminRoutes.WEBHOOKS_SETTINGS_WEBHOOK_EVENTS
      }
    }
  ];
}

export function emailTemplatesTabigations() {
  return [
    {
      label: i18n.t("_action.edit"),
      to: {
        name: AdminRoutes.EMAIL_TEMPLATE_EDIT
      }
    },
    {
      label: i18n.t("_.sent_email_history"),
      to: {
        name: AdminRoutes.EMAIL_TEMPLATE_HISTORY
      }
    }
  ];
}

// Promotions
export function AdminPromotionsRoutes(): IMenuRoute[] {
  return [
    {
      label: i18n.t("_.all_promotions"),
      to: {
        name: "admin__allPromotions"
      }
    },
    {
      label: i18n.t("_.active_promotions"),
      to: {
        name: "admin__activePromotions"
      }
    },
    {
      label: i18n.t("_.inactive_promotions"),
      to: {
        name: "admin__inactivePromotions"
      }
    },
    {
      label: i18n.t("_.deleted_promotions"),
      to: {
        name: "admin__deletedPromotions"
      }
    }
  ];
}

export function ClientTemplatesTabigation() {
  return [
    {
      label: i18n.t("_.slots"),
      to: {
        name: `admin__clientTemplateSlots`
      }
    },
    {
      label: i18n.t("_.templates"),
      to: {
        name: `admin__clientTemplateTemplates`
      }
    }
  ];
}

export function CatalogueProductTemplatesTabigation() {
  return [
    {
      label: i18n.t("_.slots"),
      to: {
        name: `admin__catalogueProductTemplateSlots`
      }
    },
    {
      label: i18n.t("_.templates"),
      to: {
        name: `admin__catalogueProductTemplateTemplates`
      }
    }
  ];
}
