import { ClientRoutes, GuestRoutes } from "@/data/enums/router";
import { DataModules } from "@/store/modules/data/modules";
import type { IState } from "@/store";
import store from "@/store";
import type { IClient } from "@upmind-automation/types";

export default [
  {
    children: [
      {
        label: "_.my_orders",
        to: {
          name: ClientRoutes.ORDERS
        },
        children: [
          {
            label: (vm: any) => {
              return vm.$t("_.order_id", vm.$route.params);
            },
            to: (vm: any) => {
              return {
                name: ClientRoutes.ORDER,
                params: vm.$route.params
              };
            },
            meta: {
              guard: (vm: any) => {
                const lastPath =
                  vm.$route.matched[vm.$route.matched.length - 1].path;
                return lastPath.startsWith("/billing/orders/:contractId");
              }
            }
          },
          {
            if: () => store.getters["brand/showStore"],
            label: "_action.place_new_order",
            to: {
              name: GuestRoutes.STORE
            }
          }
        ]
      },
      {
        label: "_.my_invoices",
        to: {
          name: ClientRoutes.INVOICES
        },
        children: [
          {
            label: "_.paid",
            to: {
              name: ClientRoutes.INVOICES_PAID
            }
          },
          {
            label: "_.unpaid",
            to: {
              name: ClientRoutes.INVOICES_UNPAID
            }
          },
          {
            label: "_.credited",
            to: {
              name: ClientRoutes.INVOICES_CREDITED
            }
          }
        ]
      },
      {
        label: "_.my_legacy_invoices",
        to: {
          name: ClientRoutes.LEGACY_INVOICES
        },
        if: () => {
          const clientId = (store?.state as IState)?.user?.id;
          const client: IClient = store.getters[`data/single`]({
            storeModule: DataModules.CLIENTS,
            scope: store.getters[`data/${DataModules.CLIENTS}/scope`](clientId)
          });
          return !!client?.has_legacy_invoices;
        }
      },
      {
        label: "_.credit_notes",
        to: {
          name: ClientRoutes.CREDIT_NOTES
        }
      },
      {
        label: "_.payment_methods",
        to: { name: ClientRoutes.PAYMENT_METHODS }
      },
      {
        label: "_.account_credit",
        to: { name: ClientRoutes.ACCOUNT_CREDIT }
      },
      {
        label: "_.settings",
        to: { name: ClientRoutes.BILLING_SETTINGS }
      }
    ]
  }
];
