import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import _ from "@/boot/lodash";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/bulk_actions`;
    return { admin };
  },
  scope: () => id => {
    return `$bulkAction_${id}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.BULK_ACTIONS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    if (!_.has(payload, "id"))
      throw new Error("id not provided to fetch results");
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.BULK_ACTIONS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.BULK_ACTIONS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.BULK_ACTIONS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.BULK_ACTIONS
      },
      { root: true }
    );
  },
  reschedule: ({ dispatch, getters }, payload) => {
    if (!_.has(payload, "id")) throw new Error("id not provided to reschedule");
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload?.id}/reschedule`,
        storeModule: DataModules.BULK_ACTIONS,
        requestConfig: { data: payload?.data }
      },
      { root: true }
    );
  },
  retry: async ({ commit, dispatch, getters }, payload) => {
    if (!_.has(payload, "id")) throw new Error("id not provided to retry");
    const bulkAction = await dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${payload?.id}/retry`,
        storeModule: DataModules.BULK_ACTIONS,
        requestConfig: { params: payload.params }
      },
      { root: true }
    );

    if (payload.updateStore) {
      commit(
        `data/updateData`,
        {
          ...payload,
          data: bulkAction,
          key: bulkAction.id,
          storeModule: DataModules.BULK_ACTIONS
        },
        { root: true }
      );
    }
    return bulkAction;
  },
  cancel: async ({ commit, dispatch, getters }, payload) => {
    const bulkAction = await dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${payload.id}/cancel`,
        storeModule: DataModules.BULK_ACTIONS,
        requestConfig: { params: payload.params }
      },
      { root: true }
    );

    if (payload.updateStore) {
      commit(
        `data/updateData`,
        {
          ...payload,
          data: bulkAction,
          key: bulkAction.id,
          storeModule: DataModules.BULK_ACTIONS
        },
        { root: true }
      );
    }
  },
  schedule: async ({ commit, dispatch, getters }, payload) => {
    const bulkAction = await dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${payload.id}/schedule`,
        storeModule: DataModules.BULK_ACTIONS,
        requestConfig: { data: payload.params }
      },
      { root: true }
    );
    if (payload.updateStore) {
      commit(
        `data/updateData`,
        {
          ...payload,
          data: bulkAction,
          key: bulkAction.id,
          storeModule: DataModules.BULK_ACTIONS
        },
        { root: true }
      );
    }
  },
  openAddEditModal: ({ rootGetters, dispatch }, payload) => {
    if (!rootGetters["user/can"]("create_bulk_action")) return;
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          canCancel: ["x", "button"],
          component: () =>
            import(
              "@/components/app/admin/automation/addEditBulkActionModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    results: require("./results").default
  }
};
