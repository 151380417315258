<script lang="ts">
import { defineComponent } from "vue";
import i18n from "@/i18n";
import _ from "@/boot/lodash";
import type { RenderContext } from "vue";

enum CHARS {
  LEFT_BRACKET = "{",
  RIGHT_BRACKET = "}",
  EMPTY = "",
  PIPE = "|"
}

export default defineComponent({
  functional: true,
  props: {
    path: { type: String, required: true },
    count: { type: Number, required: true },
    tag: { type: [String, Boolean], default: null }
  },
  render(h: Function, { data, props, slots }: RenderContext) {
    const { path, count } = props;
    let text = i18n.t(path).toString();
    const parts = text.split(CHARS.PIPE);
    const index = Math.max(0, parts.length > 2 ? count : count - 1);
    text = _.trim(parts?.[index] || _.last(parts));
    const tag =
      (!!props.tag && props.tag !== true) || props.tag === false
        ? props.tag
        : "span";

    if (!text || text === path) return h(tag, {}, path);

    const vueSlots = slots();

    let buff: string[] = [];
    const components: any[] = [];
    for (let index = 0; index < text.length; index++) {
      const char = text[index];
      if (char === CHARS.LEFT_BRACKET) {
        if (buff.length) {
          components.push(h("span", {}, buff.join(CHARS.EMPTY)));
          buff = [];
        }
      } else if (char === CHARS.RIGHT_BRACKET) {
        const slotName = buff.join(CHARS.EMPTY).trim();
        buff = [];
        if (_.has(vueSlots, slotName)) components.push(vueSlots[slotName]);
      } else {
        buff.push(char);
      }
    }

    /**
     * If anything left in the buffer add it as text
     */
    if (buff.length) {
      components.push(h("span", {}, buff.join(CHARS.EMPTY)));
    }

    return tag ? h(tag, data, components) : components;
  }
});
</script>
