<template>
  <clients-provider
    :id="/** [C]lients [L]ist [1] */ `CL1`"
    :available-filters="clientsFilters"
    :available-sorters="clientsSorters"
    :manual-filters="providerManualFilters"
  >
    <template v-slot:default="{ $clientsData, $clientsMethods }">
      <div>
        <div class="has-margin-bottom-50">
          <b-input
            v-focus="$store.getters['ui/isTabletPlus']"
            :value="$clientsData.searchQuery"
            :icon-right="$clientsData.searchQuery ? 'close-circle' : null"
            :placeholder="$t('_placeholder.client_quick_search')"
            :icon-right-clickable="true"
            @icon-right-click="$clientsMethods.doQuickSearch()"
            @input="$clientsMethods.debounceSearch"
          />
        </div>

        <filter-sort-controls
          v-if="$clientsData.enableFiltering || $clientsData.enableSorting"
          :active-filters="$clientsData.activeFilters"
          :enable-filtering="$clientsData.enableFiltering"
          :enable-sorting="$clientsData.enableSorting"
          :pre-defined-filters="$clientsData.constQueryFilters"
          :filters="$clientsData.availableFilters"
          :sorters="$clientsData.availableSorters"
          :table="$clientsData.table"
          class="has-margin-bottom-100"
          @onRefresh="$clientsMethods.reloadData"
          @onFiltersChange="$clientsMethods.onFiltersChange"
          @onSort="$clientsMethods.onSort"
        >
          <!-- Display Mode -->
          <template #control>
            <u-list-display-modes
              v-model="displayMode"
              :storage-path="displayModeStoragePath"
            />
          </template>
        </filter-sort-controls>

        <add-segment-message
          v-if="!$_.isEmpty($clientsData.activeFilters)"
          :active-filters="$clientsData.activeFilters"
          :pre-defined-filters="$clientsData.constQueryFilters"
          :api-props="segmentApiProps"
          :segment-type="segmentType"
        />

        <clients-view-switcher :display-mode="displayMode" />

        <u-pagination
          :current.sync="$clientsData.page"
          :per-page="$clientsData.limit"
          :default-limit="$clientsData.defaults.limit"
          :total="$clientsData.total"
          :visible-total="$clientsData.table.data.length"
          @change-page="$clientsMethods.onPageChange"
          @change-per-page="$clientsMethods.onLimitChange"
        />
      </div>
    </template>
  </clients-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DefaultFilters } from "@/data/filters/client";
import { DefaultSorters } from "@/data/sorters/client";
import { DataModules } from "@/store/modules/data/modules";
import { SegmentTypes } from "@/data/enums/segments";
import { UserMetaKeys } from "@/data/enums/users";
import { ListDisplayMode } from "@/data/constants";
import type { PropType } from "vue";
import type { ISorter, IFilter } from "@/models/tables";

const ClientsListing = defineComponent({
  name: "ClientsListing",
  components: {
    "clients-provider": () =>
      import("@/components/app/admin/clients/clientsProvider.vue"),
    "add-segment-message": () =>
      import("@/components/app/admin/segments/addSegmentMessage.vue"),
    "clients-view-switcher": () =>
      import("@/components/app/admin/clients/clientsViewSwitcher.vue")
  },
  props: {
    availableFilters: {
      type: Function as PropType<(vm: typeof ClientsListing) => IFilter[]>,
      default: DefaultFilters
    },
    availableSorters: {
      type: Function as PropType<(vm: typeof ClientsListing) => ISorter[]>,
      default: DefaultSorters
    },
    manualFilters: {
      type: Function as PropType<
        (vm: typeof ClientsListing) => Record<string, string | number | boolean>
      >,
      default: () => ({})
    }
  },
  data: () => ({
    displayMode: ListDisplayMode.GRID,
    displayModeStoragePath: UserMetaKeys.UI_CLIENTS_DISPLAY_MODE,
    segmentType: SegmentTypes.CLIENT
  }),
  computed: {
    segmentApiProps() {
      return {
        with: this.$store.getters[`data/${DataModules.CLIENTS}/withParam`]
      };
    },
    clientsFilters(): IFilter[] {
      return this.availableFilters(this);
    },
    clientsSorters(): ISorter[] {
      return this.availableSorters(this);
    },
    providerManualFilters() {
      return this.manualFilters(this);
    }
  }
});

export default ClientsListing;
</script>
