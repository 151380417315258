<script lang="ts">
import { defineComponent } from "vue";
import FilteringSortingTableStore from "@/mixins/tableWithFilteringAndSortingStore";
import Context from "@/mixins/context";
import { DataModules } from "@/store/modules/data/modules";
import { EmailTypes } from "@/data/enums/emails";
import emailsFilters from "@/data/filters/emails";
import emailSorters from "@/data/sorters/emails";
import type { PropType, CreateElement } from "vue";
import type {
  IEmailsProviderData,
  IEmailsProviderMethods
} from "@/models/providers/emails";
import type { RecipientTypeCodes } from "@/data/enums/emails";
import type { IFilter, ISorter } from "@/models/tables";
import type { IEmail, ISentEmail } from "@/models/emails";
import type { ITemplate } from "@/models/templates";
import type { IHookLog } from "@/models/hooks";

export default defineComponent({
  name: "EmailsHistoryProvider",
  mixins: [FilteringSortingTableStore, Context],
  provide() {
    return {
      emailHistoryProvider: this.emailHistoryProvider,
      emailHistoryMethods: this.emailHistoryMethods
    };
  },
  props: {
    emailType: { type: String as PropType<EmailTypes>, required: true },
    emailTypeId: { type: String, default: null },
    templateId: { type: String as PropType<ITemplate["id"]>, default: null },
    recipientId: { type: String, default: null },
    recipientTypesFilter: {
      type: Array as PropType<RecipientTypeCodes[]>,
      default: null
    },
    withRecipient: { type: Boolean, default: true },
    handleRequestError: { type: Boolean, default: true },
    id: { type: String, default: "EHT" },
    manualFilters: {
      type: Object as PropType<Record<string, string | number | boolean>>,
      default: () => ({})
    },
    hookLogId: { type: String as PropType<IHookLog["id"]>, default: null }
  },
  data: () => ({
    isProcessing: false,
    isSending: false,
    dataModule: DataModules.EMAILS,
    filters: [] as IFilter[],
    sorters: emailSorters() as ISorter[]
  }),
  computed: {
    contentAvailable() {
      return this.$userCan(this.guardFunctionality);
    },
    canSendEmail() {
      return this.$userCan("send_email_message");
    },
    canResendEmail() {
      return this.$userCan("resend_email_message");
    },
    guardFunctionality() {
      switch (this.emailType) {
        case EmailTypes.ACCOUNT:
          return "list_account_email_history";
        case EmailTypes.BRAND:
          return "list_brand_email_history";
        case EmailTypes.CLIENT:
          return "list_client_email_history";
        case EmailTypes.LEAD:
          return "list_lead_email_history";
        case EmailTypes.MYSELF:
          return "list_self_email_history";
        case EmailTypes.USER:
          return "list_user_email_history";
        default:
          return "";
      }
    },
    emailHistoryMethods(): IEmailsProviderMethods {
      return {
        reloadData: this.reloadData,
        getData: this.getData,
        onPageChange: this.onPageChange,
        onFiltersChange: this.onFiltersChange,
        onLimitChange: this.onLimitChange,
        onSort: this.onSort,
        viewEmail: this.viewEmail,
        sendEmail: this.sendEmail,
        resendEmail: this.resendEmail
      };
    }
  },
  created() {
    if (this.contentAvailable) {
      // Listen on the global bus
      this.$bus.$on("admin/emails/refresh", this.reloadData);
      this.$bus.$on("admin/emails/view", ({ emailId }) =>
        this.viewEmail(emailId)
      );

      this.filters = emailsFilters({
        templateId: this.isClient ? true : this.templateId
      }).filter(filter => (filter.if ? filter?.if() : true));

      this.getData();
    } else {
      this.loading = false;
    }
  },
  methods: {
    getStagedImportFilter() {
      return [EmailTypes.CLIENT, EmailTypes.USER].includes(this.emailType)
        ? { with_staged_imports: 1 }
        : {};
    },
    getTemplateFilter() {
      return this.templateId ? { "filter[template_id]": this.templateId } : {};
    },
    getRecipientFilter() {
      return this.recipientId
        ? { "filter[recipient_id]": this.recipientId }
        : {};
    },
    getRecipientTypeFilter() {
      return this.recipientTypesFilter?.length
        ? { "filter[recipient_type.code]": this.recipientTypesFilter?.join() }
        : {};
    },
    getHookFilter() {
      return this.hookLogId ? { "filter[hook_log_id]": this.hookLogId } : {};
    },
    getStoreParams(params: any): Promise<any> {
      return this.$_.merge(params, {
        params: {
          with: this.withRecipient
            ? "recipient,recipient_type,recipient.image"
            : "",
          ...this.manualFilters,
          emailType: this.emailType,
          emailTypeId: this.emailTypeId,
          ...this.getRecipientFilter(),
          ...this.getRecipientTypeFilter(),
          ...this.getStagedImportFilter(),
          ...this.getTemplateFilter(),
          ...this.getHookFilter()
        }
      });
    },
    reloadData() {
      return this.getData({ ignoreStored: true });
    },
    viewEmail(emailId: IEmail["id"]): void {
      this.$router
        .push({
          name: "adminEmailLog",
          params: {
            emailId
          }
        })
        .catch(err => err);
    },
    async sendEmail(id: ISentEmail["id"]) {
      if (!this.$userCan("send_email_message") || this.isSending) return;
      try {
        this.isSending = true;
        await this.$store.dispatch(`data/${this.dataModule}/send`, { id });
        this.$buefy.toast.open({
          message: this.$t("_sentence.successfully_sent") as string
        });
        await this.reloadData();
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
      } finally {
        this.isSending = false;
      }
    },
    async resendEmail(id: ISentEmail["id"]) {
      if (this.$userCan("resend_email_message")) {
        try {
          this.isSending = true;
          await this.$store.dispatch(`data/${this.dataModule}/resend`, { id });
          this.$buefy.toast.open({
            message: this.$t("_sentence.successfully_sent") as string
          });
          await this.reloadData();
        } catch (error) {
          this.$store.dispatch("api/handleError", error);
        } finally {
          this.isSending = false;
        }
      }
    },
    emailHistoryProvider(): IEmailsProviderData {
      return {
        activeFilters: this.activeFilters,
        canResendEmail: this.canResendEmail,
        canSendEmail: this.canSendEmail,
        contentAvailable: this.contentAvailable,
        emailType: this.emailType,
        emailTypeId: this.emailTypeId,
        enableFiltering: this.enableFiltering,
        enableSorting: this.enableSorting,
        filters: this.filters,
        guardFunctionality: this.guardFunctionality,
        isLoading: this.table.loading,
        isSending: this.isSending,
        limit: this.table.limit,
        page: this.table.page,
        sorters: this.sorters,
        table: this.table,
        total: this.table.total,
        emails: this.table.data as ISentEmail[],
        withRecipient: this.withRecipient
      };
    }
  },
  render(h: CreateElement) {
    // Handle request error
    if (this.handleRequestError && this.requestError)
      return h("request-error", {
        props: { isLoading: this.table.loading && this.requestError },
        on: { click: () => this.reloadData() }
      });
    return (
      this.$scopedSlots?.default &&
      this.$scopedSlots.default({
        $emailsData: this.emailHistoryProvider(),
        $emailsMethods: this.emailHistoryMethods
      })
    );
  }
});
</script>
