import type {
  IContractProduct,
  IContractProductCondition
} from "@/models/contracts";
import { ContractStatusMapped } from "@/models/contracts";

import type { IStatus } from "@/models/statuses";
import BillingCyclesStore from "./constants/billingCyclesStore";
import _ from "@/boot/lodash";
import { PriceType } from "@/data/enums/contractProducts";
import { DataModules } from "@/store/modules/data/modules";

export default {
  mixins: [BillingCyclesStore],
  methods: {
    getProductName(cProd?: IContractProduct) {
      return this.$store.getters[`data/${DataModules.CONTRACTS_PRODUCTS}/name`](
        cProd
      );
    },
    getClientLabel(cProd?: IContractProduct) {
      return cProd?.client_label;
    },
    getCategoryName(cProd?: IContractProduct) {
      return (
        _.get(cProd, "product.category.name_translated") ||
        _.get(cProd, "product.category.name")
      );
    },
    getProductImageSrc(cProd: IContractProduct) {
      const src = _.get(cProd, "product.image.full_url");
      return src ? `${src}?size=100x100` : null;
    },
    getProvConfigName(cProd: IContractProduct) {
      return cProd.provision_configuration?.name;
    },
    getPriceTermSummary(cProd: IContractProduct) {
      if (!cProd.billing_cycle_months)
        return this.$store.getters["utils/money/trimTrailingZeros"](
          this.$store.getters[`brand/taxAwarePrice`]({
            taxIncPrice: cProd.configuration_total_discounted_amount_formatted,
            taxExcPrice: cProd.configuration_net_amount_discounted_formatted
          })
        );
      return [
        this.$store.getters["utils/money/trimTrailingZeros"](
          this.$store.getters[`brand/taxAwarePrice`]({
            taxIncPrice: cProd.configuration_total_recurring_amount_formatted,
            taxExcPrice:
              cProd.configuration_total_recurring_net_amount_formatted
          })
        ),
        this.getBillingCycleName(
          cProd.billing_cycle_months
        ).toLocaleLowerCase(),
        cProd.product?.post_paid
          ? `(${(this.$t("_.estimated") as string).toLowerCase()})`
          : ""
      ]
        .join(" ")
        .trim();
    },
    getCondition(cProd: IContractProduct): IContractProductCondition {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/conditionSummary`
      ](cProd, this.$route);
    },
    getMappedStatus(status: IStatus) {
      return ContractStatusMapped(status as IStatus);
    },
    isConvertedPrice(cProd: IContractProduct) {
      return cProd.price_type === PriceType.CONVERTED;
    },
    isManualPrice(cProd: IContractProduct) {
      return cProd.price_type === PriceType.MANUAL;
    },
    isOnTerminatingTrial(cProd: IContractProduct): boolean {
      return this.$store.getters[
        `data/${DataModules.CONTRACTS_PRODUCTS}/isOnTerminatingTrial`
      ](cProd);
    }
  }
};
