import Vue from "vue";
import type {
  NavigationGuardNext,
  Route,
  NavigationFailure,
  NavigationFailureType
} from "vue-router";
import Router from "vue-router";
import store from "@/store";
import _ from "@/boot/lodash";
import { $bus } from "@/boot/event-bus";
import { Contexts } from "@/models/contexts";
import { ToastProgrammatic as $toast } from "buefy";
import i18n from "@/i18n";
import { QUERY_PARAMS } from "@/data/constants";
import { DataModules } from "@/store/modules/data/modules";
import { ClientRoutes } from "@/data/enums/router";

Vue.use(Router);

const router: Router & { handleFailure?: (error: Error) => void } = new Router({
  mode: "history",
  routes: _.union(
    [
      {
        path: "/sandbox",
        component: () => import("@/views/sandbox/index.vue")
      }
    ],
    require("./order").default,
    require("./admin").default,
    require("./client").default,
    [
      {
        path: "*",
        beforeEnter: async (
          to: Route,
          from: Route,
          next: NavigationGuardNext<Vue>
        ) => {
          // Add admin 404 route
          router.addRoute("adminRoute", {
            path: "*",
            component: () => import("@/views/error/index.vue"),
            meta: { error: 404 }
          });

          // Add client 404 route
          router.addRoute(ClientRoutes.DEFAULT, {
            path: "*",
            component: () => import("@/views/error/index.vue"),
            meta: { error: 404 }
          });

          next(to.fullPath);
        }
      }
    ]
  )
});

function getContext(to: Route): Contexts {
  let context!: Contexts;
  if (to.path.startsWith("/admin")) {
    context = Contexts.ADMIN;
  } else if (
    _.some(to.matched, ({ meta }) => !!meta?.allowGuestContext) &&
    !store.getters["auth/client/isAuthenticated"]
  ) {
    context = Contexts.GUEST;
  } else {
    context = Contexts.CLIENT;
  }
  return context;
}

function checkAutoPayParam(route: Route) {
  if (!route.query[QUERY_PARAMS.AUTO_PAY]) return;

  store.dispatch(`data/${DataModules.PAYMENTS}/openAutoPayModal`, {
    props: {
      invoiceId: route.params.invoiceId,
      clientId: route.params.clientId
    }
  });
}

function checkInitPayParam({ query, name }: Route) {
  if (!query[QUERY_PARAMS.INIT_PAY]) return;
  if (["adminOrderCheckout", "orderCheckoutStep"].includes(name || "")) return;
  store.dispatch(`data/${DataModules.PAYMENTS}/openInitPayModal`, {});
}

router.beforeEach(
  async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    // Catch broken routes (when no matched routes are found)
    if (!to.matched.length && from.matched.length) {
      $toast.open({
        message: i18n.t("_sentence.broken_route_msg") as string,
        type: "is-danger"
      });
      // Prevent routing
      return next(false);
    }
    // Initiate NProgress bar
    const redirect = _.get(router.currentRoute.query, "redirect", "");
    if (to.path !== from.path && to.path !== redirect) {
      store.dispatch("ui/startRouting");
    }
    // Commit new context based on destination
    store.commit("context", getContext(to));

    // Continue routing
    next();
  }
);

function checkRequiredFeatureKeys({ matched }: Route) {
  return store.dispatch("org/guardMissingFeatureKeys", {
    featureKeys: _.flatten(
      _.compact(_.map(matched, ({ meta }) => meta.requiredFeatureKeys))
    ),
    onCancelUpgrade: () => {
      // Force back to previous route
      if (window.history.length > 1) return router.back();
      return router.replace({ name: "adminDashboard" });
    }
  });
}

router.afterEach(async (to: Route) => {
  checkRequiredFeatureKeys(to);
  checkAutoPayParam(to);
  checkInitPayParam(to);
});

router.afterEach((to: Route, from: Route) => {
  const previousRoute = store.getters["ui/previousRoute"];
  if (to.path === _.get(previousRoute, "path")) {
    // Pop route
    store.commit("ui/popPreviousRoute");
  } else if (from.path !== "/" && to.path !== from.path) {
    // Push route
    store.commit("ui/addPreviousRoute", {
      path: from.path,
      fullPath: from.fullPath,
      backTo: _.get(from, "meta.backTo", "_.previous")
    });
  }
});

router.beforeResolve(
  async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    const titles = _.reverse(
      _.map(
        _.filter(to.matched, r => _.get(r, "meta.title")),
        r => _.get(r, "meta.title")
      )
    );
    store.commit("ui/setMetaTitles", titles);
    next();
  }
);

router.onError(() => {
  store.dispatch("ui/endRouting");
});

router.afterEach((to: Route, from: Route) => {
  // End routing
  store.dispatch("ui/endRouting");
  // Emit bus event if route path has changed (used by modals to auto close)
  if (!!from.matched.length && to.path !== from.path)
    $bus.$emit("route-path-changed", { to, from });
});

router.onReady(() => {
  store.dispatch("upm/track", router);
});

_.set(router, "handleFailure", (error: NavigationFailure) => {
  const failureTypes: NavigationFailureType[] = [2, 4, 8, 16];
  // Catch failure and convert to warning
  if (failureTypes.includes(error.type)) {
    // eslint-disable-next-line no-console
    return console.warn(error.message);
  }
  throw error;
});

export default router;
