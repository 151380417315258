import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import type { ApiPathGetter } from "@/models/api";
import type { IUser } from "@/models/users";
import type { IBrand } from "@/models/brands";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/tickets/departments`;
      const client = `api/tickets/departments`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  brandApiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    () => {
      const admin = `api/admin/brand/tickets/departments`;
      const client = `api/brand/tickets/departments`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => departmentId => {
    return `$ticket_department_${departmentId}`;
  },
  listBrandDepartmentsScope: () => (brandId: IBrand["id"]) => {
    return `$brand_${brandId}_departments`;
  },
  listUserDepartmentsScope: () => (userId: IUser[]) => {
    return `$user_${userId}_departments`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().contextual,
        storeModule: DataModules.TICKETS_DEPARTMENTS,
        params: {
          limit: 0,
          with: "brand_ticket_departments",
          ...(payload.params || {})
        }
      },
      { root: true }
    );
  },
  listBrandDepartments: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.brandApiPath().contextual,
        storeModule: DataModules.TICKETS_DEPARTMENTS,
        params: {
          limit: 0,
          with: "department",
          ...(payload.params || {})
        }
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().contextual}/${payload.id}`,
        storeModule: DataModules.TICKETS_DEPARTMENTS,
        params: {
          ...(payload.params || {}),
          with: "brand_ticket_departments"
        }
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.TICKETS_DEPARTMENTS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.TICKETS_DEPARTMENTS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.TICKETS_DEPARTMENTS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
