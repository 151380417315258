import { FilterOperators, FilterTypes } from "@/data/table";
import i18n from "@/i18n";
import { UpmindObjectMap } from "@/data/enums/objects";
import _ from "@/boot/lodash";
import { CreatedAtFilter } from "@/data/filters/object";

export const NameFilter = () => ({
  key: "name",
  label: i18n.t("_.name"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS,
  group: "department"
});

export const ReferenceFilter = () => ({
  key: "reference",
  label: i18n.t("_.reference"),
  type: FilterTypes.STRING,
  operator: FilterOperators.EQUAL
});

export const SubjectFilter = () => ({
  key: "subject",
  label: i18n.t("_.subject"),
  type: FilterTypes.STRING,
  operator: FilterOperators.EQUAL
});

export const CodeFilter = () => ({
  key: "code",
  label: i18n.t("_.code"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS,
  group: "department"
});

export const ObjectTypeFilter = () => ({
  key: "object_type",
  label: i18n.t("_.object_type"),
  type: FilterTypes.SELECT,
  operator: FilterOperators.EQUAL,
  options: _.map(UpmindObjectMap(), object => ({
    value: object.key,
    label: object.name
  }))
});

export const ClientParam = vm => ({
  [`filter[client_id]`]: vm.clientId
});

export const LeadParam = vm => ({
  [`filter[lead_id]`]: vm.leadId
});

export const UserParam = vm => ({
  [`filter[user_id]`]: vm.userId
});

export const IsActiveParam = () => ({
  [`filter[status.code|neq]`]: "ticket_closed"
});

export const IsClosedParam = () => ({
  [`filter[status.code]`]: "ticket_closed"
});

export const DefaultFilters = () => [
  ReferenceFilter(),
  SubjectFilter(),
  CreatedAtFilter()
];

export const DefaultStatusesFilters = () => [CodeFilter(), ObjectTypeFilter()];

export const DefaultPrioritiesFilters = () => [CodeFilter()];

export const DefaultDepartmentsFilters = () => [
  NameFilter(),
  CodeFilter(),
  CreatedAtFilter()
];

export const DefaultClientDepartmentsFilters = () => [
  NameFilter(),
  CodeFilter(),
  CreatedAtFilter({ group: "client" })
];
