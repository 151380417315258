import { mapState } from "vuex";
import _ from "@/boot/lodash";
import RequestPromises from "@/mixins/requestPromises";
import { DataModules } from "@/store/modules/data/modules";
import { FilterOperators, FilterTypes } from "@/data/table";
import _ from "@/boot/lodash";
import {
  CustomFieldsFilterTypes,
  CustomFieldsTypes,
  CustomFieldsMajorTypes
} from "@/data/enums/customFields";
import type { ICustomField } from "@/models/brands";
import type { IState } from "@/store";
import type { IFilter } from "@/models/tables";

export default {
  mixins: [RequestPromises],
  data: () => ({
    params: {
      limit: 0,
      order: "order"
    }
  }),
  computed: {
    ...mapState({
      customBasketFieldsScope(state: IState, getters): string {
        return getters[`data/${DataModules.CUSTOM_FIELDS}/listScope`](
          CustomFieldsMajorTypes.INVOICE
        );
      },
      customCancellationFieldsScope(state: IState, getters): string {
        return getters[`data/${DataModules.CUSTOM_FIELDS}/listScope`](
          CustomFieldsMajorTypes.CANCEL_REQUEST
        );
      },
      customClientFieldsScope(state: IState, getters): string {
        return getters[`data/${DataModules.CUSTOM_FIELDS}/listScope`](
          CustomFieldsMajorTypes.CLIENT
        );
      },
      customBasketFields(state: IState, getters): ICustomField[] {
        return getters[`data/listArray`]({
          scope: this.customBasketFieldsScope,
          storeModule: DataModules.CUSTOM_FIELDS
        });
      },
      customCancellationFields(state: IState, getters): ICustomField[] {
        return getters[`data/listArray`]({
          scope: this.customCancellationFieldsScope,
          storeModule: DataModules.CUSTOM_FIELDS
        });
      },
      customClientFields(state: IState, getters): ICustomField[] {
        return getters[`data/listArray`]({
          scope: this.customClientFieldsScope,
          storeModule: DataModules.CUSTOM_FIELDS
        });
      }
    })
  },
  methods: {
    getCustomClientFields(ignoreStored = false, withStagedImports = false) {
      return this.$store.dispatch(`data/${DataModules.CUSTOM_FIELDS}/list`, {
        customFieldsType: CustomFieldsMajorTypes.CLIENT,
        scope: this.customClientFieldsScope,
        params: {
          ...(withStagedImports ? { with_staged_imports: 1 } : {}),
          ...this.params
        },
        ignoreStored,
        vm: this
      });
    },
    getCustomBasketFields(ignoreStored) {
      return this.$store.dispatch(`data/${DataModules.CUSTOM_FIELDS}/list`, {
        customFieldsType: CustomFieldsMajorTypes.INVOICE,
        scope: this.customBasketFieldsScope,
        params: this.params,
        ignoreStored,
        vm: this
      });
    },
    getCustomCancellationFields(ignoreStored = false) {
      return this.$store.dispatch(`data/${DataModules.CUSTOM_FIELDS}/list`, {
        customFieldsType: CustomFieldsMajorTypes.CANCEL_REQUEST,
        scope: this.customCancellationFieldsScope,
        params: this.params,
        ignoreStored,
        vm: this
      });
    },
    customFieldsFilters(
      type: string,
      prefix: string,
      group: string,
      ignore = false
    ): IFilter[] {
      return _.map(
        _.filter(
          type === CustomFieldsMajorTypes.CLIENT
            ? this.customClientFields
            : type === CustomFieldsMajorTypes.BASKET
            ? this.customBasketFields
            : this.customCancellationFields,
          filter => filter.type !== CustomFieldsTypes.IMAGE
        ),
        customField =>
          this.createCustomField(customField, prefix, group, type, ignore)
      );
    },
    createCustomField(customField, prefix, group, type, ignore) {
      return {
        key: this.getCustomFieldFilterKey(customField, prefix),
        label: this.getLabel(type, customField.name_translated, ignore),
        operator: FilterOperators.EQUAL,
        type: CustomFieldsFilterTypes[customField.type],
        options:
          CustomFieldsFilterTypes[customField.type] !== FilterTypes.NUMBER // Hot fix for range field which is not used on custom fields
            ? customField.values || []
            : [],
        nameTranslated: customField.name_translated,
        ...(CustomFieldsFilterTypes[customField.type] ===
        FilterTypes.SELECT_RADIO
          ? { value: "1" }
          : {}),
        group: group
      };
    },
    getLabel(type, label, ignore) {
      if (ignore) {
        return label;
      }

      if (type === CustomFieldsMajorTypes.BASKET) {
        return this.$t("_.order_field", { field: label });
      }

      if (type === CustomFieldsMajorTypes.CANCEL_REQUEST) {
        return this.$t("_.cancellation_field", { field: label });
      }

      return this.$t("_.client_field", { field: label });
    },
    getCustomFieldFilterKey(customField: ICustomField, prefix: string) {
      return `${prefix ? prefix + "." : ""}custom_fields.${customField.code}`;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("data/binList", {
      scope: this.customClientFieldsScope,
      storeModule: DataModules.CUSTOM_FIELDS,
      vm: this
    });

    this.$store.dispatch("data/binList", {
      scope: this.customBasketFieldsScope,
      storeModule: DataModules.CUSTOM_FIELDS,
      vm: this
    });
  }
};
