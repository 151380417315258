import _ from "@/boot/lodash";
import type { IFilter } from "@/models/tables";
import { FilterOperators, FilterTypes, ApiOperators } from "@/data/table";
import {
  ContractStatusCodes as CSC,
  ContractStatusMap
} from "@/models/contracts";
import i18n from "@/i18n";
import store from "@/store";
import { DataModules } from "@/store/modules/data/modules";
import type { IProduct } from "@/models/products";
import type { IBlueprint } from "@/models/provisioning";
import type { IProvisionConfiguration } from "@upmind-automation/types";

// Params

export const IsActiveParam = () => ({
  "filter[status.code]": [
    CSC.ACTIVE,
    CSC.AWAITING_ACTIVATION,
    CSC.PENDING,
    CSC.SUSPENDED
  ].join()
});

export const statusParam = (statuses: CSC[]) => ({
  "filter[status.code]": statuses.join()
});

export const isFromLast30DaysParam = () => ({
  [`filter[created_at|${ApiOperators.AFTER}]`]: "-1_months"
});

export const hasUnresolvedRequestsParam = () => ({
  [`filter[unresolved_provision_requests.id|${ApiOperators.NOT_EQUAL}]`]: "null"
});

export const IsInactiveParam = () => ({
  "filter[status.code]": [CSC.CANCELLED, CSC.CLOSED].join()
});

export const IsSubscriptionParam = () => ({
  [`filter[billing_cycle_months|${ApiOperators.NOT_EQUAL}]`]: 0
});

export const IsOneTimePurchaseParam = () => ({
  "filter[billing_cycle_months]": 0
});

export const IsCategoryParam = vm => ({
  "filter[product.category.id]": vm?.$route?.params?.categoryId
});

export const ClientsIdParam = ({ clientId }) => ({
  "filter[clients.id]": clientId
});

export const CatalogueProductIdParam = (productId: IProduct["id"]) => ({
  "filter[product.id]": productId
});

// Filters

export const ProductNameFilter: () => IFilter = () => ({
  key: "product.name",
  label: i18n.t("_.product_name"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS
});

export const StatusCodesFilter: (statusCodes?: CSC[]) => IFilter = (
  statusCodes = _.values(CSC)
) => ({
  key: "status.code",
  label: i18n.t("_.status"),
  type: FilterTypes.SELECT_CHECKBOX,
  options: _.map(
    _.pick(ContractStatusMap(), statusCodes),
    ({ name }, value) => ({
      label: name,
      value
    })
  ),
  operator: FilterOperators.EQUAL
});

export const ServiceIdentifierFilter: () => IFilter = () => ({
  key: "service_identifier",
  label: i18n.t("_.service_identifier"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS,
  group: "subscription",
  if: () => store.getters.isAdminContext
});

export const ImportIdFilter: () => IFilter = () => ({
  key: "import_id",
  label: i18n.t("_.import_id"),
  type: FilterTypes.STRING,
  operator: FilterOperators.EQUAL,
  group: "subscription",
  if: () => store.getters.isAdminContext
});

export const ProvisionBluePrintId: () => IFilter = () => {
  const getOptions = async () => {
    const blueprints = await store.dispatch(
      `data/${DataModules.PROVISIONING_BLUEPRINTS}/list`,
      {
        storeData: false,
        params: {}
      }
    );

    return blueprints?.map((i: IBlueprint) => ({
      value: i.id,
      label: i.name
    }));
  };

  return {
    key: "product.provision_blueprint_id",
    label: i18n.t("_.provision_blueprint"),
    type: FilterTypes.SELECT_CHECKBOX,
    operator: FilterOperators.EQUAL,
    group: "subscription",
    value: [],
    optionsFunc: getOptions,
    if: () => store.getters.isAdminContext
  } as IFilter;
};

export const ProductCategoryNameFilter: () => IFilter = () => ({
  key: "product.category.name",
  label: i18n.t("_.product_category"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS,
  if: () => store.getters.isClientContext
});

export const ProductCategoryFilter: () => IFilter = () => {
  const getOptions = async () => {
    const categories = await store.dispatch(
      `data/${DataModules.CATALOGUE_CATEGORIES}/list`,
      {
        storeData: false,
        params: {
          order: "order",
          limit: 0,
          "filter[level]": 1,
          brandId: store.getters["brand/id"]
        }
      }
    );

    return categories?.map(category => ({
      id: category.id,
      label: category.name
    }));
  };

  const filter: IFilter = {
    key: "product.category.id",
    label: i18n.t("_.product_category"),
    type: FilterTypes.SELECT_MULTI,
    value: [],
    operator: FilterOperators.EQUAL,
    optionsFunc: getOptions,
    if: () => store.getters.isAdminContext
  };

  return filter;
};

export const BrandFilter: () => IFilter = () => {
  const getOptions = () =>
    store.state?.auth?.admin?.brands?.map(brand => ({
      id: brand.id,
      label: brand.name
    }));

  const filter: IFilter = {
    key: "product.brand_id",
    label: i18n.t("_.brand"),
    type: FilterTypes.SELECT_MULTI,
    value: [],
    operator: FilterOperators.EQUAL,
    optionsFunc: getOptions,
    if: () => store.getters["brand/isMultibrand"]
  };

  return filter;
};

export const ActiveStatusCodesFilter = () =>
  StatusCodesFilter([
    CSC.ACTIVE,
    CSC.AWAITING_ACTIVATION,
    CSC.PENDING,
    CSC.SUSPENDED
  ]);

export const AllStatusCodesFilter = () =>
  StatusCodesFilter([
    CSC.ACTIVE,
    CSC.AWAITING_ACTIVATION,
    CSC.PENDING,
    CSC.SUSPENDED,
    CSC.CANCELLED,
    CSC.CLOSED,
    CSC.FRAUD
  ]);

export const ProvisionProviderId: (
  filters?: Record<string, string>
) => IFilter = filters => {
  const getOptions = async () => {
    const configurations = await store.dispatch(
      `data/${DataModules.PROVISIONING_CATEGORIES}/list`,
      {
        storeData: false,
        params: {
          limit: 0,
          with: "providers",
          ...(typeof filters === "object" ? filters : {})
        }
      }
    );

    return configurations?.map(i => ({
      value: i.id,
      label: i.name,
      name: i.name,
      options: i.providers.map(provider => ({
        value: provider.id,
        label: provider.name
      }))
    }));
  };

  const filter: IFilter = {
    key: "provision_provider_id",
    label: i18n.t("_.provision_provider"),
    type: FilterTypes.SELECT,
    operator: FilterOperators.EQUAL,
    optionsFunc: getOptions,
    group: "subscription",
    if: () => store.getters.isAdminContext
  };
  return filter;
};

export const ProvisionConfigurationId: (
  filters?: Record<string, string>
) => IFilter = filters => {
  const getOptions = async () => {
    const configurations = await store.dispatch(
      `data/${DataModules.PROVISIONING_CONFIGURATIONS}/list`,
      {
        storeData: false,
        params: {
          limit: 0,
          ...(typeof filters === "object" ? filters : {})
        }
      }
    );

    return configurations?.map((i: IProvisionConfiguration) => ({
      id: i.id,
      label: i.name
    }));
  };

  const filter: IFilter = {
    key: "provision_configuration_id",
    label: i18n.t("_.provision_configuration"),
    type: FilterTypes.SELECT_MULTI,
    operator: FilterOperators.EQUAL,
    group: "subscription",
    value: [],
    optionsFunc: getOptions,
    if: () => store.getters.isAdminContext
  };
  return filter;
};

export const CancelledStatusCodesFilter = () =>
  StatusCodesFilter([CSC.CLOSED, CSC.CANCELLED]);

export const CurrencyCodeFilter: () => IFilter = () => ({
  key: "currency_base.code",
  label: i18n.t("_.currency_code"),
  type: FilterTypes.STRING,
  operator: FilterOperators.EQUAL
});

export const PriceFilter: () => IFilter = () => ({
  key: "total_amount",
  label: i18n.t("_.price"),
  type: FilterTypes.NUMBER,
  operator: FilterOperators.EQUAL
});

export const DatePurchasedFilter: () => IFilter = () => ({
  key: "created_at",
  label: i18n.t("_.date_purchased"),
  type: FilterTypes.DATE,
  operator: FilterOperators.AFTER
});

export const NextDueDateFilter: () => IFilter = () => ({
  key: "next_due_date",
  label: i18n.t("_.next_due_date"),
  type: FilterTypes.DATE,
  operator: FilterOperators.AFTER
});

export const CProdFilters: (args?: any) => IFilter[] = ({
  statuses = [] as CSC[]
} = {}) => [
  {
    key: "product.name",
    label: i18n.t("_.product_name"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "subscription"
  },
  {
    key: "product.category.id",
    label: i18n.t("_.product_category"),
    type: FilterTypes.SELECT_MULTI,
    operator: FilterOperators.EQUAL,
    group: "subscription",
    value: [],
    if: context => context.isAdmin
  },
  {
    key: "product.category.name",
    label: i18n.t("_.product_category"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "subscription",
    if: context => context.isClient
  },
  {
    key: "status.code",
    label: i18n.t("_.status"),
    type: FilterTypes.SELECT_CHECKBOX,
    options: [
      {
        value: CSC.ACTIVE,
        label: i18n.t("_.active")
      },
      {
        value: CSC.AWAITING_ACTIVATION,
        label: i18n.t("_.subscription_awaiting_activation_summary")
      },
      {
        value: CSC.PENDING,
        label: i18n.t("_.subscription_pending_summary")
      },
      {
        value: CSC.SUSPENDED,
        label: i18n.t("_.suspended")
      },
      {
        value: CSC.CANCELLED,
        label: i18n.t("_.cancelled")
      },
      { value: CSC.CLOSED, label: i18n.t("_.lapsed") }
    ].filter(option => {
      if (!statuses.length) return true;
      if (Array.isArray(option.value))
        return _.intersection(option.value, statuses).length;
      return statuses.includes(option.value);
    }),
    operator: FilterOperators.EQUAL,
    group: "subscription",
    if: vm => !vm.isOneTimePurchase
  },
  {
    key: "status.code",
    label: i18n.t("_.status"),
    type: FilterTypes.SELECT_CHECKBOX,
    options: [
      {
        value: CSC.ACTIVE,
        label: i18n.t("_.fulfilled")
      },
      {
        value: CSC.AWAITING_ACTIVATION,
        label: i18n.t("_.awaiting_activation")
      },
      {
        value: CSC.PENDING,
        label: i18n.t("_.awaiting_payment")
      },
      {
        value: CSC.SUSPENDED,
        label: i18n.t("_.suspended")
      },
      {
        value: CSC.CANCELLED,
        label: i18n.t("_.cancelled")
      },
      { value: CSC.CLOSED, label: i18n.t("_.lapsed") }
    ].filter(option => {
      if (!statuses.length) return true;
      if (Array.isArray(option.value))
        return _.intersection(option.value, statuses).length;
      return statuses.includes(option.value);
    }),
    operator: FilterOperators.EQUAL,
    group: "purchase",
    if: vm => vm.isOneTimePurchase
  },
  {
    key: "total_amount",
    label: i18n.t("_.price"),
    type: FilterTypes.NUMBER,
    operator: FilterOperators.EQUAL,
    group: "subscription"
  },
  {
    key: "next_due_date",
    label: i18n.t("_.next_due_date"),
    type: FilterTypes.DATE,
    operator: FilterOperators.AFTER,
    group: "subscription",
    if: vm => !vm.isOneTimePurchase
  },
  {
    key: "created_at",
    label: i18n.t("_.date_purchased"),
    type: FilterTypes.DATE,
    operator: FilterOperators.AFTER,
    group: "subscription"
  },
  {
    key: "service_identifier",
    label: i18n.t("_.service_identifier"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "subscription"
  },
  {
    key: "import_id",
    label: i18n.t("_.import_id"),
    type: FilterTypes.STRING,
    operator: FilterOperators.EQUAL,
    group: "subscription",
    if: context => context.isAdmin
  }
];

export const CProdClientsFilters: () => IFilter[] = () => [
  {
    key: "clients.username",
    label: i18n.t("_.client_username"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "client"
  },
  {
    key: "clients.has_login",
    label: i18n.t("_.client_has_login"),
    type: FilterTypes.SELECT_RADIO,
    operator: FilterOperators.EQUAL,
    value: "1",
    group: "client"
  },
  {
    key: "clients.firstname",
    label: i18n.t("_.client_firstname"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "client"
  },
  {
    key: "clients.lastname",
    label: i18n.t("_.client_lastname"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "client"
  },
  {
    key: "clients.created_at",
    label: i18n.t("_.client_created"),
    type: FilterTypes.DATE,
    operator: FilterOperators.AFTER,
    group: "client"
  },
  {
    key: "clients.id",
    label: i18n.t("_.client_id"),
    type: FilterTypes.STRING,
    operator: FilterOperators.EQUAL,
    group: "client"
  },
  {
    key: "clients.verified",
    label: i18n.t("_.client_verified"),
    type: FilterTypes.SELECT_RADIO,
    operator: FilterOperators.EQUAL,
    value: "1",
    group: "client"
  }
];

export const CProdProvisionFilters: () => IFilter[] = () => [
  {
    key: "product.provision_blueprint_id",
    label: i18n.t("_.provision_blueprint"),
    type: FilterTypes.SELECT_CHECKBOX,
    operator: FilterOperators.EQUAL,
    group: "subscription",
    value: []
  },
  {
    key: "provision_provider_id",
    label: i18n.t("_.provision_provider"),
    type: FilterTypes.SELECT,
    operator: FilterOperators.EQUAL,
    group: "subscription"
  },
  {
    key: "provision_configuration_id",
    label: i18n.t("_.provision_configuration"),
    type: FilterTypes.SELECT_CHECKBOX,
    operator: FilterOperators.EQUAL,
    group: "subscription",
    value: []
  }
];

export const DefaultCProdFilters: () => IFilter[] = () => {
  return [
    BrandFilter(),
    DatePurchasedFilter(),
    NextDueDateFilter(),
    CurrencyCodeFilter(),
    PriceFilter(),
    ProductNameFilter(),
    ProductCategoryFilter(),
    ProductCategoryNameFilter(),
    AllStatusCodesFilter(),
    ServiceIdentifierFilter(),
    ImportIdFilter(),
    ProvisionBluePrintId(),
    ProvisionProviderId(),
    ProvisionConfigurationId()
  ];
};

export const CProdFiltersWithoutCategory: () => IFilter[] = () => {
  return [
    DatePurchasedFilter(),
    NextDueDateFilter(),
    CurrencyCodeFilter(),
    PriceFilter(),
    ProductNameFilter(),
    ServiceIdentifierFilter(),
    ImportIdFilter(),
    ProvisionBluePrintId(),
    ProvisionProviderId(),
    ProvisionConfigurationId()
  ];
};

export const RetentionsFilters: () => IFilter[] = () => {
  return [
    BrandFilter(),
    DatePurchasedFilter(),
    NextDueDateFilter(),
    CurrencyCodeFilter(),
    PriceFilter(),
    ProductNameFilter(),
    ProductCategoryFilter(),
    ServiceIdentifierFilter(),
    ImportIdFilter()
  ];
};

export const CatalogueProductCProdFilters: () => IFilter[] = () => {
  return [
    DatePurchasedFilter(),
    NextDueDateFilter(),
    CurrencyCodeFilter(),
    PriceFilter(),
    ServiceIdentifierFilter(),
    ImportIdFilter()
  ];
};
