<template>
  <segment-results-provider
    :id="id"
    ref="segmentResultsProvider"
    :segment-id="segmentId"
    :segment-type-hint="segmentTypeHint"
    :handle-loading="false"
    :manual-filters="segmentsManualFilters"
    :available-filters="segmentAvailableFilters"
    :available-sorters="segmentAvailableSorters"
    :limit="10"
  >
    <segment-results-config>
      <template
        v-slot:default="{ $segmentResultsData, $segmentResultsMethods, config }"
      >
        <div class="has-margin-bottom-50">
          <div class="field is-grouped">
            <!-- Quick search -->
            <div class="control is-expanded">
              <b-input
                v-focus="$store.getters['ui/isTabletPlus']"
                :value="$segmentResultsData.searchQuery"
                :placeholder="config.i18n.quickSearchPlaceholder"
                :icon-right="
                  $segmentResultsData.searchQuery ? 'close-circle' : null
                "
                :icon-right-clickable="true"
                @icon-right-click="$segmentResultsMethods.doQuickSearch()"
                @input="$segmentResultsData.debounceSearch"
              />
            </div>
          </div>
          <filter-sort-controls
            v-if="
              $segmentResultsData.enableFiltering ||
              $segmentResultsData.enableSorting
            "
            :pre-defined-filters="
              $segmentResultsMethods.getConstSegmentFilters(
                $segmentResultsData.availableFilters
              )
            "
            :active-filters="$segmentResultsData.activeFilters"
            :enable-filtering="$segmentResultsData.enableFiltering"
            :enable-sorting="$segmentResultsData.enableSorting"
            :filters="$segmentResultsData.availableFilters"
            :sorters="$segmentResultsData.availableSorters"
            :table="$segmentResultsData.table"
            class="has-margin-bottom-100"
            @onRefresh="$segmentResultsMethods.reloadData"
            @onFiltersChange="$segmentResultsMethods.onFiltersChange"
            @onSort="$segmentResultsMethods.onSort"
          >
            <!-- Display Mode -->
            <template v-if="displayMode" #control>
              <u-list-display-modes
                v-model="displayMode"
                :modes="displayModes"
                :storage-path="displayModeStoragePath"
              />
            </template>
          </filter-sort-controls>
          <add-segment-message
            v-if="
              allowCreateSegment &&
              !$_.isEmpty($segmentResultsData.activeFilters)
            "
            :active-filters="$segmentResultsData.activeFilters"
            :pre-defined-filters="
              $segmentResultsMethods.getConstSegmentFilters(
                $segmentResultsData.availableFilters
              )
            "
            :api-props="{}"
            :segment-type="$segmentResultsData.segmentType"
            :new-segment-redirect-route="
              newSegmentRouteName || $segmentResultsData.newSegmentRouteName
            "
          />
          <template v-if="$segmentResultsData.isContractsProductsSegment">
            <!-- Map to CProds Provider -->
            <segment-results-to-cprods-provider-mapping>
              <cprods-grid-view
                v-if="isDisplayModeGrid"
                :row-events="rowEvents"
                :redirect="redirect"
              />
              <cprods-table-view
                v-else-if="isDisplayModeTable"
                :row-events="rowEvents"
                :redirect="redirect"
              />
            </segment-results-to-cprods-provider-mapping>
          </template>
          <template v-else-if="$segmentResultsData.isClientsSegment">
            <!-- Map to Clients provider -->
            <segment-results-to-clients-provider-mapping>
              <clients-view-switcher
                :display-mode="displayMode"
                :show-controls="showControls"
                :disable-linking="disableLinking"
                :rowEvents="rowEvents"
              />
            </segment-results-to-clients-provider-mapping>
          </template>
          <template v-else-if="$segmentResultsData.isOrdersSegment">
            <!-- Map to Orders provider -->
            <segment-results-to-orders-provider-mapping>
              <orders-table-view />
            </segment-results-to-orders-provider-mapping>
          </template>
          <u-pagination
            :current.sync="$segmentResultsData.page"
            :per-page="$segmentResultsData.limit"
            :total="$segmentResultsData.total"
            :default-limit="$segmentResultsData.defaults.limit"
            :visible-total="$segmentResultsData.table.data.length"
            @change-page="$segmentResultsMethods.onPageChange"
            @change-per-page="$segmentResultsMethods.onLimitChange"
          />
        </div>
      </template>
    </segment-results-config>
  </segment-results-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ListDisplayMode } from "@/data/constants";
import SegmentResultsProvider from "@/components/app/admin/segments/segmentResultsProvider.vue";
import type { PropType } from "vue";
import type { IFilter, ISorter } from "@/models/tables";
import type { ISegment } from "@/models/segments";
import type { UserMetaKeys } from "@/data/enums/users";
import type { IContractProduct } from "@/models/contracts";

const SegmentResultsListing = defineComponent({
  name: "SegmentResultsListing",
  components: {
    SegmentResultsProvider,
    "segment-results-config": () =>
      import("@/components/app/admin/segments/segmentResultsConfig.vue"),
    "cprods-grid-view": () =>
      import("@/components/app/global/contractProducts/cProdsGridView.vue"),
    "cprods-table-view": () =>
      import("@/components/app/global/contractProducts/cProdsTableView.vue"),
    "segment-results-to-cprods-provider-mapping": () =>
      import(
        "@/components/app/admin/segments/segmentResultsToCProdsProviderMapping.vue"
      ),
    "segment-results-to-clients-provider-mapping": () =>
      import(
        "@/components/app/admin/segments/segmentResultsToClientsProviderMapping.vue"
      ),
    "segment-results-to-orders-provider-mapping": () =>
      import(
        "@/components/app/admin/segments/segmentResultsToOrdersProviderMapping.vue"
      ),
    "add-segment-message": () =>
      import("@/components/app/admin/segments/addSegmentMessage.vue"),
    "orders-table-view": () =>
      import("@/components/app/global/orders/ordersTableView.vue"),
    "clients-view-switcher": () =>
      import("@/components/app/admin/clients/clientsViewSwitcher.vue")
  },
  props: {
    id: { type: String, default: "SRP" },
    segmentId: { type: String as PropType<ISegment["id"]>, required: true },
    segmentTypeHint: {
      type: String as PropType<ISegment["type"]>,
      default: null
    },
    routeFilters: {
      type: Function as PropType<
        (
          vm?: typeof SegmentResultsListing
        ) => Record<string, string | number | boolean>
      >,
      default: () => {}
    },
    parentFilters: {
      type: Object as PropType<Record<string, string | number | boolean>>,
      default: () => ({})
    },
    availableFilters: {
      type: Function as PropType<
        (vm?: typeof SegmentResultsListing) => IFilter[]
      >,
      default: () => {}
    },
    availableSorters: {
      type: Function as PropType<
        (vm?: typeof SegmentResultsListing) => ISorter[]
      >,
      default: () => {}
    },
    newSegmentRouteName: { type: String, default: null },
    allowCreateSegment: { type: Boolean, default: true },
    defaultDisplayMode: {
      type: String as PropType<ListDisplayMode>,
      required: true
    },
    displayModes: {
      type: Array as PropType<ListDisplayMode[]>,
      default: () => undefined
    },
    displayModeStoragePath: {
      type: String as PropType<UserMetaKeys>,
      default: ""
    },
    showControls: {
      type: Boolean,
      default: true
    },
    disableLinking: { type: Boolean, default: false },
    rowEvents: {
      type: Function as PropType<
        (contractProduct: IContractProduct) => {
          [key: string]: Function;
        }
      >,
      default: () => {}
    },
    redirect: { type: Boolean, default: true },
    rowTag: { type: String, default: "u-link" },
    to: { type: Object as PropType<any>, default: null }
  },
  data: () => ({
    displayMode: "",
    segmentAvailableFilters: [] as IFilter[]
  }),
  computed: {
    isDisplayModeTable() {
      return this.displayMode === ListDisplayMode.TABLE;
    },
    isDisplayModeGrid() {
      return this.displayMode === ListDisplayMode.GRID;
    },
    segmentsManualFilters() {
      return {
        ...this.parentFilters,
        ...this.routeFilters(this)
      };
    },
    segmentAvailableSorters() {
      return this.availableSorters(this);
    }
  },
  watch: {
    parentFilters: { handler: "onParentFiltersChange", deep: true }
  },
  created() {
    this.displayMode = this.defaultDisplayMode || "";
    this.segmentAvailableFilters = this.availableFilters(this);
  },
  methods: {
    onParentFiltersChange() {
      this.$refs.segmentResultsProvider.reloadData();
    }
  }
});

export default SegmentResultsListing;
</script>

<style lang="scss" scoped>
.cprod-wells {
  display: grid;
  gap: 0.75rem;
  grid-template-columns: 1fr;
  @include tablet {
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  }
}
::v-deep .cprod-wells .b-skeleton {
  margin-top: 0;
  opacity: 0.75;
  .b-skeleton-item {
    height: 8.125rem;
  }
}

.u-ripple-table-empty {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 22rem;
  box-shadow: 0 4px 16px 0 rgba($black, 0.1);
  & ~ .b-skeleton {
    opacity: 0.4;
    border-top-color: $light !important;
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 360ms ease-out;
}
.slide-down-leave-active {
  transition-duration: 0s;
}
.slide-down-enter,
.slide-down-leave-to {
  margin-top: -1rem;
  opacity: 0;
}
</style>
