<script lang="ts">
import { defineComponent } from "vue";
import _ from "@/boot/lodash";
import Context from "@/mixins/context";
import ExternalAuthMixin from "@/components/app/global/payments/externalAuthMixin.vue";
import RequestPromises from "@/mixins/requestPromises";
import { DataModules } from "@/store/modules/data/modules";
import { PaymentMethodType } from "@/models/selectPaymentMethod";
import { QUERY_PARAMS, TransactionStatus } from "@/data/constants";

import { SnackbarProgrammatic as $snackbar } from "buefy";
import type { Location as VueLocation } from "vue-router";
import type { IClient } from "@upmind-automation/types";
import type { IAutoPay, IExternalAuthRedirect } from "@/models/externalAuth";
import type {
  GatewayCardData,
  GatewayData,
  GatewayExternalCardData,
  GatewayMobileData,
  ManualPaymentData,
  SelectedPaymentMethod,
  StoredCardData
} from "@/models/selectPaymentMethod";

export default defineComponent({
  name: "PaymentProvider",
  mixins: [Context, ExternalAuthMixin, RequestPromises],
  data: () => ({
    paymentMethod: null as SelectedPaymentMethod | null,
    paymentData: {} as any,
    externalAuth: undefined as IExternalAuthRedirect
  }),
  computed: {
    paymentMethodType(): PaymentMethodType | undefined {
      return this.paymentMethod?.type;
    },
    isStoredCard() {
      return this.paymentMethodType === PaymentMethodType.STORED_CARD;
    },
    isGatewayCard() {
      return this.paymentMethodType === PaymentMethodType.GATEWAY_CARD;
    },
    isGatewayBankTransfer() {
      return this.paymentMethodType === PaymentMethodType.GATEWAY_BANK_TRANSFER;
    },
    isGatewayDirectDebit() {
      return this.paymentMethodType === PaymentMethodType.GATEWAY_DIRECT_DEBIT;
    },
    isGatewaySepa() {
      return this.paymentMethodType === PaymentMethodType.GATEWAY_SEPA;
    },
    isGatewayMobile() {
      return this.paymentMethodType === PaymentMethodType.GATEWAY_MOBILE;
    },
    isGatewayOffline() {
      return this.paymentMethodType === PaymentMethodType.GATEWAY_OFFLINE;
    },
    isPayLater() {
      return this.paymentMethodType === PaymentMethodType.PAY_LATER;
    },
    isManualPayment() {
      return this.paymentMethodType === PaymentMethodType.MANUAL_PAYMENT;
    },
    isExternalStore() {
      return this.paymentMethodType === PaymentMethodType.EXTERNAL_STORE;
    },
    isExternal() {
      return !!this.$_.get(this.paymentMethod, "data.external");
    },
    shouldBeStored() {
      if (this.isGuest) return false;
      return !!this.$_.get(this.paymentMethod, "data.store");
    },
    externalAuthReturnLocation(): VueLocation {
      return this.$router.resolve(this.$route.fullPath).location;
    },
    isAsynchronousGateway() {
      return [
        PaymentMethodType.GATEWAY_BANK_TRANSFER,
        PaymentMethodType.GATEWAY_OFFLINE
      ].includes(this.paymentMethodType as PaymentMethodType);
    },
    hasValidPaymentMethod() {
      return (
        (!!this.paymentMethod?.type && !_.isEmpty(this.paymentMethod?.data)) ||
        typeof this.paymentMethod?.dataFunc === "function" ||
        !!this.paymentMethod?.walletAmount
      );
    },
    autoPay(): IAutoPay | null {
      return {
        accountId: this.additionalPaymentData?.account_id,
        amount: this.paymentMethod?.amount || 0,
        clientId: this.additionalPaymentData?.client_id,
        currencyId: this.additionalPaymentData?.currency_id,
        invoiceId: this.additionalPaymentData?.invoice_id,
        walletAmount: this.paymentMethod?.walletAmount || 0
      };
    },
    totalAmountToPay() {
      return 0;
    },
    additionalManualPaymentData() {
      return {};
    },
    additionalPaymentData(): { [key: string]: any } {
      return {};
    }
  },
  methods: {
    prepareStoreCardData() {
      const data = this.paymentMethod?.data as StoredCardData;
      this.paymentData = { payment_details_id: data.payment_details_id };
    },
    async prepareCardGatewayData(clientId: IClient["id"]) {
      if (this.shouldBeStored) {
        const newPaymentMethod = await this.storePaymentMethod(clientId);
        this.paymentData = {
          payment_details_id: newPaymentMethod.id
        };
        return;
      }

      if (this.isExternal) {
        const data = this.paymentMethod?.data as GatewayExternalCardData;
        this.paymentData = this.$_.pick(data, [
          "gateway_id",
          "store_on_payment",
          "store_on_payment_auto_payment"
        ]);
        return;
      }

      const data = this.paymentMethod?.data as GatewayCardData;

      this.paymentData = {
        card_type: data.card_type,
        card_num: data.card_num,
        card_expire_date: data.card_expire_date,
        card_cvv: data.card_cvv,
        name: data.name,
        address_id: data.address_id,
        gateway_id: data.gateway_id,
        cardholder_name: data.cardholder_name,
        ...this.$_.pick(data, [
          "store_on_payment",
          "store_on_payment_auto_payment"
        ])
      };
    },
    prepareGatewayData() {
      const data = this.paymentMethod?.data as GatewayData;
      this.paymentData = this.$_.pick(data, [
        "gateway_id",
        "store_on_payment",
        "store_on_payment_auto_payment"
      ]);
    },
    prepareGatewayMobileData() {
      const data = this.paymentMethod?.data as GatewayMobileData;
      this.paymentData = this.$_.pick(data, [
        "gateway_id",
        "payer",
        "store_on_payment",
        "store_on_payment_auto_payment"
      ]);
    },
    prepareManualPaymentData() {
      const data = this.paymentMethod?.data as ManualPaymentData;
      this.paymentData.gateway_id = data.gateway_id;
      this.paymentData.amount = data.amount;
      if (data.transaction_id) {
        this.paymentData.transaction_id = data.transaction_id;
      }
    },
    async preparePaymentData(clientId: IClient["id"]) {
      this.paymentData = {};

      const data = this.paymentMethod?.data;
      const dataFunc = this.paymentMethod?.dataFunc;

      if (typeof dataFunc === "function") {
        this.paymentData = {
          ...this.$_.pick(data, [
            "store_on_payment",
            "store_on_payment_auto_payment"
          ]),
          ...(await dataFunc())
        };
      } else {
        switch (this.paymentMethodType) {
          case PaymentMethodType.STORED_CARD:
            this.prepareStoreCardData();
            break;
          case PaymentMethodType.GATEWAY_CARD:
            await this.prepareCardGatewayData(clientId);
            break;
          case PaymentMethodType.GATEWAY_SEPA:
          case PaymentMethodType.GATEWAY_OFFLINE:
          case PaymentMethodType.GATEWAY_DIRECT_DEBIT:
          case PaymentMethodType.GATEWAY_BANK_TRANSFER:
          case PaymentMethodType.GATEWAY_AWAITING_CLIENT:
            this.prepareGatewayData();
            break;
          case PaymentMethodType.GATEWAY_MOBILE:
            this.prepareGatewayMobileData();
            break;
          case PaymentMethodType.MANUAL_PAYMENT:
            this.prepareManualPaymentData();
            break;
        }
      }

      if (this.paymentMethod?.amount) {
        this.paymentData.amount = this.paymentMethod?.amount;
      }

      if (!this.paymentData.amount && this.totalAmountToPay) {
        this.paymentData.amount = this.totalAmountToPay;
      }

      if (this.paymentMethod?.walletAmount) {
        this.paymentData.wallet_amount = this.paymentMethod?.walletAmount;
        if (!this.paymentMethodType) {
          this.paymentData.amount = this.paymentData.wallet_amount;
        }
      }
    },
    async storePaymentMethod(clientId: IClient["id"]) {
      const data = this.paymentMethod?.data as GatewayCardData;
      const returnUrl = this.buildReturnUrl({
        externalAuthReturnLocation: this.externalAuthReturnLocation,
        paymentMethodType: this.paymentMethodType,
        autoPay: this.autoPay
      });

      const result = await this.$store.dispatch(
        `data/${DataModules.CLIENTS_PAYMENT_DETAILS}/create`,
        {
          id: clientId,
          data: {
            card_type: data.card_type,
            card_num: data.card_num,
            card_expire_date: data.card_expire_date,
            card_cvv: data.card_cvv,
            name: data.name,
            address_id: data.address_id,
            gateway_id: data.gateway_id,
            cardholder_name: data.cardholder_name,
            return_url: returnUrl,
            auto_payment: this.paymentMethod?.auto_payment
          }
        }
      );

      const scaVerified = result?.sca_verified;
      const nextAction = result?.next_action;

      if (nextAction && !scaVerified) {
        this.externalAuth = {
          ...nextAction,
          returnLocation: this.externalAuthReturnLocation
        };
      }

      return result;
    },
    executeManualPayment() {
      return this.$store.dispatch(
        `data/${DataModules.PAYMENTS}/manualPayment`,
        {
          data: {
            ...this.paymentData,
            ...this.additionalManualPaymentData
          }
        }
      );
    },
    async executePayment(attemptKey = "") {
      // Add payment `attempt` key to query param
      if (attemptKey) {
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              [QUERY_PARAMS.ATTEMPT]: attemptKey
            }
          })
          .catch(err => err);
      }

      if (this.isManualPayment) {
        await this.executeManualPayment();
      } else if (
        this.totalAmountToPay !== 0 &&
        (![
          PaymentMethodType.GATEWAY_OFFLINE,
          PaymentMethodType.GATEWAY_BANK_TRANSFER,
          PaymentMethodType.PAY_LATER
        ].includes(this.paymentMethodType as PaymentMethodType) ||
          this.paymentData.wallet_amount > 0)
      ) {
        const { approval_url, transaction_status, transaction_id } =
          await this.$store.dispatch(`data/${DataModules.PAYMENTS}/create`, {
            data: {
              ...this.paymentData,
              ...this.additionalPaymentData
            }
          });

        // If SCA is required via iframe solution (for MercadoPago). Note – this
        // await will not resolve by design.
        if (
          approval_url?.fields?.redirect_url_after_challenge &&
          approval_url?.url?.includes("mercadopago")
        )
          await this.$store.dispatch(
            `data/${DataModules.PAYMENTS}/openMercadoPagoSCAChallengeModal`,
            { approval_url }
          );

        // If SCA is required via off-site completion, redirect user. Note – this
        // await will not resolve by design.
        if (approval_url?.url) await this.redirectToExternalAuth(approval_url);

        if (
          transaction_status === TransactionStatus.WAITING &&
          this.paymentMethodType === PaymentMethodType.GATEWAY_AWAITING_CLIENT
        ) {
          await this.$store.dispatch(
            `data/${DataModules.PAYMENTS}/openPaymentInstructionsModal`,
            transaction_id
          );
        } else {
          // Otherwise, confirm successful payment
          $snackbar.open({
            message: this.$t("_.payment_successful") as string,
            type: "is-success"
          });
        }
      }

      return Promise.resolve();
    }
  }
});
</script>
