<template>
  <is-loading :if="isLoading" :is-absolute="true">
    <earth-layout>
      <template slot="A">
        <u-header :title="$t('_.products_and_services')">
          <tabigation
            v-if="totalOrders"
            slot="tabs"
            :routes="routes()"
            class="has-margin-bottom-200"
          />
          <template #cta>
            <b-button
              :to="{ name: 'adminCatalogueProducts' }"
              tag="router-link"
            >
              <i18n path="_action.manage_product_catalogue" />
            </b-button>
            <template v-if="canAddOrder">
              <b-button
                type="is-dark"
                @click="$store.dispatch('data/contracts/startNewOrder')"
              >
                <i18n path="_action.place_new_order" />
              </b-button>
            </template>
          </template>
        </u-header>
        <u-onboarding
          :if="!totalOrders"
          :title="$t('_.awaiting_first_order')"
          :cta="$t('_action.place_first_order')"
          :desc="$t('_sentence.products_and_services_onboarding_desc')"
          img="/assets/images/interface/onboarding/add-order.svg"
          @click="$store.dispatch('data/contracts/startNewOrder')"
        >
          <router-view />
        </u-onboarding>
      </template>
    </earth-layout>
  </is-loading>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RequestPromises from "@/mixins/requestPromises";
import { DataModules } from "@/store/modules/data/modules";
import type { PropType } from "vue";

export default defineComponent({
  name: "AdminProductsAndServices",
  mixins: [RequestPromises],
  props: {
    routes: { type: Function as PropType<() => any>, default: () => {} }
  },
  data: () => ({
    isLoading: false,
    totalOrders: 0
  }),
  computed: {
    canAddOrder() {
      // list_clients because a client must be selected
      return this.$userCan("staff_create_order", "list_clients");
    }
  },
  created() {
    this.isLoading = true;
    this.$store
      .dispatch(`data/${DataModules.CONTRACTS}/list`, {
        vm: this,
        scope: "count",
        returnData: false,
        storeData: false,
        params: {
          limit: "count"
        }
      })
      .then(result => {
        this.totalOrders = result.total;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
});
</script>
