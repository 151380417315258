<template>
  <cprods-provider
    ref="cProdsProvider"
    :handle-loading="false"
    :limit="10"
    v-bind="{
      accountId,
      availableFilters,
      availableSorters,
      clientId,
      ignoreHistory,
      isStaged,
      manualFilters
    }"
  >
    <template v-slot:default="{ $cProdsData, $cProdsMethods }">
      <div class="has-margin-bottom-50">
        <template v-if="showQuickSearch">
          <div class="field is-grouped">
            <div class="control is-expanded">
              <b-input
                v-focus="$store.getters['ui/isTabletPlus']"
                :value="$cProdsData.searchQuery"
                :placeholder="$t('_placeholder.product_quick_search')"
                :icon-right="$cProdsData.searchQuery ? 'close-circle' : null"
                :icon-right-clickable="true"
                @icon-right-click="$cProdsMethods.doQuickSearch()"
                @input="$cProdsData.debounceSearch"
              />
            </div>
          </div>
        </template>
        <filter-sort-controls
          v-if="$cProdsData.enableFiltering || $cProdsData.enableSorting"
          :active-filters="$cProdsData.activeFilters"
          :enable-filtering="$cProdsData.enableFiltering"
          :enable-sorting="$cProdsData.enableSorting"
          :filters="$cProdsData.availableFilters"
          :sorters="$cProdsData.availableSorters"
          :table="$cProdsData.table"
          class="has-margin-bottom-100"
          @onRefresh="$cProdsMethods.reloadData"
          @onFiltersChange="$cProdsMethods.onFiltersChange"
          @onSort="$cProdsMethods.onSort"
        >
          <!-- Display Mode -->
          <template v-if="!forceDisplayMode" #control>
            <u-list-display-modes
              v-model="displayMode"
              :storage-path="displayModeStoragePath"
            />
          </template>
        </filter-sort-controls>
        <component :is="listComponent" />
        <u-pagination
          :current.sync="$cProdsData.page"
          :per-page="$cProdsData.limit"
          :total="$cProdsData.total"
          :default-limit="$cProdsData.defaults.limit"
          :visible-total="$cProdsData.table.data.length"
          @change-page="$cProdsMethods.onPageChange"
          @change-per-page="$cProdsMethods.onLimitChange"
        />
      </div>
    </template>
  </cprods-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UserMetaKeys } from "@/data/enums/users";
import { ListDisplayMode } from "@/data/constants";
import CProdsProvider from "@/components/app/global/contractProducts/cProdsProvider.vue";
import CProdsGridView from "@/components/app/global/contractProducts/cProdsGridView.vue";
import CProdsTableView from "@/components/app/global/contractProducts/cProdsTableView.vue";
import type { PropType, CreateElement } from "vue";
import type { IClient } from "@upmind-automation/types";
import type { IFilter, ISorter } from "@/models/tables";
import type { IContractProduct } from "@/models/contracts";

const CProdsListing = defineComponent({
  name: "CProdsListing",
  components: {
    "cprods-provider": CProdsProvider,
    CProdsGridView,
    CProdsTableView
  },
  props: {
    clientId: { type: String as PropType<IClient["id"]>, default: null },
    accountId: { type: String as PropType<IClient["id"]>, default: null },
    routeFilters: {
      type: Function as PropType<
        (vm: any) => Record<string, string | number | boolean>
      >,
      default: () => {}
    },
    parentFilters: {
      type: Object as PropType<Record<string, string | number | boolean>>,
      default: () => ({})
    },
    availableFilters: {
      type: Function as PropType<(vm?: typeof CProdsListing) => IFilter[]>,
      default: () => []
    },
    availableSorters: {
      type: Function as PropType<(vm?: typeof CProdsListing) => ISorter[]>,
      default: () => []
    },
    rowTag: { type: String, default: undefined },
    rowEvents: {
      type: Function as PropType<
        (contractProduct: IContractProduct) => {
          [key: string]: Function;
        }
      >,
      default: () => ({})
    },
    forceDisplayMode: {
      type: String as PropType<ListDisplayMode>,
      default: undefined
    },
    client: { type: Object as PropType<IClient>, default: undefined },
    ignoreHistory: { type: Boolean, default: false },
    showQuickSearch: { type: Boolean, default: true }
  },
  data: () => ({
    displayModeStoragePath: UserMetaKeys.UI_CPRODS_VIEW,
    displayMode: ListDisplayMode.GRID,
    cProdAvailableFilters: [] as IFilter[]
  }),
  computed: {
    displayModeComputed() {
      return this.forceDisplayMode || this.displayMode;
    },
    manualFilters() {
      return {
        ...this.parentFilters,
        ...this.routeFilters(this)
      };
    },
    listComponent() {
      return {
        render: (h: CreateElement) => {
          if (this.displayModeComputed === ListDisplayMode.GRID)
            return h(CProdsGridView);
          return h(CProdsTableView, {
            props: {
              id: "ACPTV" /* Admin Contract Products Table View*/,
              clientId: this.clientId,
              rowTag: this.rowTag,
              rowEvents: this.rowEvents
            }
          });
        }
      };
    },
    isStaged() {
      return this.client?.staged_import ?? false;
    }
  },
  watch: {
    parentFilters: { handler: "onParentFiltersChange", deep: true }
  },
  created() {
    this.cProdAvailableFilters = this.availableFilters(this);
  },
  methods: {
    onParentFiltersChange() {
      this.$refs.cProdsProvider.reloadData();
    }
  }
});

export default CProdsListing;
</script>

<style lang="scss" scoped>
.cprod-wells {
  display: grid;
  gap: 0.75rem;
  grid-template-columns: 1fr;
  @include tablet {
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  }
}
::v-deep .cprod-wells .b-skeleton {
  margin-top: 0;
  opacity: 0.75;
  .b-skeleton-item {
    height: 8.125rem;
  }
}

.u-ripple-table-empty {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 22rem;
  box-shadow: 0 4px 16px 0 rgba($black, 0.1);
  & ~ .b-skeleton {
    opacity: 0.4;
    border-top-color: $light !important;
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 360ms ease-out;
}
.slide-down-leave-active {
  transition-duration: 0s;
}
.slide-down-enter,
.slide-down-leave-to {
  margin-top: -1rem;
  opacity: 0;
}
</style>
