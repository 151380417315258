import ModalCard from "@/components/base/ModalCard.vue";
import SlideModalCard from "@/components/base/SlideModalCard.vue";
import I18ntc from "@/components/base/I18ntc.vue";
import { registerComponents } from "@/helpers/async";

const manualImports = ["ModalCard", "SlideModalCard", "I18ntc"];

export default {
  install: (Vue: any) => {
    registerComponents(Vue, import.meta.glob("./*.vue"), manualImports);

    // Register components inside a folder
    Vue.component(
      "date-range-picker",
      () => import("@/components/base/DateRangePicker/index.vue")
    );
    Vue.component(
      "multilevel-sortable-list",
      () => import("@/components/base/MultilevelSortableList/index.vue")
    );
    Vue.component(
      "image-gallery",
      () => import("@/components/base/ImageGallery/index.vue")
    );

    // Register manual imports
    Vue.component("modal-card", ModalCard);
    Vue.component("slide-modal-card", SlideModalCard);
    Vue.component("i18ntc", I18ntc);
  }
};
