import Vue from "vue";
import _ from "@/boot/lodash";
import router from "@/router";
import store from "@/store";
import { ClientRoutes } from "@/data/enums/router";
import { Contexts } from "@/models/contexts";
import { GrantTypes } from "@/data/enums/tokens";
import { Methods } from "@/models/methods";
import { UUID } from "@/data/enums";
import { getSupportedLocaleCode } from "@/data/enums/i18n";
import { AppHookCodes } from "@/data/enums/hooks";
import tokenState from "@/store/modules/auth/tokenState";
import type { IState } from "@/store";
import type { IAuthenticate } from "@/models/auth";
import type { IBrand } from "@/models/brands";
import type { IClient, IClientRegisterForm } from "@upmind-automation/types";
import type { IFunctionality } from "@/store/types";
import type { IOrg } from "@/models/organisation";
import type { IToken } from "@/models/token";
import type { IUser } from "@/models/users";
import type { MutationTree, GetterTree, ActionTree } from "vuex";
import type { Route } from "vue-router";

export interface IAuthAdminState {
  brandId: IBrand["id"];
  brands: {
    id: IBrand["id"] | UUID.ORG;
    name: string;
    domain?: string;
  }[];
  functionalities: IFunctionality[];
  isImpersonated: boolean;
  token: IToken;
}

export const initialState = () => {
  return {
    brandId: null,
    brands: [],
    functionalities: [],
    isImpersonated: false,
    token: tokenState(Contexts.ADMIN)
  };
};

const getters: GetterTree<IAuthAdminState, IState> = {
  isAuthenticated: (state, getters, rootState, rootGetters): boolean => {
    return (
      !_.isEmpty(state.token.access_token) &&
      !rootGetters["auth/hasExpiredSession"](Contexts.ADMIN)
    );
  },
  hasMultipleBrands: (state): boolean => {
    return state.brands.length > 1;
  },
  functionalities: (state): IFunctionality[] => {
    return state.brandId === UUID.ORG
      ? state.functionalities
      : _.filter(state.functionalities, functionality => {
          return _.includes(functionality.brand_ids, state.brandId);
        });
  },
  isLoggedOutPath: () => (path: Route["path"]) => {
    const regex = new RegExp(
      [
        `^`,
        `(?:/admin)`,
        `(?:/auth)?`,
        `(?:/(?:${[
          "login",
          "forgotten-password",
          "reset-password",
          "verify",
          "relay",
          "logout"
        ].join("|")}))`,
        `(?:/|[?#].*)?`,
        `$`
      ].join(""),
      "gi"
    );
    return !!path.match(regex);
  }
};

const actions: ActionTree<IAuthAdminState, IState> = {
  getUser: async ({
    state,
    commit,
    dispatch,
    rootGetters
  }): Promise<IUser | undefined> => {
    try {
      const {
        actor,
        brand_id,
        brands,
        functionalities,
        impersonator_id,
        upmind_contract_product,
        upmind_impersonation_enabled,
        upmind_impersonation_expiry,
        upmind_package_limits,
        user_flow_secrets
      }: {
        actor: IUser;
        brand_id: IBrand["id"];
        brands: IBrand[];
        functionalities: IFunctionality[];
        impersonator_id: IUser["id"] | null;
        user_flow_secrets?: {
          actor: string;
        };
      } & Pick<
        IOrg,
        | "upmind_impersonation_enabled"
        | "upmind_impersonation_expiry"
        | "upmind_contract_product"
        | "upmind_package_limits"
      > = await store.dispatch("data/callApi", {
        method: Methods.GET,
        path: "api/admin/self",
        requestConfig: {
          params: {
            with: [
              "actor",
              "brands",
              "brands.image",
              "brands.icon",
              "functionalities",
              "user_flow_secrets",
              "upmind_contract_product"
            ].join()
          }
        }
      });

      commit("user", actor, { root: true });
      // Commit user's userflow signature
      const signature = user_flow_secrets?.actor || null;
      commit("ui/userflow/signature", signature, { root: true });
      // Commit whether user is being impersonated
      commit("isImpersonated", !!impersonator_id);
      // Commit denormalized org data
      commit(
        "org/data",
        {
          upmind_contract_product,
          upmind_impersonation_enabled,
          upmind_impersonation_expiry,
          upmind_package_limits
        },
        { root: true }
      );
      // Commit user's supported brands
      commit("brands", brands || []);
      // Map all brand IDs (seeding `org` default)
      const supportedBrandIds = [UUID.ORG, ..._.map(brands, "id")];
      // Set default brandId using session `brand_id` value
      let brandId = brand_id;
      // If brandId isn't supported: assign first supported id
      if (!supportedBrandIds.includes(brandId)) {
        brandId = _.first(supportedBrandIds) as string;
      }
      // If final brandId is different to state value, commit new brandId
      if (brandId !== state.brandId) commit("brandId", brandId);
      // Commit user functionalities
      commit("functionalities", functionalities || []);
      // Set language based on user locale (checking for native support)
      const localeCode = getSupportedLocaleCode(rootGetters["user/locale"]);
      dispatch("i18n/setLanguage", localeCode, { root: true });
      dispatch("auth/userReady", {}, { root: true });
      return actor;
    } catch (error) {
      // Check for network error
      if (await dispatch("api/isNetworkError", error, { root: true })) {
        // Show network error modal
        dispatch("ui/openNetworkErrorModal", null, { root: true });
      }
    }
  },
  setBrand: async (
    { state, dispatch, commit, rootGetters, rootState },
    brandId?: IBrand["id"]
  ) => {
    brandId = brandId || state.brandId || rootGetters["brand/id"];
    // Ensure staff has access to brand
    brandId = _.find(state.brands, ["id", brandId]) ? brandId : UUID.ORG;
    // If access to only one brand, force that brand over ORG mode
    brandId = state.brands.length === 1 ? state.brands[0].id : brandId;
    // Set data object
    const data = { brand_id: brandId };
    // Select brand
    await dispatch(
      "api/call",
      {
        method: Methods.POST,
        path: "api/admin/brands/select",
        requestConfig: { data }
      },
      { root: true }
    );
    if (brandId === UUID.ORG) {
      /* If entering into "org" mode, reset brand state */
      commit("brand/reset", null, { root: true });
      commit("brand/id", UUID.ORG, { root: true });
    } else if (brandId !== rootGetters["brand/id"]) {
      /* If brandId is different to the current brand, get new brand data */
      await dispatch(
        "brand/get",
        {
          ignoreStored: true
        },
        { root: true }
      );
    }
    /* Commit 'brandId' AFTER brand settings call (brand/get) to avoid race
    condition: 'auth/admin/brandId' is used as part of a unique key which
    determines when components should re-render. As some components rely on data
    returned from the brand settings call (eg stats require knowledge of brand
    currencies), we must wait for the settings call to finish, before updating
    brandId and triggering reinstantiation. */
    if (brandId !== state.brandId) commit("brandId", brandId);
    // Resolve select brand call
    return Promise.resolve(rootState.brand);
  },
  reauthenticate: async ({ state, dispatch }, payload: IAuthenticate) => {
    const token = await dispatch("auth/getToken", payload, { root: true });
    const data = { brand_id: state.brandId };
    await store.dispatch("api/call", {
      method: Methods.POST,
      path: "api/admin/brands/select",
      requestConfig: { data },
      callConfig: {
        customAccessToken: token.access_token
      }
    });
    await dispatch(
      "auth/saveToken",
      { token, context: Contexts.ADMIN },
      { root: true }
    );
    await dispatch("getUser");
    return;
  },
  handleLogin: ({ commit, rootGetters, getters }) => {
    commit("brandId", rootGetters["brand/id"]);
    const lastRoute = _.last(router.currentRoute.matched);
    if (_.get(lastRoute, "path", "").startsWith("/admin/auth/")) {
      const redirect = _.get(router.currentRoute, "query.redirect", "");
      router
        .replace(
          redirect && !getters["isLoggedOutPath"](redirect)
            ? // Replace '\\' from start to prevent attack vector
              redirect.toString().replace(/^\\+/, "")
            : { name: "adminDashboard" }
        )
        .catch(err => err);
    }
  },
  register: ({ dispatch, rootState }, form: IClientRegisterForm) => {
    const tracking = _.get(rootState, "upm.track");
    return store
      .dispatch("api/call", {
        method: Methods.POST,
        path: "api/org/register",
        requestConfig: {
          data: {
            ...form,
            ...(tracking ? { tracking } : {})
          }
        },
        callConfig: {
          withAccessToken: false
        }
      })
      .then(async result => {
        // Fire app hook
        dispatch(
          `ui/hooks/${AppHookCodes.CLIENT_REGISTERED}`,
          result?.data?.id,
          { root: true }
        );
        return result;
      });
  },
  verifyRegHash: (
    { dispatch },
    data: {
      username: IAuthenticate["username"];
      reg_hash: IAuthenticate["reg_hash"];
    }
  ) => {
    return dispatch(
      "api/call",
      {
        method: Methods.PATCH,
        path: "api/admin/users/reg_hash/verify",
        // This end point only works for guests
        callConfig: { withAccessToken: false },
        requestConfig: {
          data
        }
      },
      { root: true }
    );
  },
  completeUserRegistration: async (
    { dispatch },
    {
      reg_hash,
      username,
      password
    }: {
      reg_hash: IAuthenticate["reg_hash"];
      username: IAuthenticate["username"];
      password: IAuthenticate["password"];
    }
  ) => {
    const { token }: { token: IToken } = await dispatch(
      "auth/createAccessToken",
      {
        data: {
          grant_type: GrantTypes.COMPLETE_USER_REGISTRATION,
          password,
          reg_hash,
          username
        }
      },
      { root: true }
    );
    await dispatch(
      "auth/saveToken",
      { token, context: Contexts.ADMIN },
      { root: true }
    );
    dispatch("handleLogin");
    return Promise.resolve();
  },
  completeOrgRegistration: async (
    { dispatch, rootState },
    {
      data
    }: {
      data: {
        reg_hash: IAuthenticate["reg_hash"];
        username: IAuthenticate["username"];
      };
    }
  ) => {
    const { token }: { token: IToken } = await dispatch(
      "auth/createAccessToken",
      {
        data: {
          grant_type: GrantTypes.COMPLETE_ORG_REGISTRATION,
          ...data
        }
      },
      { root: true }
    );
    await dispatch(
      "auth/saveToken",
      { token, context: Contexts.ADMIN },
      { root: true }
    );

    /** Here we re-call 'brand/get' in case a non-default currency was
     * selected during the verification flow OR the seeding of demo data was
     * requested. */

    await store.dispatch("brand/get", { ignoreStored: true }, { root: true });

    /** Here we check if the current brand has a 'demo_data_import_id', and
     * initiate a check to see if the import is complete or in progress */

    if (rootState.brand?.demo_data_import_id)
      dispatch("demoData/checkImportStatus", null, { root: true });

    dispatch("handleLogin");
    return Promise.resolve();
  },
  openSelectBrandModal: ({ getters, dispatch, state }) => {
    if (!getters["hasMultipleBrands"]) return;
    dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/tenancy/selectBrandModal.vue"),
          width: state.brands.length > 4 ? 1040 : 640,
          hasModalCard: false,
          canCancel: ["escape", "outside"]
        }
      },
      { root: true }
    );
  },
  impersonateUser: async (
    { dispatch },
    { userId, orgId }: { userId: IUser["id"]; orgId: IOrg["id"] }
  ) => {
    try {
      const { resolved } = router.resolve({ name: "relayAdminToken" });
      const token: IToken = await store.dispatch("data/callApi", {
        method: Methods.POST,
        path: `api/admin/users/${userId}/access_token`,
        requestConfig: {
          data: { org_id: orgId }
        }
      });
      await dispatch(
        "auth/relayToken",
        { token, path: resolved.path },
        { root: true }
      );
    } catch (error) {
      dispatch("api/handleValidationError", { error }, { root: true });
    }
  },
  impersonateClient: async ({ dispatch }, clientId: IClient["id"]) => {
    try {
      const { resolved } = router.resolve({
        name: ClientRoutes.RELAY_TOKEN
      });
      const token: IToken = await store.dispatch("data/callApi", {
        method: Methods.POST,
        path: `api/admin/clients/${clientId}/access_token`
      });
      await dispatch(
        "auth/relayToken",
        { token, path: resolved.path },
        { root: true }
      );
    } catch (error) {
      dispatch("api/handleValidationError", { error }, { root: true });
    }
  }
};

const mutations: MutationTree<IAuthAdminState> = {
  brands: (state, brands) => {
    Vue.set(state, "brands", brands);
  },
  brandId: (state, brandId: IBrand["id"]) => {
    Vue.set(state, "brandId", brandId);
  },
  functionalities: (state, functionalities: IFunctionality[]) => {
    Vue.set(state, "functionalities", functionalities);
  },
  isImpersonated: (state, val: boolean) => {
    Vue.set(state, "isImpersonated", val);
  },
  reset: state => {
    for (const property in localStorage) {
      if (property.startsWith("admin/auth/")) {
        localStorage.removeItem(property);
      }
    }
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};
